import React from "react";
import * as Three from "three";
import { loadObjWithMaterial } from "src/demo/src/catalog/utils/load-obj";
import { BALANCER } from './_constants';
var cached3DDoor = null;
var STYLE_HOLE_BASE = { fill: "white" };
var STYLE_HOLE_SELECTED = {
    stroke: "#0096fd",
    strokeWidth: "4px",
    // fill: "#0096fd",
    cursor: "move",
    // fill: 'none'
};
var STYLE_ARC_BASE = {
    stroke: "#808080",
    strokeWidth: "3px",
    strokeDasharray: "5,5",
    fill: "none",
};
var STYLE_ARC_SELECTED = {
    stroke: "#0096fd",
    strokeWidth: "4px",
    strokeDasharray: "5,5",
    fill: "none",
    cursor: "move",
};
var EPSILON = 3;
var generateDoors = function (door) {
    var width = door.width || 80;
    var height = door.height || 215;
    var altitude = door.altitude || 0;
    var thickness = door.thickness || 20;
    var doorElement = {
        name: "door-".concat(door.literal),
        prototype: "holes",
        info: {
            title: door.name_rus,
            tag: ["door"],
            description: door.name_rus,
            image: "".concat(BALANCER).concat(door.icon_src_preview),
        },
        properties: {
            width: {
                label: "Ширина",
                type: "length-measure",
                defaultValue: {
                    // length: door.width,
                    length: width,
                },
            },
            height: {
                label: "Высота",
                type: "length-measure",
                defaultValue: {
                    // length: door.height,
                    length: height,
                },
            },
            altitude: {
                label: "Высота от пола",
                type: "length-measure",
                defaultValue: {
                    // length: door.altitude,
                    length: altitude,
                },
            },
            thickness: {
                label: "Толщина",
                type: "length-measure",
                defaultValue: {
                    // length: door.thickness,
                    length: thickness,
                },
            },
            flip_orizzontal: {
                label: "Открытие внутрь",
                type: "checkbox",
                defaultValue: false,
                values: {
                    none: false,
                    yes: true,
                },
            },
        },
        render2D: function (element, layer, scene) {
            var epsilon = element.properties.get('thickness').get("length");
            var flip = element.properties.get("flip_orizzontal");
            var holeWidth = element.properties.get("width").get("length");
            var holePath = "M".concat(0, " ").concat(-EPSILON, "  L").concat(holeWidth, " ").concat(-EPSILON, "  L").concat(holeWidth, " ").concat(EPSILON, "  L").concat(0, " ").concat(EPSILON, "  z");
            var arcPath = "M".concat(0, ",").concat(0, "  A").concat(holeWidth, ",").concat(holeWidth, " 0 0,1 ").concat(holeWidth, ",").concat(holeWidth);
            var holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
            var arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
            var length = element.properties.get("width").get("length");
            if (flip == false) {
                return (React.createElement("g", { transform: "translate(".concat(-length / 2, ", 0)") },
                    React.createElement("path", { d: arcPath, style: arcStyle, transform: "translate(".concat(0, ",").concat(holeWidth, ") scale(").concat(1, ",").concat(-1, ") rotate(").concat(0, ")") }),
                    React.createElement("rect", { x: 0, y: holeWidth - epsilon / 2, width: holeWidth, height: epsilon, 
                        // height={epsilon}
                        style: holeStyle, transform: "translate(".concat(0, ",").concat(holeWidth, ") scale(").concat(1, ",").concat(-1, ") rotate(").concat(0, ")"), preserveAspectRatio: "none" }),
                    React.createElement("image", { x: 0, y: holeWidth - epsilon / 2, width: holeWidth, height: epsilon, href: "".concat(BALANCER).concat(door.icon_src), preserveAspectRatio: "none", style: holeStyle, transform: "translate(".concat(0, ",").concat(holeWidth, ") scale(").concat(1, ",").concat(-1, ") rotate(").concat(0, ")") })));
            }
            else {
                return (React.createElement("g", { transform: "translate(".concat(-length / 2, ", 0)") },
                    React.createElement("path", { d: arcPath, style: arcStyle, transform: "translate(".concat(0, ",").concat(-holeWidth, ") scale(").concat(1, ",").concat(1, ") rotate(").concat(0, ")") }),
                    React.createElement("rect", { x: 0, y: holeWidth - epsilon / 2, width: holeWidth, height: epsilon, 
                        // height={epsilon}
                        style: holeStyle, transform: "translate(".concat(0, ",").concat(-holeWidth, ") scale(").concat(1, ",").concat(1, ") rotate(").concat(0, ")"), preserveAspectRatio: "none" }),
                    React.createElement("image", { x: 0, y: holeWidth - epsilon / 2, width: holeWidth, height: epsilon, href: "".concat(BALANCER).concat(door.icon_src), preserveAspectRatio: "none", style: holeStyle, transform: "translate(".concat(0, ",").concat(-holeWidth, ") scale(").concat(1, ",").concat(1, ") rotate(").concat(0, ")") })));
            }
        },
        render3D: function (element, layer, scene) {
            var onLoadItem = function (object) {
                var boundingBoxBefore = new Three.Box3();
                var boundingBox = boundingBoxBefore.setFromObject(object);
                var initialWidth = boundingBox.max.x - boundingBox.min.x;
                var initialHeight = boundingBox.max.y - boundingBox.min.y;
                var initialThickness = boundingBox.max.z - boundingBox.min.z;
                if (element.selected) {
                    var box = new Three.BoxHelper(object, 0x99c3fb);
                    box.material.linewidth = 2;
                    box.material.depthTest = false;
                    box.renderOrder = 1000;
                    object.add(box);
                }
                var width = element.properties.get("width").get("length");
                var height = element.properties.get("height").get("length");
                var thickness = element.properties.get("thickness").get("length");
                object.scale.set(width / initialWidth, height / initialHeight, thickness / initialThickness);
                return object;
            };
            if (cached3DDoor) {
                return Promise.resolve(onLoadItem(cached3DDoor.clone()));
            }
            // let mtl = door.icon_src
            // let obj = door.icon_src
            // let img = door.icon_src
            var mtl = require("./door/door.mtl");
            var obj = require("./door/door.obj");
            var img = "".concat(BALANCER).concat(door.icon_src_preview);
            return loadObjWithMaterial(mtl, obj, img).then(function (object) {
                cached3DDoor = object;
                return onLoadItem(cached3DDoor.clone());
            });
        },
    };
    return doorElement;
};
export var generateDoor = function (_a) {
    var door = _a.door, catalog = _a.catalog;
    var holes = [];
    door.forEach(function (item) {
        var elementCatalog = generateDoors(item);
        holes.push(elementCatalog);
        catalog.registerElement(elementCatalog);
    });
    return holes;
};
