var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useCallback, useEffect, useState } from "react";
import { SubMenuStyled, SubMenuImagesBlock, SubMenuTitle, SubMenuImagesUrl, CurrentFurnitureInfo, CurrentFurnitureImg, TitleText, ButtonContainer, } from "./styled";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { getFurnituresRequests } from "src/api/requests/get-furnitures";
import { postRoomsRequest } from "src/api/requests/post-rooms";
import { Project } from "src/class/export";
import { localStorageWorker } from "src/utils/local-storage-worker";
import { LOCAL_STRORAGE_KEYS } from "src/constants";
export var SubMenuChangeFurniture = memo(function SubMenuChangeFurniture(_a) {
    var _b;
    var menuItem = _a.menuItem, onClose = _a.onClose, changingFurnitureId = _a.changingFurnitureId, setIsUploadingCollage = _a.setIsUploadingCollage, collageActions = _a.collageActions, collageInfo = _a.collageInfo, state = _a.state, catalog = _a.catalog, onLoadEstimates = _a.onLoadEstimates;
    var handleOnClose = useCallback(function () {
        onClose();
    }, [onClose]);
    var _c = __read(useState(null), 2), furnitureInfo = _c[0], setFurnitureInfo = _c[1];
    var handleStartLoadingAlternativeFurnitures = function () { };
    var handleStopLoadingAlternativeFurnitures = function () { };
    var handleLoadAlternativeFurnitures = function (res) {
        setFurnitureInfo(res);
    };
    var handleStartLoadingSaveRooms = function () { };
    var handleStopLoadingSaveRooms = function () { };
    useEffect(function () {
        getFurnituresRequests({
            startLoadingCallback: handleStartLoadingAlternativeFurnitures,
            stopLoadingCallback: handleStopLoadingAlternativeFurnitures,
            onSuccess: handleLoadAlternativeFurnitures,
            catalog_id: changingFurnitureId.id,
        });
    }, [changingFurnitureId.id]);
    var handleSaveRoomsWithNewFurniture = useCallback(function (newObj) {
        var _a;
        var _state = Project.unselectAll(state).updatedState;
        var stateScene = _state.get("scene").toJS();
        var getlayoutAreaTemp = localStorageWorker.get({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
        });
        var layer = stateScene.layers["layer-1"];
        Object.values(layer.items).forEach(function (item) {
            var element = catalog.elements[item.type];
            if (element) {
                layer.items[item.id].catalog = element === null || element === void 0 ? void 0 : element.catalog;
            }
        });
        var data = __assign(__assign({}, layer), { items: __assign(__assign({}, layer.items), (_a = {}, _a[changingFurnitureId.item_idx] = __assign(__assign({}, layer.items[changingFurnitureId.item_idx]), { name: newObj.name, catalog: __assign({}, newObj) }), _a)), layout_area_temp: getlayoutAreaTemp });
        console.log(__assign(__assign({}, layer), { layout_area_temp: getlayoutAreaTemp }));
        console.log(data);
        postRoomsRequest({
            startLoadingCallback: handleStartLoadingSaveRooms,
            stopLoadingCallback: handleStopLoadingSaveRooms,
            data: data,
            onSuccess: onLoadEstimates,
        });
    }, [state, handleStartLoadingSaveRooms, handleStopLoadingSaveRooms]);
    var handleSelectItem = useCallback(function (newObj) {
        handleOnClose();
        handleSaveRoomsWithNewFurniture(newObj);
    }, [handleSaveRoomsWithNewFurniture]);
    return (React.createElement(React.Fragment, null, (furnitureInfo === null || furnitureInfo === void 0 ? void 0 : furnitureInfo.name) && (React.createElement(SubMenuStyled, null,
        React.createElement(SubMenuTitle, null,
            React.createElement(TitleText, { text: furnitureInfo.name }),
            React.createElement(CommonButton, { onClick: handleOnClose },
                React.createElement(CommonText, { color: "green", text: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C", fontSize: "16px" }))),
        React.createElement(CurrentFurnitureInfo, null,
            React.createElement(CurrentFurnitureImg, { src: furnitureInfo.default_image_src }),
            React.createElement("div", null,
                React.createElement(CommonText, { text: "\u0426e\u043D\u0430: ".concat(furnitureInfo.default_price) }))),
        ((_b = furnitureInfo.alike) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(SubMenuImagesBlock, null, furnitureInfo.alike.map(function (el) {
            return (React.createElement(ButtonContainer, { key: el.id },
                React.createElement(CommonButton, { isDisabled: false, onClick: function () { return handleSelectItem(el); } }, el.is_empty !== 1 ? (React.createElement(SubMenuImagesUrl, { src: el.default_image_src })) : (React.createElement(SubMenuImagesUrl, { src: "/img/no.png" }))),
                el.is_empty !== 1 ? (React.createElement(CommonText, { text: el.name, fontSize: "12px" })) : (React.createElement(CommonText, { text: "Удалить", fontSize: "12px" }))));
        })))))));
});
