var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { memo, useState, useEffect, useCallback } from "react";
import { AUTO_CLOSE_NOTIFICATION_TIMER } from "src/constants";
import { NotificationWrapper, NotificationItem, CloseButton } from "./styled";
export var Notifications = memo(function Notifications(_a) {
    var notification = _a.notification;
    var _b = __read(useState([]), 2), notifications = _b[0], setNotifications = _b[1];
    useEffect(function () {
        if (notification) {
            setNotifications(function (prevNotifications) {
                var notificationWithId = __assign(__assign({}, notification), { id: "".concat(prevNotifications.length) });
                return __spreadArray(__spreadArray([], __read(prevNotifications), false), [notificationWithId], false);
            });
        }
    }, [notification]);
    useEffect(function () {
        if (notification) {
            var interval_1 = setInterval(function () {
                setNotifications(function (prevNotifications) {
                    if (prevNotifications.length) {
                        var updatedNotifications = __spreadArray([], __read(prevNotifications.slice(1)), false);
                        return updatedNotifications;
                    }
                    else {
                        clearInterval(interval_1);
                        return [];
                    }
                });
            }, AUTO_CLOSE_NOTIFICATION_TIMER);
            return function () { return clearInterval(interval_1); };
        }
    }, [notification]);
    var handleRemoveNotification = useCallback(function (id) { return function () {
        setNotifications(function (prevNotifications) {
            return prevNotifications.filter(function (notification) { return notification.id !== id; });
        });
    }; }, []);
    var handleCloseAllNotification = useCallback(function () {
        setNotifications([]);
    }, []);
    return (React.createElement(NotificationWrapper, null,
        Boolean(notifications.length) && (React.createElement(CloseButton, { onClick: handleCloseAllNotification }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C \u0432\u0441\u0435")),
        notifications.map(function (notification) {
            return (React.createElement(NotificationItem, { key: notification.id, "$isError": notification.isError, "$isSuccess": notification.isSuccess },
                React.createElement("p", null, notification.title),
                React.createElement("p", null, notification.description),
                React.createElement(CloseButton, { onClick: handleRemoveNotification(notification.id) }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C")));
        })));
});
