var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Content = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n"])));
export var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  font-size: 18px;\n  color: #333;\n"], ["\n  margin-bottom: 20px;\n  font-size: 18px;\n  color: #333;\n"])));
export var InputWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 15px;\n"], ["\n  margin-bottom: 15px;\n"])));
export var FileInputLabel = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 8px 15px;\n  background-color: #2196f3;\n  color: #fff;\n  border-radius: 4px;\n  cursor: pointer;\n  &:hover {\n    background-color: #1976d2;\n  }\n"], ["\n  display: inline-block;\n  padding: 8px 15px;\n  background-color: #2196f3;\n  color: #fff;\n  border-radius: 4px;\n  cursor: pointer;\n  &:hover {\n    background-color: #1976d2;\n  }\n"])));
export var HiddenFileInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export var PreviewWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 15px;\n  img {\n    max-width: 100%;\n    max-height: 200px;\n    border-radius: 4px;\n  }\n"], ["\n  margin-bottom: 15px;\n  img {\n    max-width: 100%;\n    max-height: 200px;\n    border-radius: 4px;\n  }\n"])));
export var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n"], ["\n  display: flex;\n  gap: 10px;\n"])));
export var UploadButton = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 10px 20px;\n  background-color: #8dc646;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n"], ["\n  padding: 10px 20px;\n  background-color: #8dc646;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n"])));
export var CloseButton = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 10px 20px;\n  background-color: #f16d63;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n"], ["\n  padding: 10px 20px;\n  background-color: #f16d63;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
