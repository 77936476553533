export const mock = {
  id: "layer-1",
  altitude: 0,
  order: 0,
  opacity: 1,
  name: "default",
  visible: true,
  vertices: {
    "0Au9-0AUND": {
      id: "0Au9-0AUND",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 703.4743999999998,
      y: 1520,
      lines: ["Iqq3Y5ZZ81", "g8PSB9U2n"],
      areas: [],
    },
    "59HfRP-y-I": {
      id: "59HfRP-y-I",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1016,
      y: 1520,
      lines: ["x8uK2KY7N", "9Cnr6PxoBo", "g8PSB9U2n", "eLuFK3CQYM"],
      areas: [],
    },
    "8_B9PydxIZ": {
      id: "8_B9PydxIZ",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1396.3311039999999,
      y: 1821,
      lines: ["0QnaYf8Alf", "mxvTmWbV5", "FjSikxngHO"],
      areas: [],
    },
    Knnacc7HRx: {
      id: "Knnacc7HRx",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1808.4226399999998,
      y: 1821,
      lines: ["FjSikxngHO", "4XYCpT-H7"],
      areas: [],
    },
    vto9I2r0Ik: {
      id: "vto9I2r0Ik",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1396.3311039999999,
      y: 1520,
      lines: ["eLuFK3CQYM", "0QnaYf8Alf", "dLeaEuyzXoD"],
      areas: [],
    },
    BhIqlzjc6V: {
      id: "BhIqlzjc6V",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1016,
      y: 1299.8316,
      lines: ["x8uK2KY7N", "EBP3DbILs"],
      areas: [],
    },
    Mv7cqIG1U8: {
      id: "Mv7cqIG1U8",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 703.4743999999998,
      y: 1821,
      lines: ["Iqq3Y5ZZ81", "pHk548Yi-"],
      areas: [],
    },
    FSRZkP1kFFn: {
      id: "FSRZkP1kFFn",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1396.3311039999999,
      y: 1402.0117840000003,
      lines: ["fuBxSZnPg57", "dLeaEuyzXoD", "NxKgOrwFRfp"],
      areas: [],
    },
    Br8cx3Jp__: {
      id: "Br8cx3Jp__",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1016,
      y: 1821,
      lines: ["9Cnr6PxoBo", "pHk548Yi-", "mxvTmWbV5"],
      areas: [],
    },
    "dE-eiX-D9x": {
      id: "dE-eiX-D9x",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1808.4226399999998,
      y: 1402.0117840000003,
      lines: ["4XYCpT-H7", "NxKgOrwFRfp"],
      areas: [],
    },
    sVs1VTy0O7: {
      id: "sVs1VTy0O7",
      type: "",
      prototype: "vertices",
      name: "Vertex",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1396.3311039999999,
      y: 1299.8316,
      lines: ["EBP3DbILs", "fuBxSZnPg57"],
      areas: [],
    },
  },
  lines: {
    x8uK2KY7N: {
      id: "x8uK2KY7N",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["BhIqlzjc6V", "59HfRP-y-I"],
      holes: [],
    },
    eLuFK3CQYM: {
      id: "eLuFK3CQYM",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["59HfRP-y-I", "vto9I2r0Ik"],
      holes: ["DYAQgX7J5o"],
    },
    "0QnaYf8Alf": {
      id: "0QnaYf8Alf",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["vto9I2r0Ik", "8_B9PydxIZ"],
      holes: ["rea2UYVWB"],
    },
    NxKgOrwFRfp: {
      id: "NxKgOrwFRfp",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["FSRZkP1kFFn", "dE-eiX-D9x"],
      holes: [],
    },
    g8PSB9U2n: {
      id: "g8PSB9U2n",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["0Au9-0AUND", "59HfRP-y-I"],
      holes: [],
    },
    FjSikxngHO: {
      id: "FjSikxngHO",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["8_B9PydxIZ", "Knnacc7HRx"],
      holes: ["X6qG4YY72"],
    },
    "4XYCpT-H7": {
      id: "4XYCpT-H7",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["dE-eiX-D9x", "Knnacc7HRx"],
      holes: ["iHo-01KPQ"],
    },
    "9Cnr6PxoBo": {
      id: "9Cnr6PxoBo",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["59HfRP-y-I", "Br8cx3Jp__"],
      holes: ["vbTDyaE0c8"],
    },
    mxvTmWbV5: {
      id: "mxvTmWbV5",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["Br8cx3Jp__", "8_B9PydxIZ"],
      holes: ["judN-vaJZX"],
    },
    EBP3DbILs: {
      id: "EBP3DbILs",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["BhIqlzjc6V", "sVs1VTy0O7"],
      holes: ["s5x3Jtlhqy"],
    },
    "pHk548Yi-": {
      id: "pHk548Yi-",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["Mv7cqIG1U8", "Br8cx3Jp__"],
      holes: ["3f4V9V5UVZ"],
    },
    dLeaEuyzXoD: {
      id: "dLeaEuyzXoD",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["vto9I2r0Ik", "FSRZkP1kFFn"],
      holes: [],
    },
    fuBxSZnPg57: {
      id: "fuBxSZnPg57",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["sVs1VTy0O7", "FSRZkP1kFFn"],
      holes: [],
    },
    Iqq3Y5ZZ81: {
      id: "Iqq3Y5ZZ81",
      type: "wall",
      prototype: "lines",
      name: "Wall",
      misc: {},
      selected: false,
      properties: {
        height: {
          length: 300,
        },
        thickness: {
          length: 20,
        },
        textureA: "bricks",
        textureB: "bricks",
      },
      visible: true,
      vertices: ["0Au9-0AUND", "Mv7cqIG1U8"],
      holes: [],
    },
  },
  holes: {
    DYAQgX7J5o: {
      idx: "DYAQgX7J5o",
      type: "door-1",
      prototype: "holes",
      name: "Двери",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 80,
        },
        height: {
          length: 215,
        },
        thickness: {
          length: 30,
        },
        flip_orizzontal: false,
      },
      visible: true,
      offset: 0.5513078467544952,
      line: "eLuFK3CQYM",
    },
    vbTDyaE0c8: {
      idx: "vbTDyaE0c8",
      type: "door-1",
      prototype: "holes",
      name: "Двери",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 80,
        },
        height: {
          length: 215,
        },
        thickness: {
          length: 30,
        },
        flip_orizzontal: false,
      },
      visible: true,
      offset: 0.34551495016611294,
      line: "9Cnr6PxoBo",
    },
    "judN-vaJZX": {
      idx: "judN-vaJZX",
      type: "window-1",
      prototype: "holes",
      name: "Окна",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 90,
        },
        height: {
          length: 100,
        },
        thickness: {
          length: 10,
        },
      },
      visible: true,
      offset: 0.5432595568097423,
      line: "mxvTmWbV5",
    },
    "3f4V9V5UVZ": {
      idx: "3f4V9V5UVZ",
      type: "window-1",
      prototype: "holes",
      name: "Окна",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 90,
        },
        height: {
          length: 100,
        },
        thickness: {
          length: 10,
        },
      },
      visible: true,
      offset: 0.5249029199527977,
      line: "pHk548Yi-",
    },
    s5x3Jtlhqy: {
      idx: "s5x3Jtlhqy",
      type: "window-1",
      prototype: "holes",
      name: "Окна",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 90,
        },
        height: {
          length: 100,
        },
        thickness: {
          length: 10,
        },
      },
      visible: true,
      offset: 0.47583420629199996,
      line: "EBP3DbILs",
    },
    rea2UYVWB: {
      idx: "rea2UYVWB",
      type: "door-1",
      prototype: "holes",
      name: "Двери",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 80,
        },
        height: {
          length: 215,
        },
        thickness: {
          length: 30,
        },
        flip_orizzontal: false,
      },
      visible: true,
      offset: 0.3216930599335553,
      line: "0QnaYf8Alf",
    },
    X6qG4YY72: {
      idx: "X6qG4YY72",
      type: "window-1",
      prototype: "holes",
      name: "Окна",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 90,
        },
        height: {
          length: 100,
        },
        thickness: {
          length: 10,
        },
      },
      visible: true,
      offset: 0.4965382246530767,
      line: "FjSikxngHO",
    },
    "iHo-01KPQ": {
      idx: "iHo-01KPQ",
      type: "window-1",
      prototype: "holes",
      name: "Окна",
      misc: {},
      selected: false,
      properties: {
        width: {
          length: 90,
        },
        height: {
          length: 100,
        },
        thickness: {
          length: 10,
        },
      },
      visible: true,
      offset: 0.5036666619760015,
      line: "4XYCpT-H7",
    },
  },
  areas: {
    U263POkyrH: {
      idx: "U263POkyrH",
      type: "area",
      prototype: "areas",
      name: "Area",
      misc: {},
      selected: false,
      properties: {
        patternColor: "#ffffff",
        areaType: "BEDROOM",
      },
      visible: true,
      vertices: ["Mv7cqIG1U8", "0Au9-0AUND", "59HfRP-y-I", "Br8cx3Jp__"],
      holes: [],
    },
    cFtsaaQGvF: {
      idx: "cFtsaaQGvF",
      type: "area",
      prototype: "areas",
      name: "Area",
      misc: {},
      selected: false,
      properties: {
        patternColor: "#8DC646",
        areaType: "CHILDRENS_ROOM",
      },
      visible: true,
      vertices: ["Br8cx3Jp__", "59HfRP-y-I", "vto9I2r0Ik", "8_B9PydxIZ"],
      holes: [],
    },
    KSxvYeh1x23: {
      idx: "KSxvYeh1x23",
      type: "area",
      prototype: "areas",
      name: "Area",
      misc: {},
      selected: false,
      properties: {
        patternColor: "#8DC646",
        areaType: "STUDY_ROOM",
      },
      visible: true,
      vertices: [
        "BhIqlzjc6V",
        "sVs1VTy0O7",
        "FSRZkP1kFFn",
        "vto9I2r0Ik",
        "59HfRP-y-I",
      ],
      holes: [],
    },
    FYpeIzjdeAe: {
      idx: "FYpeIzjdeAe",
      type: "area",
      prototype: "areas",
      name: "Area",
      misc: {},
      selected: false,
      properties: {
        patternColor: "#8DC646",
        areaType: "LIVING_ROOM",
      },
      visible: true,
      vertices: [
        "vto9I2r0Ik",
        "FSRZkP1kFFn",
        "dE-eiX-D9x",
        "Knnacc7HRx",
        "8_B9PydxIZ",
      ],
      holes: [],
    },
  },
  items: {
    "5699908a35": {
      idx: "5699908a35",
      type: "346",
      prototype: "items",
      name: "Стол кофейный Эльта 75;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1568,
      y: 1524,
      rotation: 0,
      catalog: {
        length: 600,
        name: "Стол кофейный Эльта 75;",
        height: 450,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/1c0/550_371_156bd9b85792453fc2de54533db2d5475/Elta_stol_kofe_75_beyts_01.jpg",
        id: 346,
        type_sub_id: 77,
        description: "None description",
        width: 800,
        svg_file: "/images/furniture_categories/coffee_tables.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/coffee_tables.png",
          name: "кофейные столики",
          main_type: "COFFEE_TABLE",
          tags: null,
          id: 77,
          default_image_src:
            "/images/furniture_categories/catalog/coffee_tables.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    a8c0748737: {
      idx: "a8c0748737",
      type: "344",
      prototype: "items",
      name: "Стол барный Берген П-108;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1326,
      y: 1480,
      rotation: -180,
      catalog: {
        length: 600,
        name: "Стол барный Берген П-108;",
        height: 1070,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/02c/550_371_156bd9b85792453fc2de54533db2d5475/bergen_stol_barnyi_bei_ts_0.jpg",
        id: 344,
        type_sub_id: 69,
        description: "None description",
        width: 1200,
        svg_file: "/images/furniture_categories/bar_tables.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/bar_tables.png",
          name: "барные столы",
          main_type: "TABLE",
          tags: null,
          id: 69,
          default_image_src:
            "/images/furniture_categories/catalog/bar_tables.png",
          area_types: ["KITCHEN", "LIVING_ROOM", "STUDY_ROOM"],
        },
      },
    },
    "83270e585b": {
      idx: "83270e585b",
      type: "344",
      prototype: "items",
      name: "Стол барный Берген П-108;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1738,
      y: 1781,
      rotation: -180,
      catalog: {
        length: 600,
        name: "Стол барный Берген П-108;",
        height: 1070,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/02c/550_371_156bd9b85792453fc2de54533db2d5475/bergen_stol_barnyi_bei_ts_0.jpg",
        id: 344,
        type_sub_id: 69,
        description: "None description",
        width: 1200,
        svg_file: "/images/furniture_categories/bar_tables.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/bar_tables.png",
          name: "барные столы",
          main_type: "TABLE",
          tags: null,
          id: 69,
          default_image_src:
            "/images/furniture_categories/catalog/bar_tables.png",
          area_types: ["KITCHEN", "LIVING_ROOM", "STUDY_ROOM"],
        },
      },
    },
    "2038f41c47": {
      idx: "2038f41c47",
      type: "48",
      prototype: "items",
      name: "Брик диван 2-х модульный;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1306,
      y: 1364,
      rotation: 90,
      catalog: {
        length: 1600,
        name: "Брик диван 2-х модульный;",
        height: 780,
        collage_image_src: "https://dev.platformvim.org/images/data/102.png",
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/6f6/550_371_156bd9b85792453fc2de54533db2d5475/%D0%91%D1%80%D0%B8%D0%BA-2%D1%85%D0%BC%D0%BE%D0%B4-172-1.jpg",
        id: 102,
        type_sub_id: 35,
        description: "None description",
        width: 1100,
        svg_file: "/images/furniture_categories/module_sofas.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/module_sofas.png",
          name: "модульные диваны",
          main_type: "COUCH",
          tags: null,
          id: 35,
          default_image_src:
            "/images/furniture_categories/catalog/module_sofas.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    f52d70799d: {
      idx: "f52d70799d",
      type: "501",
      prototype: "items",
      name: "Тумба Давиль 3410;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1425,
      y: 1524,
      rotation: -90,
      catalog: {
        length: 390,
        name: "Тумба Давиль 3410;",
        height: 823,
        default_image_src:
          "https://www.belfan.ru/upload/iblock/c77/l7esfok8etby7uidycxbmow0d6be7z8o/big_outlet_1_.webp",
        id: 501,
        type_sub_id: 92,
        description: "None description",
        width: 1378,
        svg_file: "/images/furniture_categories/cabinets.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/cabinets.png",
          name: "тумбы",
          main_type: "STAND",
          tags: null,
          id: 92,
          default_image_src:
            "/images/furniture_categories/catalog/cabinets.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "BATHROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "5f97ca0062": {
      idx: "5f97ca0062",
      type: "1",
      prototype: "items",
      name: "Телевизор 700х700",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1288,
      y: 1542,
      rotation: 0,
      catalog: {
        length: 40,
        name: "Телевизор 700х700",
        height: 700,
        default_image_src: null,
        id: 1,
        type_sub_id: 79,
        description: "None description",
        width: 700,
        svg_file: "/images/furniture_categories/folding_chairs.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/TVs.png",
          name: "телевизоры",
          main_type: "TV",
          tags: null,
          id: 79,
          default_image_src: "/images/furniture_categories/catalog/TVs.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    b26263a13d: {
      idx: "b26263a13d",
      type: "6",
      prototype: "items",
      name: "СТУЛ нов 400х400",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1738,
      y: 1781,
      rotation: -180,
      catalog: {
        length: 400,
        name: "СТУЛ нов 400х400",
        height: 400,
        default_image_src: "/images/furniture_categories/folding_chairs.png",
        id: 6,
        type_sub_id: 73,
        description: "None description",
        width: 400,
        svg_file: "/images/furniture_categories/folding_chairs.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/folding_chairs.png",
          name: "раскладные стулья",
          main_type: "CHAIR",
          tags: null,
          id: 73,
          default_image_src:
            "/images/furniture_categories/catalog/folding_chairs.png",
          area_types: [
            "KITCHEN",
            "LIVING_ROOM",
            "STUDY_ROOM",
            "CHILDRENS_ROOM",
          ],
        },
      },
    },
    "299cac9464": {
      idx: "299cac9464",
      type: "8389",
      prototype: "items",
      name: "Стул",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1056,
      y: 1751,
      rotation: -90,
      catalog: {
        length: 500,
        name: "Стул",
        height: 400,
        default_image_src: "/images/furniture_categories/folding_chairs.png",
        id: 8389,
        type_sub_id: 73,
        description: "None description",
        width: 500,
        svg_file: "/images/furniture_categories/folding_chairs.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/folding_chairs.png",
          name: "раскладные стулья",
          main_type: "CHAIR",
          tags: null,
          id: 73,
          default_image_src:
            "/images/furniture_categories/catalog/folding_chairs.png",
          area_types: [
            "KITCHEN",
            "LIVING_ROOM",
            "STUDY_ROOM",
            "CHILDRENS_ROOM",
          ],
        },
      },
    },
    "90bc4136b6": {
      idx: "90bc4136b6",
      type: "330",
      prototype: "items",
      name: "Стеллаж Берген В-123;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1336,
      y: 1592,
      rotation: 90,
      catalog: {
        length: 1000,
        name: "Стеллаж Берген В-123;",
        height: 1206,
        default_image_src:
          "https://www.belfan.ru/upload/iblock/53b/oqqmpwrnvpgw9vl02xyi8m19kkpycy65/outlet.webp",
        id: 330,
        type_sub_id: 88,
        description: "None description",
        width: 400,
        svg_file: "/images/furniture_categories/open_shelvings.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/open_shelvings.png",
          name: "открытые стеллажи",
          main_type: "SHELF",
          tags: null,
          id: 88,
          default_image_src:
            "/images/furniture_categories/catalog/open_shelvings.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "TOILET",
            "BATHROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "9d4f105e34": {
      idx: "9d4f105e34",
      type: "108",
      prototype: "items",
      name: "Буфет Ольса 200/220;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 982,
      y: 1759,
      rotation: 90,
      catalog: {
        length: 465,
        name: "Буфет Ольса 200/220;",
        height: 2190,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/782/a725xuzmp8c185ckq2b6moddnv6waaqo/550_371_156bd9b85792453fc2de54533db2d5475/olsa_bufet_200_221_seryy_1.jpg",
        id: 108,
        type_sub_id: 86,
        description: "None description",
        width: 1021,
        svg_file: "/images/furniture_categories/buffets_and_showcases.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file:
            "/images/furniture_categories/buffets_and_showcases.png",
          name: "буфеты и витрины ",
          main_type: "CLOSET",
          tags: null,
          id: 86,
          default_image_src:
            "/images/furniture_categories/catalog/buffets_and_showcases.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    9981819313: {
      idx: "9981819313",
      type: "285",
      prototype: "items",
      name: "Кровать Алези с подьемным механизмом (низкое изножье);",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 820,
      y: 1667,
      rotation: -90,
      catalog: {
        length: 2150,
        name: "Кровать Алези с подьемным механизмом (низкое изножье);",
        height: 0,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/fe2/550_371_156bd9b85792453fc2de54533db2d5475/alezi_krovat_nizk_tabak_01.jpg",
        id: 285,
        type_sub_id: 41,
        description: "None description",
        width: 2052,
        svg_file:
          "/images/furniture_categories/beds_with_lifting_mechanism.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file:
            "/images/furniture_categories/beds_with_lifting_mechanism.png",
          name: "кровати с подъемным механизмом",
          main_type: "BED",
          tags: null,
          id: 41,
          default_image_src:
            "/images/furniture_categories/catalog/beds_with_lifting_mechanism.png",
          area_types: ["BEDROOM", "CHILDRENS_ROOM", "STUDY_ROOM"],
        },
      },
    },
    "61ae17af37": {
      idx: "61ae17af37",
      type: "284",
      prototype: "items",
      name: "Кровать Алези с подьемным механизмом (высокое изножье);",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1277,
      y: 1717,
      rotation: 90,
      catalog: {
        length: 2180,
        name: "Кровать Алези с подьемным механизмом (высокое изножье);",
        height: 0,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/3a5/550_371_156bd9b85792453fc2de54533db2d5475/alezi_krovat_tabak_1.jpg",
        id: 284,
        type_sub_id: 41,
        description: "None description",
        width: 1000,
        svg_file:
          "/images/furniture_categories/beds_with_lifting_mechanism.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file:
            "/images/furniture_categories/beds_with_lifting_mechanism.png",
          name: "кровати с подъемным механизмом",
          main_type: "BED",
          tags: null,
          id: 41,
          default_image_src:
            "/images/furniture_categories/catalog/beds_with_lifting_mechanism.png",
          area_types: ["BEDROOM", "CHILDRENS_ROOM", "STUDY_ROOM"],
        },
      },
    },
    eb7759a9b8: {
      idx: "eb7759a9b8",
      type: "1",
      prototype: "items",
      name: "Телевизор 700х700",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 997,
      y: 1667,
      rotation: 90,
      catalog: {
        length: 40,
        name: "Телевизор 700х700",
        height: 700,
        default_image_src: null,
        id: 1,
        type_sub_id: 79,
        description: "None description",
        width: 700,
        svg_file: "/images/furniture_categories/folding_chairs.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/TVs.png",
          name: "телевизоры",
          main_type: "TV",
          tags: null,
          id: 79,
          default_image_src: "/images/furniture_categories/catalog/TVs.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    f5df1dbe8c: {
      idx: "f5df1dbe8c",
      type: "384",
      prototype: "items",
      name: "Стол письменный Алези 1 (1 тумба слева);",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1056,
      y: 1751,
      rotation: -90,
      catalog: {
        length: 600,
        name: "Стол письменный Алези 1 (1 тумба слева);",
        height: 751,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/e0d/550_371_156bd9b85792453fc2de54533db2d5475/alezi_stol_pism_1tumba_bronza_lev_1.jpg",
        id: 384,
        type_sub_id: 98,
        description: "None description",
        width: 1200,
        svg_file: "/images/furniture_categories/working_tables.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/working_tables.png",
          name: "рабочий стол",
          main_type: "TABLE",
          tags: null,
          id: 98,
          default_image_src:
            "/images/furniture_categories/catalog/working_tables.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "9ff25ba151": {
      idx: "9ff25ba151",
      type: "330",
      prototype: "items",
      name: "Стеллаж Берген В-123;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1063,
      y: 1460,
      rotation: -180,
      catalog: {
        length: 1000,
        name: "Стеллаж Берген В-123;",
        height: 1206,
        default_image_src:
          "https://www.belfan.ru/upload/iblock/53b/oqqmpwrnvpgw9vl02xyi8m19kkpycy65/outlet.webp",
        id: 330,
        type_sub_id: 88,
        description: "None description",
        width: 400,
        svg_file: "/images/furniture_categories/open_shelvings.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/open_shelvings.png",
          name: "открытые стеллажи",
          main_type: "SHELF",
          tags: null,
          id: 88,
          default_image_src:
            "/images/furniture_categories/catalog/open_shelvings.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "TOILET",
            "BATHROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "2832ff4f75": {
      idx: "2832ff4f75",
      type: "2",
      prototype: "items",
      name: "Телевизор 900х900",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1418,
      y: 1524,
      rotation: -90,
      catalog: {
        length: 40,
        name: "Телевизор 900х900",
        height: 900,
        default_image_src: null,
        id: 2,
        type_sub_id: 79,
        description: "None description",
        width: 900,
        svg_file: "/images/furniture_categories/folding_chairs.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/TVs.png",
          name: "телевизоры",
          main_type: "TV",
          tags: null,
          id: 79,
          default_image_src: "/images/furniture_categories/catalog/TVs.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "2714fd3a02": {
      idx: "2714fd3a02",
      type: "552",
      prototype: "items",
      name: "Шкаф для книг Алези 2;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1086,
      y: 1560,
      rotation: -90,
      catalog: {
        length: 1200,
        name: "Шкаф для книг Алези 2;",
        height: 1938,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/cfa/550_371_156bd9b85792453fc2de54533db2d5475/alezi_shkaf_knigi_antich_pat_1.jpg",
        id: 552,
        type_sub_id: 85,
        description: "None description",
        width: 600,
        svg_file: "/images/furniture_categories/bookcases.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/bookcases.png",
          name: "книжные шкафы",
          main_type: "CLOSET",
          tags: null,
          id: 85,
          default_image_src:
            "/images/furniture_categories/catalog/bookcases.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "50346dabe1": {
      idx: "50346dabe1",
      type: "64",
      prototype: "items",
      name: "Грей-2 диван 2-х модульный;",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1743,
      y: 1528,
      rotation: 90,
      catalog: {
        length: 1100,
        name: "Грей-2 диван 2-х модульный;",
        height: 820,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/b17/550_371_156bd9b85792453fc2de54533db2d5475/grey_2_divan_2_mod_1EL_1ER.jpg",
        id: 64,
        type_sub_id: 35,
        description: "None description",
        width: 2330,
        svg_file: "/images/furniture_categories/module_sofas.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/module_sofas.png",
          name: "модульные диваны",
          main_type: "COUCH",
          tags: null,
          id: 35,
          default_image_src:
            "/images/furniture_categories/catalog/module_sofas.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
    "9e0bf232d2": {
      idx: "9e0bf232d2",
      type: "8389",
      prototype: "items",
      name: "Стул",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1326,
      y: 1480,
      rotation: -180,
      catalog: {
        length: 500,
        name: "Стул",
        height: 400,
        default_image_src: "/images/furniture_categories/folding_chairs.png",
        id: 8389,
        type_sub_id: 73,
        description: "None description",
        width: 500,
        svg_file: "/images/furniture_categories/folding_chairs.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file: "/images/furniture_categories/folding_chairs.png",
          name: "раскладные стулья",
          main_type: "CHAIR",
          tags: null,
          id: 73,
          default_image_src:
            "/images/furniture_categories/catalog/folding_chairs.png",
          area_types: [
            "KITCHEN",
            "LIVING_ROOM",
            "STUDY_ROOM",
            "CHILDRENS_ROOM",
          ],
        },
      },
    },
    dfb21d295b: {
      idx: "dfb21d295b",
      type: "165",
      prototype: "items",
      name: "Витрина 4-х дв. Chicago (левая);",
      misc: {},
      selected: false,
      properties: {},
      visible: true,
      x: 1468,
      y: 1785,
      rotation: -180,
      catalog: {
        length: 518,
        name: "Витрина 4-х дв. Chicago (левая);",
        height: 2000,
        default_image_src:
          "https://www.belfan.ru/upload/resize_cache/iblock/1a6/550_371_156bd9b85792453fc2de54533db2d5475/Forest_vitrina_3dv_nizkaya_dub_naturalnyy_01.jpg",
        id: 165,
        type_sub_id: 86,
        description: "None description",
        width: 1044,
        svg_file: "/images/furniture_categories/buffets_and_showcases.png",
        tags: null,
        default_price: null,
        type_sub: {
          default_svg_file:
            "/images/furniture_categories/buffets_and_showcases.png",
          name: "буфеты и витрины ",
          main_type: "CLOSET",
          tags: null,
          id: 86,
          default_image_src:
            "/images/furniture_categories/catalog/buffets_and_showcases.png",
          area_types: [
            "BEDROOM",
            "LIVING_ROOM",
            "DINING_ROOM",
            "CHILDRENS_ROOM",
            "STUDY_ROOM",
            "STUDY_ROOM",
          ],
        },
      },
    },
  },
  selected: {
    vertices: [],
    lines: [],
    holes: [],
    areas: [],
    items: [],
  },
  collage_data: {
    areas: {
      U263POkyrH: {
        //#1е помещение
        idx: "U263POkyrH",

        positions: {
          kreslo: {
            x: 0,
            y: -30,
          },
          divan: {
            x: 0,
            y: -30,
          },
          stol: {
            x: 0,
            y: -250,
          },
          shkaf: {
            x: 0,
            y: -10,
          },
        },
        wall: {
          id: 16,
          width: 1000,
          image:
            "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/wall2.jpg",
        },
        floor: {
          id: 15,
          width: 5000,
          height: "25%",
          image:
            "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/floor3.jpg",
        },
        items: [
          {
            idx: "DYAQgX7J5o",
            userX: 0,
            userY: 0,
            type: "door",
            layer: "1",
            //   name: "Дверь №1",
            x: 0,
            y: -6,
            //  width: 1000,
            position: "8", //# unique value!
            //    image:               "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/door.png",
            //    description: "Дверь входная",
            is_empty: 0,
          },
          {
            idx: "2038f41c47",
            userX: 0,
            userY: 0,
            //   furniture_id: 102,
            type: "divan",
            layer: "1",
            //    name: "Диван №102",
            //  width: 2000,
            //   alike: "100,101,102",
            position: "2",
            //      image: "https://dev.platformvim.org/images/data/102.png",
            //     image_left: "https://dev.platformvim.org/images/data/102.png",
            //     image_right: "https://dev.platformvim.org/images/data/102.png",
            //    description: "Диван 102",
            is_empty: 0,
          },
        ],
      },
      // cFtsaaQGvF: {
      //   id: "cFtsaaQGvF",
      //   positions: {
      //     kreslo: {
      //       x: 0,
      //       y: -30,
      //     },
      //     divan: {
      //       x: 0,
      //       y: -30,
      //     },
      //     stol: {
      //       x: 0,
      //       y: -250,
      //     },
      //     shkaf: {
      //       x: 0,
      //       y: -10,
      //     },
      //   },
      //   wall: {
      //     id: 16,
      //     width: 1000,
      //     image:
      //       "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/wall2.jpg",
      //   },
      //   floor: {
      //     id: 15,
      //     width: 5000,
      //     height: "25%",
      //     image:
      //       "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/floor3.jpg",
      //   },
      //   items: [
      //     {
      //       x: 0,
      //       y: -6,
      //       userX: 0,
      //       userY: 0,
      //       id: "DYAQgX7J5o",
      //       name: "Дверь №1",
      //       type: "decor",
      //       image:
      //         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/door.png",
      //       layer: "1",
      //       price: 45000,
      //       width: 1000,
      //       is_empty: 0,
      //       position: "1",
      //       description: "Дверь входная",
      //     },

      //     {
      //       id: "2038f41c47",
      //       userX: 0,
      //       userY: 0,
      //       name: "Диван №101",
      //       type: "divan",
      //       alike: "100,101,102,103",
      //       image: "https://dev.platformvim.org/images/data/101.png",
      //       layer: "1",
      //       price: 19000,
      //       width: 2000,
      //       is_empty: 0,
      //       position: "3",
      //       image_left: "https://dev.platformvim.org/images/data/101.png",
      //       description: "Диван 101",
      //       image_right: "https://dev.platformvim.org/images/data/101.png",
      //       furniture_id: 101,
      //     },

      //     {
      //       x: 0,
      //       y: -60,
      //       userX: 0,
      //       userY: 0,
      //       id: "rfv",
      //       name: "Stul 603",
      //       type: "stul",
      //       alike: "600,602,603,604",
      //       image: "https://dev.platformvim.org/images/data/603.png",
      //       layer: "1",
      //       price: 5000,
      //       width: 600,
      //       is_empty: 0,
      //       position: "41",
      //       image_left: "https://dev.platformvim.org/images/data/603.png",
      //       description: "Stul 603",
      //       image_right: "https://dev.platformvim.org/images/data/603.png",
      //       furniture_id: 603,
      //     },
      //     {
      //       x: 0,
      //       y: 0,
      //       userX: 0,
      //       userY: 0,
      //       id: "gb",
      //       name: "Стол №507",
      //       type: "stol",
      //       alike: "500,507,501,502,503",
      //       image:
      //         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
      //       layer: "2",
      //       price: 5000,
      //       width: 1200,
      //       is_empty: 0,
      //       position: "3",
      //       image_left:
      //         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
      //       description: "Стол №507 ровный",
      //       image_right:
      //         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
      //       furniture_id: 507,
      //     },

      //     {
      //       id: "yhn",
      //       userX: 0,
      //       userY: 0,
      //       name: "Шкаф №1001",
      //       type: "shkaf",
      //       alike: "1001,1002,1003,1000",
      //       image: "https://dev.platformvim.org/images/data/1001.png",
      //       layer: "1",
      //       price: 10000,
      //       width: 1500,
      //       is_empty: 0,
      //       position: "3",
      //       image_left: "https://dev.platformvim.org/images/data/1001.png",
      //       description: "Шкаф №1001 вместительный",
      //       image_right: "https://dev.platformvim.org/images/data/1001.png",
      //       furniture_id: 1001,
      //     },
      //   ],
      // },
    },
  },
  layout_area_temp: "BEDROOM",
};
