var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Z_INDEX } from "src/constants";
import { TextStyled } from "src/ui-kit/text/styled";
import { memo } from "react";
var itemsWidth = "290px";
export var SubMenuStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  left: 100px;\n  top: 265px;\n  background-color: #fff;\n  border-radius: 14px;\n\n  margin-left: 20px;\n  margin-top: 20px;\n\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  height: max-content;\n\n  z-index: 10000; //", ";\n\n  max-width: 320px;\n"], ["\n  position: absolute;\n  left: 100px;\n  top: 265px;\n  background-color: #fff;\n  border-radius: 14px;\n\n  margin-left: 20px;\n  margin-top: 20px;\n\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  height: max-content;\n\n  z-index: 10000; //", ";\n\n  max-width: 320px;\n"])), Z_INDEX.turboMax);
export var SubMenuImagesBlock = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: baseline;\n  max-height: 200px;\n  min-height: 40px;\n  overflow-x: auto;\n  // justify-content: space-between;\n  gap: 10px;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: baseline;\n  max-height: 200px;\n  min-height: 40px;\n  overflow-x: auto;\n  // justify-content: space-between;\n  gap: 10px;\n"])));
var activeType = "\nimg {\n  background-color: #8dc646;\n  border-radius: 10px;\n}\n \n  svg {\n    fill: #8dc646;\n  }\n  & svg {\n    stroke: black;\n  }\n\n  nth-child(2) {\n    stroke: black;\n  }\n  rect:nth-of-type(1),\n  rect:nth-of-type(2),\n  rect:nth-of-type(3),\n  rect:nth-of-type(4),\n  rect:nth-of-type(5),\n  rect:nth-of-type(6),\n  rect:nth-of-type(7),\n  rect:nth-of-type(8),\n  rect:nth-of-type(9),\n  rect:nth-of-type(10),\n  rect:nth-of-type(11) {\n    stroke: #1f1f1f;\n  }\n\n  path,\n  circle,\n  line {\n    stroke: black;\n  }\n";
export var SubMenuTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n"])));
export var SubMenuImagesUrl = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 80px;\n  width: 80px;\n  object-fit: contain;\n  margin-bottom: 12px;\n  padding: 4px;\n"], ["\n  height: 80px;\n  width: 80px;\n  object-fit: contain;\n  margin-bottom: 12px;\n  padding: 4px;\n"])));
export var CurrentFurnitureInfo = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  // justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  // justify-content: space-between;\n"])));
export var CurrentFurnitureImg = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 125px;\n  width: 125px;\n  object-fit: contain;\n  margin-bottom: 12px;\n  //padding: 4px;\n  border-radius: 10px;\n"], ["\n  height: 125px;\n  width: 125px;\n  object-fit: contain;\n  margin-bottom: 12px;\n  //padding: 4px;\n  border-radius: 10px;\n"])));
export var TitleText = memo(function CommonText(_a) {
    var _b = _a.fontSize, fontSize = _b === void 0 ? "18px" : _b, _c = _a.color, color = _c === void 0 ? "black" : _c, _d = _a.fontWeight, fontWeight = _d === void 0 ? "600" : _d, text = _a.text;
    return (React.createElement(TextStyled, { color: color, fontWeight: fontWeight, fontSize: fontSize }, text));
});
export var ButtonContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 0px;\n  max-width: 80px;\n  width: 33.33%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 0px;\n  max-width: 80px;\n  width: 33.33%;\n"])));
export var ButtonText = memo(function CommonText(_a) {
    var _b = _a.fontSize, fontSize = _b === void 0 ? "14px" : _b, _c = _a.color, color = _c === void 0 ? "black" : _c, _d = _a.fontWeight, fontWeight = _d === void 0 ? "500" : _d, text = _a.text;
    return (React.createElement(TextStyled, { color: color, fontWeight: fontWeight, fontSize: fontSize }, text));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
