var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var TabsConainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  //gap: 20px;\n  //margin-bottom: 20px;\n  overflow: auto;\n  scrollbar-width: none;\n  width: 100%;\n  box-sizing: border-box;\n  ::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  display: flex;\n  //gap: 20px;\n  //margin-bottom: 20px;\n  overflow: auto;\n  scrollbar-width: none;\n  width: 100%;\n  box-sizing: border-box;\n  ::-webkit-scrollbar {\n    display: none;\n  }\n"])));
export var Tabs = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  margin-bottom: 10px;\n  overflow: visible;\n"], ["\n  display: flex;\n  gap: 20px;\n  margin-bottom: 10px;\n  overflow: visible;\n"])));
export var Tab = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 20px;\n  border-bottom: ", ";\n  color: ", ";\n"], ["\n  font-size: 20px;\n  border-bottom: ", ";\n  color: ", ";\n"])), function (_a) {
    var $isSelect = _a.$isSelect;
    return $isSelect ? "2px solid #000" : "0.5px solid #00000050";
}, function (_a) {
    var $isSelect = _a.$isSelect;
    return ($isSelect ? " #000" : " #00000050");
});
export var Conainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  scrollbar-width: none;\n"], ["\n  scrollbar-width: none;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
