import { fetchPostRequests } from "src/api/fetch/post";
import { generateAuto } from "src/api/urls/generate-auto";
import { getProjectInfo } from '../../fetch/get-project-info';
export var postGenerateAutoRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c;
    var project_layout_id = getProjectInfo().layoutId;
    // const callBackOnSuccess = () => {
    fetchPostRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: "".concat(generateAuto).concat(project_layout_id, "/auto"),
        // data: { project_layout_id },
    });
    // }
};
