var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
var defaultSize = {
    width: 0,
    heidth: 0,
};
export var useCheckResize = function () {
    var _a = __read(useState(defaultSize), 2), size = _a[0], setSize = _a[1];
    var setNewSize = function () {
        setSize({
            width: window.innerWidth,
            heidth: window.innerHeight,
        });
    };
    useEffect(function () {
        setNewSize();
        window.addEventListener("resize", setNewSize);
        return function () {
            window.removeEventListener("resize", setNewSize);
        };
    }, []);
    return size;
};
