var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Label = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 21.86px;\n  margin-bottom: 7px;\n"], ["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 21.86px;\n  margin-bottom: 7px;\n"])));
export var ChildrenBlock = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  gap: 0 30px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  & > div {\n    width: 100%;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  gap: 0 30px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  & > div {\n    width: 100%;\n  }\n"])));
export var ChildrenAgeBlock = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n  }\n"])));
export var AdultBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
