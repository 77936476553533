var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useState } from "react";
import { Modal } from "src/ui-kit/modal";
import { Content, Title, InputWrapper, PreviewWrapper, ButtonWrapper, UploadButton, CloseButton, HiddenFileInput, FileInputLabel, } from "./styled";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
export var PlanRecorgnition = memo(function PlanRecorgnition(_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, isLoadingPlanRecognition = _a.isLoadingPlanRecognition, onFileImport = _a.onFileImport;
    var _b = __read(useState(null), 2), selectedFile = _b[0], setSelectedFile = _b[1];
    var handleFileChange = function (e) {
        var _a;
        var file = ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null;
        setSelectedFile(file);
    };
    var handleUpload = function () {
        if (selectedFile) {
            var reader_1 = new FileReader();
            var formData = new FormData();
            formData.append("file", selectedFile);
            onFileImport(formData);
            reader_1.onload = function () {
                if (reader_1.result) {
                    // onFileImport(formData);
                }
            };
            reader_1.readAsDataURL(selectedFile);
        }
    };
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
        React.createElement(Content, null,
            React.createElement(Title, null, "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u0435 \u043F\u043B\u0430\u043D"),
            React.createElement(InputWrapper, null,
                React.createElement(FileInputLabel, { htmlFor: "fileInput" }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0444\u0430\u0439\u043B"),
                React.createElement(HiddenFileInput, { id: "fileInput", type: "file", accept: "image/*", onChange: handleFileChange })),
            selectedFile && (React.createElement(PreviewWrapper, null,
                React.createElement("img", { src: URL.createObjectURL(selectedFile), alt: "Preview" }))),
            React.createElement(ButtonWrapper, null,
                React.createElement(CommonButton, { isLoading: isLoadingPlanRecognition, onClick: handleUpload },
                    React.createElement(UploadButton, null,
                        React.createElement(CommonText, { color: "#fff", text: "\u0420\u0430\u0441\u043F\u043E\u0437\u043D\u0430\u0442\u044C" }))),
                React.createElement(CommonButton, { isLoading: isLoadingPlanRecognition, onClick: onClose },
                    React.createElement(CloseButton, null,
                        React.createElement(CommonText, { color: "#fff", text: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" })))))));
});
