var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  //background-color: #ffffff;\n  width: min-content;\n  //width: 200px;\n  position: relative;\n\n  display: flex;\n  //gap: 20px;\n  flex-direction: column;\n  @media (max-width: 480px) {\n    width: auto;\n  }\n"], ["\n  //background-color: #ffffff;\n  width: min-content;\n  //width: 200px;\n  position: relative;\n\n  display: flex;\n  //gap: 20px;\n  flex-direction: column;\n  @media (max-width: 480px) {\n    width: auto;\n  }\n"])));
export var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  width: 100%;\n"], ["\n  text-align: center;\n  width: 100%;\n"])));
export var Adornment = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var InputBlock = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 0.5px solid #303030;\n  display: flex;\n  align-items: center;\n  padding: 11px 20px 8px 20px;\n  border-radius: 10px;\n  //  box-shadow: 0 0 7px 0 gray;\n  width: max-content;\n  /* &--focused {\n      box-shadow: 0 0 7px 0 red;\n    } */\n  gap: 27px;\n\n  @media (max-width: 480px) {\n    width: calc(50% - 8px);\n  }\n"], ["\n  border: 0.5px solid #303030;\n  display: flex;\n  align-items: center;\n  padding: 11px 20px 8px 20px;\n  border-radius: 10px;\n  //  box-shadow: 0 0 7px 0 gray;\n  width: max-content;\n  /* &--focused {\n      box-shadow: 0 0 7px 0 red;\n    } */\n  gap: 27px;\n\n  @media (max-width: 480px) {\n    width: calc(50% - 8px);\n  }\n"])));
export var Input = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: 0;\n  text-decoration: none;\n  width: 110px;\n  box-sizing: border-box;\n  &:focus {\n    outline: none;\n  }\n"], ["\n  border: 0;\n  text-decoration: none;\n  width: 110px;\n  box-sizing: border-box;\n  &:focus {\n    outline: none;\n  }\n"])));
export var Inputs = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
