import { getProjectInfo } from '../../fetch/get-project-info';
import { fetchPatchRequests } from 'src/api/fetch/patch';
export var postRoomsRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data;
    var _d = getProjectInfo(), projectId = _d.projectId, layoutId = _d.layoutId;
    fetchPatchRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: "https://demo.platformvim.org/api/v2/layouts/".concat(layoutId),
        data: { data: data },
    });
};
