var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useMemo, useEffect, } from "react";
import { useCheckResize } from "src/hooks/useCheckResize";
import { QuestionaryModal } from "./questionaryModal/questionaryModal";
import { FamilyInfo } from "./pages/familyInfo/familyInfo";
import { MoneyInfo } from "./pages/moneyInfo/moneyInfo";
import { RoomStyleInfo } from "./pages/roomStyleInfo/roomStyleInfo";
import { RoomColorInfo } from "./pages/roomColorInfo/roomColorInfo";
import { Project } from "src/class/export";
var areas = {
    KITCHEN: "Кухня",
    LIVING_ROOM: "Гостиная",
    BATHROOM: "Ванна",
    BEDROOM: "Спальня",
    CHILDRENS_ROOM: "Детская комната",
    STUDY_ROOM: "Кабинет",
    TOILET: "Туалет",
};
export var Questionary = function (props) {
    var state = Project.unselectAll(props.state).updatedState;
    var stateScene = state.get("scene").toJS();
    var layer = stateScene.layers["layer-1"];
    var rooms = Object.keys(layer.areas).map(function (el) { return areas[layer.areas[el].properties.areaType]; });
    var areaTypes = Object.keys(layer.areas).map(function (el) { return layer.areas[el].properties.areaType; });
    var roomsStartData = areaTypes.map(function (el, i) { return ({
        areaType: el,
        style: null,
        isLight: true,
        isWarm: true,
        isPastel: true,
        isCurrent: i === 0,
    }); });
    var _a = __read(useState(1), 2), pageNumber = _a[0], setPageNumber = _a[1];
    var _b = __read(useState(0), 2), amountOfChildren = _b[0], setAmountOfChildren = _b[1];
    var _c = __read(useState(0), 2), amountOfAdultM = _c[0], setAmountOfAdultM = _c[1];
    var _d = __read(useState(0), 2), amountOfAdultF = _d[0], setAmountOfAdultF = _d[1];
    var _e = __read(useState([]), 2), childrenInfo = _e[0], setChildrenInfo = _e[1];
    var _f = __read(useState(null), 2), priceSegment = _f[0], setPriceSegment = _f[1];
    var _g = __read(useState(0), 2), roomTab = _g[0], setRoomTab = _g[1];
    var _h = __read(useState(null), 2), roomStyle = _h[0], setRoomStyle = _h[1];
    var _j = __read(useState(null), 2), roomColor = _j[0], setRoomColor = _j[1];
    var _k = __read(useState(roomsStartData), 2), roomsData = _k[0], setRoomsData = _k[1];
    useEffect(function () {
        if (childrenInfo.length < amountOfChildren) {
            var rest = Array(amountOfChildren - childrenInfo.length)
                .fill(null)
                .map(function (a) {
                return {
                    age: 1,
                    isMale: true,
                };
            });
            var newArr = __spreadArray([], __read(childrenInfo), false);
            newArr.push.apply(newArr, __spreadArray([], __read(rest), false));
            setChildrenInfo(newArr);
        }
        else {
            //   console.log();
            setChildrenInfo(function (p) {
                return p.slice(0, p.length - (p.length - amountOfChildren));
            });
        }
    }, [amountOfChildren]);
    var size = useCheckResize();
    var totalPages = useMemo(function () {
        return size.width < 600 ? 4 : 3;
    }, [size.width]);
    var onClose = function () {
        props.setIsOpen(false);
    };
    var handeSetChildAge = function (newAge, index) {
        setChildrenInfo(function (p) {
            return p.map(function (el, i) {
                if (i === index) {
                    el.age = newAge;
                    return __assign({}, el);
                }
                return el;
            });
        });
    };
    var handeSetChildSex = function (index) {
        setChildrenInfo(function (p) {
            return p.map(function (el, i) {
                if (i === index) {
                    el.isMale = !el.isMale;
                    return __assign({}, el);
                }
                return el;
            });
        });
    };
    var handleSendDataToLayout = function () {
        var layoutRoomsData = {};
        roomsData.forEach(function (el) {
            var _a;
            var a = String(el.areaType) + "-1";
            layoutRoomsData[el.areaType] = (_a = {},
                _a[a] = {
                    style: el.style,
                },
                _a);
        });
        var data = {
            residents: {
                children: childrenInfo.map(function (el) { return ({
                    age: el.age,
                    sex: el.isMale ? "M" : "F",
                }); }),
                adults: {
                    F: amountOfAdultF,
                    M: amountOfAdultM,
                },
            },
            rooms: layoutRoomsData,
        };
        props.handleGenerateAuto(data);
    };
    return (React.createElement(QuestionaryModal, { pageNumber: pageNumber, setPageNumber: setPageNumber, 
        //  totalPages={totalPages}
        isOpen: props.isOpen, onClose: onClose, title: "Авторасстановка", totalPages: totalPages, width: size.width, setRoomTab: setRoomTab, 
        //  handleGenerateAuto={props.handleGenerateAuto}
        handleSendDataToLayout: handleSendDataToLayout },
        pageNumber === 1 && totalPages === 3 && (React.createElement(React.Fragment, null,
            React.createElement(FamilyInfo, { amountOfChildren: amountOfChildren, setAmountOfChildren: setAmountOfChildren, childrenInfo: childrenInfo, handeSetChildAge: handeSetChildAge, handeSetChildSex: handeSetChildSex, amountOfAdultM: amountOfAdultM, setAmountOfAdultM: setAmountOfAdultM, amountOfAdultF: amountOfAdultF, setAmountOfAdultF: setAmountOfAdultF, width: size.width }),
            React.createElement(MoneyInfo, { priceSegment: priceSegment, setPriceSegment: setPriceSegment, width: size.width }))),
        pageNumber === 1 && totalPages === 4 && (React.createElement(React.Fragment, null,
            React.createElement(FamilyInfo, { amountOfChildren: amountOfChildren, setAmountOfChildren: setAmountOfChildren, childrenInfo: childrenInfo, handeSetChildAge: handeSetChildAge, handeSetChildSex: handeSetChildSex, amountOfAdultM: amountOfAdultM, setAmountOfAdultM: setAmountOfAdultM, amountOfAdultF: amountOfAdultF, setAmountOfAdultF: setAmountOfAdultF, width: size.width }))),
        pageNumber === 2 && totalPages === 4 && (React.createElement(React.Fragment, null,
            React.createElement(MoneyInfo, { priceSegment: priceSegment, setPriceSegment: setPriceSegment, width: size.width }))),
        ((pageNumber === 2 && totalPages === 3) ||
            (pageNumber === 3 && totalPages === 4)) && (React.createElement(React.Fragment, null,
            React.createElement(RoomStyleInfo, { roomStyle: roomStyle, setRoomStyle: setRoomStyle, roomTab: roomTab, setRoomTab: setRoomTab, width: size.width, rooms: rooms, roomsData: roomsData, setRoomsData: setRoomsData }))),
        ((pageNumber === 3 && totalPages === 3) ||
            (pageNumber === 4 && totalPages === 4)) && (React.createElement(React.Fragment, null,
            React.createElement(RoomColorInfo, { roomColor: roomColor, setRoomColor: setRoomColor, roomTab: roomTab, setRoomTab: setRoomTab, width: size.width, rooms: rooms, roomsData: roomsData, setRoomsData: setRoomsData })))));
};
