import React from "react";
import { ModalBackdrop, QuestionaryContainer, CloseButton, QuestionaryTitle, PageNumber, ButtonBlock, BackButton, StyledButton, ButtonBlockContainer, StyledImg,
// NextButton,
// ModalContent,
 } from "./styled";
import { CommonButton } from "src/ui-kit/button";
export var QuestionaryModal = function (props) {
    var pageNumber = props.pageNumber, setPageNumber = props.setPageNumber, onClose = props.onClose, isOpen = props.isOpen, title = props.title, totalPages = props.totalPages, width = props.width, setRoomTab = props.setRoomTab, 
    //    handleGenerateAuto,
    handleSendDataToLayout = props.handleSendDataToLayout;
    return (React.createElement(ModalBackdrop, { "$isOpen": isOpen },
        React.createElement(QuestionaryContainer, null,
            React.createElement(CloseButton, { onClick: onClose }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"),
            React.createElement(QuestionaryTitle, null,
                title,
                " ",
                React.createElement(PageNumber, null, "".concat(pageNumber, "/").concat(totalPages))),
            React.createElement("div", null, props.children),
            React.createElement(ButtonBlockContainer, null,
                width > 480 && (React.createElement(ButtonBlock, null,
                    pageNumber > 1 && (React.createElement(CommonButton, { onClick: function () {
                            setRoomTab(0);
                            setPageNumber(function (p) { return p - 1; });
                        } },
                        React.createElement(BackButton, null,
                            React.createElement(StyledImg, { src: require("src/assets/img/chevron-back.png"), alt: "Back" })))),
                    React.createElement(CommonButton, { onClick: function () {
                            onClose();
                            handleSendDataToLayout();
                            //   handleGenerateAuto();
                        } },
                        React.createElement(StyledButton, { "$isMain": pageNumber === 3 }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C \u0440\u0430\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043A\u0443")),
                    pageNumber < 3 && (React.createElement(CommonButton, { onClick: function () {
                            setRoomTab(0);
                            setPageNumber(function (p) { return p + 1; });
                        } },
                        React.createElement(StyledButton, { "$isMain": true }, "\u0414\u0430\u043B\u0435\u0435"))))),
                width <= 480 && (React.createElement(ButtonBlock, null,
                    pageNumber > 1 && (React.createElement(CommonButton, { onClick: function () {
                            setRoomTab(0);
                            setPageNumber(function (p) { return p - 1; });
                        } },
                        React.createElement(BackButton, null,
                            React.createElement(StyledImg, { src: require("src/assets/img/chevron-back.png"), alt: "Back" })))),
                    pageNumber < totalPages && (React.createElement(CommonButton, { onClick: function () {
                            setRoomTab(0);
                            setPageNumber(function (p) { return p + 1; });
                        } },
                        React.createElement(StyledButton, { "$isMain": true }, "\u0414\u0430\u043B\u0435\u0435"))),
                    React.createElement(CommonButton, { onClick: function () {
                            onClose();
                            handleSendDataToLayout();
                            // handleGenerateAuto();
                        }, isFullWidth: true },
                        React.createElement(StyledButton, { "$isMain": pageNumber === 3 }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C \u0440\u0430\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043A\u0443"))))))));
};
