var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Range, getTrackBackground } from "react-range";
//import { getTrackBackground } from "../src/index";
// const STEP = 0.1;
// const MIN = 0;
// const MAX = 100;
var TwoThumbsRange = function (_a) {
    var rtl = _a.rtl, step = _a.step, min = _a.min, max = _a.max, values = _a.values, setValues = _a.setValues;
    // const [values, setValues] = React.useState([25, 75]);
    return (React.createElement("div", { style: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
        } },
        React.createElement(Range, { values: values, step: step, min: min, max: max, rtl: rtl, onChange: function (values) {
                setValues(values);
            }, renderTrack: function (_a) {
                var props = _a.props, children = _a.children;
                return (React.createElement("div", { onMouseDown: props.onMouseDown, onTouchStart: props.onTouchStart, style: __assign(__assign({}, props.style), { height: "36px", display: "flex", width: "100%" }) },
                    React.createElement("div", { ref: props.ref, style: {
                            height: "3px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                                values: values,
                                colors: ["#828181", "#8DC646", "#828181"],
                                min: min,
                                max: max,
                                rtl: rtl,
                            }),
                            alignSelf: "center",
                        } }, children)));
            }, renderThumb: function (_a) {
                var props = _a.props, isDragged = _a.isDragged;
                return (React.createElement("div", __assign({}, props, { key: props.key, style: __assign(__assign({}, props.style), { height: "10px", width: "10px", borderRadius: "5px", backgroundColor: "#8DC646", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #828181" }) })));
            } })));
};
export default TwoThumbsRange;
