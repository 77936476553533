import React from "react";
import { Label, ChildrenBlock, ChildrenAgeBlock, AdultBox } from "./styled";
import { SelectBox } from "src/ui-kit/select-box";
import { BlockTitle } from "../../styled";
import { CheckboxMF } from "./checkboxMF/checkboxMF";
var amountsOfAdult = [
    { value: "0", label: "0", id: "0" },
    { value: "1", label: "1", id: "1" },
    { value: "2", label: "2", id: "2" },
    { value: "3", label: "3", id: "3" },
    { value: "4", label: "4", id: "4" },
    { value: "5", label: "5", id: "5" },
    { value: "6", label: "6", id: "6" },
    { value: "7", label: "7", id: "7" },
    { value: "8", label: "8", id: "8" },
    { value: "9", label: "9", id: "9" },
    // { value: "10", label: "10", id: "10" },
];
var amountsOfChildren = [
    { value: "0", label: "0", id: "0" },
    { value: "1", label: "1", id: "1" },
    { value: "2", label: "2", id: "2" },
    { value: "3", label: "3", id: "3" },
    { value: "4", label: "4", id: "4" },
    { value: "5", label: "5", id: "5" },
    { value: "6", label: "6", id: "6" },
    { value: "7", label: "7", id: "7" },
    { value: "8", label: "8", id: "8" },
    { value: "9", label: "9", id: "9" },
];
var childrenAges = [
    { value: "1", label: "1", id: "1" },
    { value: "2", label: "2", id: "2" },
    { value: "3", label: "3", id: "3" },
    { value: "4", label: "4", id: "4" },
    { value: "5", label: "5", id: "5" },
    { value: "6", label: "6", id: "6" },
    { value: "7", label: "7", id: "7" },
    { value: "8", label: "8", id: "8" },
    { value: "9", label: "9", id: "9" },
    { value: "10", label: "10", id: "10" },
    { value: "11", label: "11", id: "11" },
    { value: "12", label: "12", id: "12" },
    { value: "13", label: "13", id: "13" },
    { value: "14", label: "14", id: "14" },
    { value: "15", label: "15", id: "15" },
    { value: "16", label: "16", id: "16" },
    { value: "17", label: "17", id: "17" },
];
export var FamilyInfo = function (_a) {
    //const [amountOfChildren, setAmountOfChildren] = useState(0);
    var amountOfChildren = _a.amountOfChildren, setAmountOfChildren = _a.setAmountOfChildren, amountOfAdultM = _a.amountOfAdultM, setAmountOfAdultM = _a.setAmountOfAdultM, amountOfAdultF = _a.amountOfAdultF, setAmountOfAdultF = _a.setAmountOfAdultF, childrenInfo = _a.childrenInfo, handeSetChildAge = _a.handeSetChildAge, handeSetChildSex = _a.handeSetChildSex, width = _a.width;
    var handleSelectAdultMAmount = function (e) {
        // console.log(e.value);
        setAmountOfAdultM(Number(e.value));
    };
    var handleSelectAdultWAmount = function (e) {
        setAmountOfAdultF(Number(e.value));
    };
    var handleSelectChildrenAmount = function (k) {
        setAmountOfChildren(Number(k.value));
    };
    var handleSelectChildAge = function (e, i) {
        handeSetChildAge(Number(e.value), i);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(BlockTitle, null, "\u0421\u043A\u043E\u043B\u044C\u043A\u043E \u043B\u044E\u0434\u0435\u0439 \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u0435\u0442:"),
        React.createElement(AdultBox, null,
            React.createElement("div", null,
                React.createElement(Label, null, "\u0412\u0437\u0440\u043E\u0441\u043B\u044B\u0445 \u043C\u0443\u0436\u0447\u0438\u043D"),
                React.createElement(SelectBox, { options: amountsOfAdult, onValueChange: handleSelectAdultMAmount, hasBorder: true, customFontsizeTitle: "18px", selectedValue: amountOfAdultM, customWidth: width > 480 ? "100px" : "100%" })),
            React.createElement("div", null,
                React.createElement(Label, null, "\u0412\u0437\u0440\u043E\u0441\u043B\u044B\u0445 \u0436\u0435\u043D\u0449\u0438\u043D"),
                React.createElement(SelectBox, { options: amountsOfAdult, onValueChange: handleSelectAdultWAmount, hasBorder: true, customFontsizeTitle: "18px", selectedValue: amountOfAdultF, customWidth: width > 480 ? "100px" : "100%" }))),
        React.createElement(ChildrenBlock, null,
            React.createElement("div", null,
                React.createElement(Label, null, "\u0414\u0435\u0442\u0435\u0439"),
                React.createElement(SelectBox, { options: amountsOfChildren, onValueChange: handleSelectChildrenAmount, hasBorder: true, customFontsizeTitle: "18px", selectedValue: amountOfChildren, customWidth: width > 480 ? "100px" : "100%" })),
            amountOfChildren > 0 && (React.createElement("div", null,
                React.createElement(Label, null, "\u0412\u043E\u0437\u0440\u0430\u0441\u0442 \u0434\u0435\u0442\u0435\u0439"),
                React.createElement(ChildrenAgeBlock, null, childrenInfo.map(function (el, i) {
                    return (React.createElement("div", null,
                        React.createElement(SelectBox, { options: childrenAges, customWidth: "100px", selectedValue: el.age, onValueChange: function (e) { return handleSelectChildAge(e, i); }, hasBorder: true, customFontsizeTitle: "18px" }),
                        React.createElement(CheckboxMF, { isMale: el.isMale, handleOnClick: function () {
                                handeSetChildSex(i);
                            } })));
                })))))));
};
