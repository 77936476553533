var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { Z_INDEX } from "src/constants";
var itemsWidth = "290px";
export var SubMenuStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nbackground-color: #fff;\nborder-radius: 14px;\n\nmargin-left: 20px;\nmargin-top: 20px;\n\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    height: max-content;\n    position: absolute;\n    z-index: ", ";\n    max-height: 80vh;\n    overflow-y: auto;\n}\n"], ["\nbackground-color: #fff;\nborder-radius: 14px;\n\nmargin-left: 20px;\nmargin-top: 20px;\n\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    height: max-content;\n    position: absolute;\n    z-index: ", ";\n    max-height: 80vh;\n    overflow-y: auto;\n}\n"])), Z_INDEX.turboMax);
export var SubMenuImagesBlock = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: baseline;\n  max-height: 200px;\n  overflow-x: auto;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: baseline;\n  max-height: 200px;\n  overflow-x: auto;\n  justify-content: space-between;\n"])));
var activeType = "\nimg {\n  background-color: #8dc646;\n  border-radius: 10px;\n}\n \n  svg {\n    fill: #8dc646;\n  }\n  & svg {\n    stroke: black;\n  }\n\n  nth-child(2) {\n    stroke: black;\n  }\n  rect:nth-of-type(1),\n  rect:nth-of-type(2),\n  rect:nth-of-type(3),\n  rect:nth-of-type(4),\n  rect:nth-of-type(5),\n  rect:nth-of-type(6),\n  rect:nth-of-type(7),\n  rect:nth-of-type(8),\n  rect:nth-of-type(9),\n  rect:nth-of-type(10),\n  rect:nth-of-type(11) {\n    stroke: #1f1f1f;\n  }\n\n  path,\n  circle,\n  line {\n    stroke: black;\n  }\n";
export var SubMenuImage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 80px;\n  margin-bottom: 12px;\n  // padding: 8px;\n  box-sizing: border-box;\n  text-align: center;\n  ", "\n\n  &: hover {\n    ", "\n  }\n"], ["\n  display: flex;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 80px;\n  margin-bottom: 12px;\n  // padding: 8px;\n  box-sizing: border-box;\n  text-align: center;\n  ", "\n\n  &: hover {\n    ", "\n  }\n"])), function (props) {
    return props.$isActive && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ", "\n    "])), activeType);
}, activeType);
export var SubMenuTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n"])));
export var SubMenuTitleName = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  paddlng-top: 12px;\n  padding-bottom: 12px;\n  // justify-content: center;\n  // align-items: center;\n"], ["\n  display: flex;\n  paddlng-top: 12px;\n  padding-bottom: 12px;\n  // justify-content: center;\n  // align-items: center;\n"])));
export var SubMenuInput = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  // justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  // justify-content: center;\n  align-items: center;\n"])));
export var SubMenuInputsBlock = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 12px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 12px;\n"])));
export var SubMenuCheckboxBlock = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-bottom: 12px;\n  align-items: center;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-bottom: 12px;\n  align-items: center;\n"])));
export var SubMenuButtonsBlock = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-bottom: 12px;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-bottom: 12px;\n"])));
export var SubMenuCustomButton = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 6px 12px;\n  background-color: #bde191;\n  border-radius: 12px;\n"], ["\n  padding: 6px 12px;\n  background-color: #bde191;\n  border-radius: 12px;\n"])));
export var SubMeniDropdow = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: ", ";\n  gap: 18px;\n  border-radius: 20px;\n  padding: 5px;\n  &:hover {\n    background-color: #e8e8e8;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  width: ", ";\n  gap: 18px;\n  border-radius: 20px;\n  padding: 5px;\n  &:hover {\n    background-color: #e8e8e8;\n  }\n"])), itemsWidth);
export var SubMeniDropdowImg = styled.img(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  width: 40px;\n  height: 40px;\n  object-fit: contain;\n"], ["\n  display: flex;\n  width: 40px;\n  height: 40px;\n  object-fit: contain;\n"])));
export var SubMenuArrow = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin-left: auto;\n"], ["\n  margin-left: auto;\n"])));
export var SubMenuBody = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: ", ";\n  margin-bottom: 12px;\n"], ["\n  width: ", ";\n  margin-bottom: 12px;\n"])), itemsWidth);
export var SubMenuImagesUrl = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  height: 80px;\n  width: 80px;\n  object-fit: contain;\n  margin-bottom: 12px;\n  padding: 4px;\n"], ["\n  height: 80px;\n  width: 80px;\n  object-fit: contain;\n  margin-bottom: 12px;\n  padding: 4px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
