export const collage = {
  wall: {
    id: 16,
    image:
      "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/wall2.jpg",
    width: 1000,
  },
  floor: {
    id: 15,
    image:
      "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/floor3.jpg",
    width: 5000,
    height: "25%",
  },
  furniture: [
    {
      x: 0,
      y: -6,
      id: 1,
      name: "Дверь №1",
      type: "decor",
      image:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/door.png",
      layer: "1",
      price: 45000,
      width: 1000,
      is_empty: 0,
      position: "1",
      description: "Дверь входная",
    },
    {
      id: 110000,
      type: "kreslo",
      alike: "401,420,421,422,400",
      image:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/front.png",
      layer: "1",
      price: 10000,
      width: 800,
      is_empty: 0,
      position: "1",
      image_left:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/left.png",
      description: "Кресло №401 Очень удобное",
      image_right:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/right.png",
      furniture_id: 401,
    },
    // {
    //   x: 0,
    //   y: 0,
    //   id: 2,
    //   name: "Стол №501",
    //   type: "stol",
    //   alike: "500,507,501,502,503",
    //   image: "https://dev.platformvim.org/images/data/501.png",
    //   layer: "1",
    //   price: 5000,
    //   width: 1200,
    //   is_empty: 0,
    //   position: "2",
    //   image_left: "https://dev.platformvim.org/images/data/501.png",
    //   description: "Стол №501 ровный",
    //   image_right: "https://dev.platformvim.org/images/data/501.png",
    //   furniture_id: 501,
    // },
    {
      id: 3,
      name: "Диван №101",
      type: "divan",
      alike: "100,101,102,103",
      image: "https://dev.platformvim.org/images/data/101.png",
      layer: "1",
      price: 19000,
      width: 2000,
      is_empty: 0,
      position: "3",
      image_left: "https://dev.platformvim.org/images/data/101.png",
      description: "Диван 101",
      image_right: "https://dev.platformvim.org/images/data/101.png",
      furniture_id: 101,
    },

    {
      x: 0,
      y: -60,
      id: 4,
      name: "Stul 603",
      type: "stul",
      alike: "600,602,603,604",
      image: "https://dev.platformvim.org/images/data/603.png",
      layer: "1",
      price: 5000,
      width: 600,
      is_empty: 0,
      position: "41",
      image_left: "https://dev.platformvim.org/images/data/603.png",
      description: "Stul 603",
      image_right: "https://dev.platformvim.org/images/data/603.png",
      furniture_id: 603,
    },
    {
      x: 0,
      y: 0,
      id: 98765,
      name: "Стол №507",
      type: "stol",
      alike: "500,507,501,502,503",
      image:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
      layer: "2",
      price: 5000,
      width: 1200,
      is_empty: 0,
      position: "3",
      image_left:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
      description: "Стол №507 ровный",
      image_right:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
      furniture_id: 507,
    },
    // {
    //   x: 0,
    //   y: -100,
    //   id: 7,
    //   name: "Стол №507",
    //   type: "stol",
    //   alike: "500,507,501,502,503",
    //   image:
    //     "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
    //   layer: "1",
    //   price: 5000,
    //   width: 1200,
    //   is_empty: 0,
    //   position: "9",
    //   image_left:
    //     "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
    //   description: "Стол №507 ровный",
    //   image_right:
    //     "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
    //   furniture_id: 507,
    // },
    // {
    //   x: 0,
    //   y: -100,
    //   id: 11,
    //   name: "Стол №507",
    //   type: "stol",
    //   alike: "500,507,501,502,503",
    //   image:
    //     "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
    //   layer: "1",
    //   price: 5000,
    //   width: 1200,
    //   is_empty: 0,
    //   position: "19",
    //   image_left:
    //     "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
    //   description: "Стол №507 ровный",
    //   image_right:
    //     "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
    //   furniture_id: 507,
    // },

    {
      id: 11456,
      name: "Шкаф №1001",
      type: "shkaf",
      alike: "1001,1002,1003,1000",
      image: "https://dev.platformvim.org/images/data/1001.png",
      layer: "1",
      price: 10000,
      width: 1500,
      is_empty: 0,
      position: "3",
      image_left: "https://dev.platformvim.org/images/data/1001.png",
      description: "Шкаф №1001 вместительный",
      image_right: "https://dev.platformvim.org/images/data/1001.png",
      furniture_id: 1001,
    },
  ],
  positions: {
    stol: {
      x: 0,
      y: -20,
    },
    divan: {
      x: 0,
      y: -30,
    },
    shkaf: {
      x: 0,
      y: -10,
    },
    kreslo: {
      x: 0,
      y: -30,
    },
  },
  alternatives: [
    {
      name: "Новое кресло №420",
      type: "kreslo",
      alike: "401,420,421,422,400",
      image: "https://dev.platformvim.org/images/data/420.png",
      price: 666,
      width: 800,
      is_empty: 0,
      image_left: "https://dev.platformvim.org/images/data/420.png",
      description: "420 кресло",
      image_right: "https://dev.platformvim.org/images/data/420.png",
      furniture_id: 420,
    },
    {
      name: "Супер кресло №421",
      type: "kreslo",
      alike: "401,420,421,422,400",
      image: "https://dev.platformvim.org/images/data/421.png",
      price: 666,
      width: 800,
      is_empty: 0,
      image_left: "https://dev.platformvim.org/images/data/421.png",
      description: "421 кресло",
      image_right: "https://dev.platformvim.org/images/data/421.png",
      furniture_id: 421,
    },
    {
      name: "Супер кресло №422",
      type: "kreslo",
      alike: "401,420,421,422,400",
      image: "https://dev.platformvim.org/images/data/422.png",
      price: 666,
      width: 800,
      is_empty: 0,
      image_left: "https://dev.platformvim.org/images/data/422.png",
      description: "422 кресло",
      image_right: "https://dev.platformvim.org/images/data/422.png",
      furniture_id: 422,
    },
    {
      x: 0,
      y: 0,
      name: "Стол №501",
      type: "stol",
      alike: "500,507,501,502,503",
      image: "https://dev.platformvim.org/images/data/501.png",
      layer: "2",
      price: 5000,
      width: 1200,
      is_empty: 0,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/501.png",
      description: "Стол №501 ровный",
      image_right: "https://dev.platformvim.org/images/data/501.png",
      furniture_id: 501,
    },
    {
      x: 0,
      y: 0,
      name: "Стол №502",
      type: "stol",
      alike: "507,501,502,503,500",
      image: "https://dev.platformvim.org/images/data/502.png",
      layer: "2",
      price: 5000,
      width: 1200,
      is_empty: 0,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/502.png",
      description: "Стол №502 ровный",
      image_right: "https://dev.platformvim.org/images/data/502.png",
      furniture_id: 502,
    },
    {
      x: 0,
      y: 0,
      name: "Стол №1",
      type: "stol",
      alike: "507,501,502,503,500",
      image: "https://dev.platformvim.org/images/data/503.png",
      layer: "2",
      price: 5000,
      width: 1200,
      is_empty: 0,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/503.png",
      description: "Стол №503",
      image_right: "https://dev.platformvim.org/images/data/503.png",
      furniture_id: 503,
    },
    {
      name: "Шкаф №1001",
      type: "shkaf",
      alike: "1001,1002,1003,1000",
      image: "https://dev.platformvim.org/images/data/1001.png",
      layer: "1",
      price: 10000,
      width: 1500,
      is_empty: 0,
      position: "3",
      image_left: "https://dev.platformvim.org/images/data/1001.png",
      description: "Шкаф №1001 вместительный",
      image_right: "https://dev.platformvim.org/images/data/1001.png",
      furniture_id: 1001,
    },
    {
      name: "Шкаф №1002",
      type: "shkaf",
      alike: "1001,1002,1003,1000",
      image: "https://dev.platformvim.org/images/data/1002.png",
      layer: "1",
      price: 10000,
      width: 1500,
      is_empty: 0,
      position: "3",
      image_left: "https://dev.platformvim.org/images/data/1002.png",
      description: "Шкаф №1002 вместительный",
      image_right: "https://dev.platformvim.org/images/data/1002.png",
      furniture_id: 1002,
    },
    {
      name: "Шкаф №1003",
      type: "shkaf",
      alike: "1001,1002,1003,1000",
      image: "https://dev.platformvim.org/images/data/1003.png",
      layer: "1",
      price: 10000,
      width: 1500,
      is_empty: 0,
      position: "3",
      image_left: "https://dev.platformvim.org/images/data/1003.png",
      description: "Шкаф №1003 вместительный",
      image_right: "https://dev.platformvim.org/images/data/1003.png",
      furniture_id: 1003,
    },
    {
      name: "Кресло №401",
      type: "kreslo",
      alike: "401,420,421,422,400",
      image:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/front.png",
      layer: "1",
      price: 10000,
      width: 800,
      is_empty: 0,
      position: "1",
      image_left:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/left.png",
      description: "Кресло №401 Очень удобное",
      image_right:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/right.png",
      furniture_id: 401,
    },
    {
      x: 0,
      y: -100,
      name: "Стол №507",
      type: "stol",
      alike: "500,507,501,502,503",
      image:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
      layer: "2",
      price: 5000,
      width: 1200,
      is_empty: 0,
      position: "7",
      image_left:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
      description: "Стол №507 ровный",
      image_right:
        "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
      furniture_id: 507,
    },
    {
      x: 0,
      y: 0,
      name: "Stul 603",
      type: "stul",
      alike: "600,602,603,604",
      image: "https://dev.platformvim.org/images/data/603.png",
      layer: "2",
      price: 5000,
      width: 600,
      is_empty: 0,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/603.png",
      description: "Stul 603",
      image_right: "https://dev.platformvim.org/images/data/603.png",
      furniture_id: 603,
    },
    {
      x: 0,
      y: 0,
      name: "Stul 604",
      type: "stul",
      alike: "600,602,603,604",
      image: "https://dev.platformvim.org/images/data/604.png",
      layer: "2",
      price: 5000,
      width: 600,
      is_empty: 0,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/604.png",
      description: "Stul 604",
      image_right: "https://dev.platformvim.org/images/data/604.png",
      furniture_id: 604,
    },
    {
      x: 0,
      y: 0,
      name: "Stul 602",
      type: "stul",
      alike: "600,602,603,604",
      image: "https://dev.platformvim.org/images/data/602.png",
      layer: "2",
      price: 5000,
      width: 600,
      is_empty: 0,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/602.png",
      description: "Stul 602",
      image_right: "https://dev.platformvim.org/images/data/602.png",
      furniture_id: 602,
    },
    {
      name: "Диван №101",
      type: "divan",
      alike: "100,101,102,103",
      image: "https://dev.platformvim.org/images/data/101.png",
      layer: "1",
      price: 19000,
      width: 2000,
      is_empty: 0,
      position: "2",
      image_left: "https://dev.platformvim.org/images/data/101.png",
      description: "Диван 101",
      image_right: "https://dev.platformvim.org/images/data/101.png",
      furniture_id: 101,
    },
    {
      name: "Диван №102",
      type: "divan",
      alike: "100,101,102,103",
      image: "https://dev.platformvim.org/images/data/102.png",
      layer: "1",
      price: 19000,
      width: 2000,
      is_empty: 0,
      position: "2",
      image_left: "https://dev.platformvim.org/images/data/102.png",
      description: "Диван 102",
      image_right: "https://dev.platformvim.org/images/data/102.png",
      furniture_id: 102,
    },
    {
      name: "Диван №103",
      type: "divan",
      alike: "100,101,102,103",
      image: "https://dev.platformvim.org/images/data/103.png",
      layer: "1",
      price: 19000,
      width: 2000,
      is_empty: 0,
      position: "2",
      image_left: "https://dev.platformvim.org/images/data/103.png",
      description: "Диван 103",
      image_right: "https://dev.platformvim.org/images/data/103.png",
      furniture_id: 103,
    },
    {
      x: 0,
      y: 0,
      name: "Стол пустой",
      type: "stol",
      alike: "500,507,501,502,503",
      image: "https://dev.platformvim.org/images/data/500.png",
      layer: "2",
      price: 0,
      width: 1200,
      is_empty: 1,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/500.png",
      description: "удалить",
      image_right: "https://dev.platformvim.org/images/data/500.png",
      furniture_id: 500,
    },
    {
      x: 0,
      y: -250,
      name: "удалить",
      type: "kreslo",
      alike: "400,420,421,422",
      image: "https://dev.platformvim.org/images/data/400.png",
      layer: "2",
      price: 0,
      width: 600,
      is_empty: 1,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/400.png",
      description: "удалить",
      image_right: "https://dev.platformvim.org/images/data/400.png",
      furniture_id: 400,
    },
    {
      x: 0,
      y: 0,
      name: "удалить",
      type: "stul",
      alike: "600,602,603,604",
      image: "https://dev.platformvim.org/images/data/400.png",
      layer: "2",
      price: 0,
      width: 600,
      is_empty: 1,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/400.png",
      description: "удалить",
      image_right: "https://dev.platformvim.org/images/data/400.png",
      furniture_id: 600,
    },
    {
      x: 0,
      y: 0,
      name: "удалить",
      type: "shkaf",
      alike: "1000,1001,1002,1003",
      image: "https://dev.platformvim.org/images/data/1000.png",
      layer: "2",
      price: 0,
      width: 1200,
      is_empty: 1,
      position: "7",
      image_left: "https://dev.platformvim.org/images/data/1000.png",
      description: "удалить",
      image_right: "https://dev.platformvim.org/images/data/1000.png",
      furniture_id: 1000,
    },
    {
      name: "удалить",
      type: "divan",
      alike: "100,101,102",
      image: "https://dev.platformvim.org/images/data/100.png",
      layer: "1",
      price: 19000,
      width: 2000,
      is_empty: 1,
      position: "2",
      image_left: "https://dev.platformvim.org/images/data/100.png",
      description: "удалить",
      image_right: "https://dev.platformvim.org/images/data/100.png",
      furniture_id: 100,
    },
  ],
};

//{
//   positions: {
//     kreslo: {
//       x: 0,
//       y: -30,
//     },
//     divan: {
//       x: 0,
//       y: -30,
//     },
//     stol: {
//       x: 0,
//       y: -250,
//     },
//     shkaf: {
//       x: 0,
//       y: -10,
//     },
//   },
//   wall: {
//     id: 16,
//     width: 1000,
//     image:
//       "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/wall2.jpg",
//   },
//   floor: {
//     id: 15,
//     width: 5000,
//     height: "25%",
//     image:
//       "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/floor3.jpg",
//   },
//   furniture: [
//     {
//       id: 16,
//       type: "kreslo",
//       name: "Кресло №1",
//       price: 10000,
//       layer: "1",
//       width: 800,
//       position: "1",
//       alike: "16,17",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/right.png",
//     },
//     {
//       id: 2,
//       type: "divan",
//       layer: "1",
//       name: "Диван №1",
//       price: 19000,
//       width: 2000,
//       alike: "9,10,11",
//       position: "2",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/divan/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/divan/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/divan/1/right.png",
//     },
//     {
//       id: 4,
//       type: "stol",
//       name: "Стол №1",
//       price: 19000,
//       layer: "2",
//       x: 0,
//       y: -250,
//       width: 1200,
//       position: "4",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
//     },
//     {
//       id: 1,
//       type: "kreslo",
//       layer: "1",
//       name: "Кресло №1",
//       price: 10000,
//       width: 800,
//       alike: "7,8",
//       position: "9",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/kreslo/1/right.png",
//     },
//     {
//       id: 3,
//       type: "shkaf",
//       layer: "1",
//       name: "Шкаф №1",
//       price: 10000,
//       width: 1500,
//       position: "3",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/shkaf1.png",
//     },
//     {
//       id: 6,
//       type: "stul",
//       layer: "1",
//       name: "Стул №1",
//       price: 10000,
//       x: 0,
//       y: -50,
//       width: 400,
//       position: "6",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stul/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stul/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stul/1/right.png",
//     },
//     {
//       id: 5,
//       type: "stul",
//       layer: "1",
//       name: "Стул №1",
//       price: 10000,
//       x: 0,
//       y: -50,
//       width: 400,
//       position: "5",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stul/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stul/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stul/1/right.png",
//     },
//     {
//       id: 7,
//       type: "stol",
//       layer: "2",
//       name: "Стол №1",
//       price: 5000,
//       x: -220,
//       y: -250,
//       width: 1200,
//       position: "7",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/front.png",
//       image_left:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/left.png",
//       image_right:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/stol/1/right.png",
//     },
//     {
//       id: 8,
//       type: "decor",
//       layer: "1",
//       name: "Дверь №1",
//       price: 45000,
//       x: 0,
//       y: -6,
//       width: 1000,
//       position: "8",
//       image:
//         "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/door.png",
//     },
//   ],
// };
