var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { COLLAGE_WIDTH, Z_INDEX } from "src/constants";
// const tablePadding = "12px";
// const rightBorder = "border-right: solid 1px;";
// const itemsWidth = "calc(100% / 6)";
// const heightItem = "50px";
// const greenColor = "rgba(141, 198, 70, 0.2)";
var imageHeight = 220;
export var RefreshSvgStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: ", "px;\n  left: -15px;\n  z-index: ", ";\n"], ["\n  position: absolute;\n  top: ", "px;\n  left: -15px;\n  z-index: ", ";\n"])), imageHeight - 20, Z_INDEX.max);
export var EstimatesStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  //position: relative;\n  // right: 20px;\n  top: 130px; //84px;;\n  /*          */\n  position: absolute;\n  z-index: 2;\n  background-color: #ffffff;\n  right: 0;\n  /*          */\n  box-sizing: border-box;\n  width: ", ";\n  margin-left: ", ";\n\n  // right: 20px;\n  height: ", "px;\n  overflow-x: auto;\n"], ["\n  //position: relative;\n  // right: 20px;\n  top: 130px; //84px;;\n  /*          */\n  position: absolute;\n  z-index: 2;\n  background-color: #ffffff;\n  right: 0;\n  /*          */\n  box-sizing: border-box;\n  width: ", ";\n  margin-left: ", ";\n\n  // right: 20px;\n  height: ", "px;\n  overflow-x: auto;\n"])), function (_a) {
    var $isCollage = _a.$isCollage;
    return ($isCollage ? "".concat(COLLAGE_WIDTH, "px") : "100%");
}, function (_a) {
    var $isCollage = _a.$isCollage;
    return ($isCollage ? "20px" : "160px");
}, document.documentElement.clientHeight - 144);
export var Selector = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  z-index: 2000;\n\n  width: auto;\n  height: auto;\n  border-radius: 10px;\n\n  background-color: #ffffff;\n"], ["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  z-index: 2000;\n\n  width: auto;\n  height: auto;\n  border-radius: 10px;\n\n  background-color: #ffffff;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
