var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var CardsBlock = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    overflow: auto;\n    scrollbar-width: none;\n    height: 60vh;\n    box-sizing: border-box;\n    ::-webkit-scrollbar {\n      display: none;\n    }\n  }\n"], ["\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    overflow: auto;\n    scrollbar-width: none;\n    height: 60vh;\n    box-sizing: border-box;\n    ::-webkit-scrollbar {\n      display: none;\n    }\n  }\n"])));
export var ImagesBlock_horisonal = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 6px;\n  & > img {\n    width: 60%;\n    /* width: 225px;\n    height: 196px; */\n  }\n  margin-bottom: 13px;\n"], ["\n  display: flex;\n  gap: 6px;\n  & > img {\n    width: 60%;\n    /* width: 225px;\n    height: 196px; */\n  }\n  margin-bottom: 13px;\n"])));
export var ImagesBlock_verical = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 38%;\n  gap: 7px;\n  & > img {\n    width: 100%;\n    /* width: 141px;\n    height: 95px; */\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 38%;\n  gap: 7px;\n  & > img {\n    width: 100%;\n    /* width: 141px;\n    height: 95px; */\n  }\n"])));
export var ButtonBlock = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  height: min-content;\n"], ["\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  height: min-content;\n"])));
export var StyledImg = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 33.33px;\n  width: auto;\n  /* transition: background-color 0.2s; */\n  cursor: pointer;\n  /* &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  } */\n"], ["\n  height: 33.33px;\n  width: auto;\n  /* transition: background-color 0.2s; */\n  cursor: pointer;\n  /* &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  } */\n"])));
export var CardName = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 20px;\n  line-height: 27.32px;\n"], ["\n  font-size: 20px;\n  line-height: 27.32px;\n"])));
export var Container = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  scrollbar-width: none;\n"], ["\n  scrollbar-width: none;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
