import {
  SET_COLLAGE_DATA,
  CHANGE_FURNITURE,
  SET_FURNITURE_POSITION,
} from "../constants";

export function setCollageData(data) {
  return {
    type: SET_COLLAGE_DATA,
    data,
  };
}

export function changeFurniture(newFurniture) {
  return {
    type: CHANGE_FURNITURE,
    newFurniture,
  };
}

export function setFurniturePosition(data) {
  return {
    type: SET_FURNITURE_POSITION,
    data,
  };
}
