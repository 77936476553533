import React from "react";
import { PriceSegment, StyledButton } from "./styled";
import { BlockTitle } from "../../styled";
import { CommonButton } from "src/ui-kit/button";
import { TwoThumbsRangeBlock } from "src/components/two-tumbs-range-block/two-tumbs-range-block";
export var MoneyInfo = function (_a) {
    var priceSegment = _a.priceSegment, setPriceSegment = _a.setPriceSegment, width = _a.width;
    return (React.createElement(React.Fragment, null,
        React.createElement(BlockTitle, null, " \u0426\u0435\u043D\u043E\u0432\u043E\u0439 \u0441\u0435\u0433\u043C\u0435\u043D\u0442:"),
        React.createElement(PriceSegment, null,
            React.createElement(CommonButton, { onClick: function () {
                    setPriceSegment(1);
                }, isFullWidth: true }, React.createElement(StyledButton, { "$isSelect": priceSegment === 1 }, "\u042D\u043A\u043E\u043D\u043E\u043C")),
            React.createElement(CommonButton, { onClick: function () {
                    setPriceSegment(2);
                }, isFullWidth: true }, React.createElement(StyledButton, { "$isSelect": priceSegment === 2 }, "\u041A\u043E\u043C\u0444\u043E\u0440\u0442")),
            React.createElement(CommonButton, { onClick: function () {
                    setPriceSegment(3);
                }, isFullWidth: true }, React.createElement(StyledButton, { "$isSelect": priceSegment === 3 }, "\u041F\u0440\u0435\u043C\u0438\u0443\u043C"))),
        React.createElement(BlockTitle, null, " \u041F\u0440\u0435\u0434\u043F\u043E\u043B\u0430\u0433\u0430\u0435\u043C\u044B\u0439 \u0431\u044E\u0434\u0436\u0435\u0442:"),
        React.createElement(TwoThumbsRangeBlock, { step: 1000, min: 0, max: 1000000 })));
};
