var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useCallback } from "react";
import { ThirdIconGroupContainer, IconWrapper, GreenBar, FirstGreenIcon, Text2D, SecondIconGroupContainer, MainHeaderContainer, FirstGroupIcon, Icon, MainHeaderIconStyled, IconGroupContainer, } from "./styled";
import { IconThree } from "./icon";
import { CommonButton } from "src/ui-kit/button";
import * as constants from "src/constants";
import Project from "src/class/project";
import { MdUndo } from "react-icons/md";
import { getProjectInfo } from "src/api/fetch/get-project-info";
export var MainHeaderIcon = memo(function MainHeaderIcon(_a) {
    var handlePlusZoomScene = _a.handlePlusZoomScene, handleMinusZoomScene = _a.handleMinusZoomScene, handleNextActionButton = _a.handleNextActionButton, handleBackActionButton = _a.handleBackActionButton, handleOpenEvaluates = _a.handleOpenEvaluates, handleOpenScene = _a.handleOpenScene, handleOpenQuestionary = _a.handleOpenQuestionary, handleOpenCollageAndEstimates = _a.handleOpenCollageAndEstimates, handleOpenCollage = _a.handleOpenCollage, onLoadEstimates = _a.onLoadEstimates, handlePrint = _a.handlePrint, isOpenCollageAndEstimates = _a.isOpenCollageAndEstimates, isOpenCollage = _a.isOpenCollage, isOpenScene = _a.isOpenScene, isOpenEvaluates = _a.isOpenEvaluates, state = _a.state, viewer3DActions = _a.viewer3DActions, viewer2DActions = _a.viewer2DActions, projectActions = _a.projectActions, handleOpen3D = _a.handleOpen3D;
    var mode = state.get("mode");
    var _state = Project.unselectAll(state).updatedState;
    var stateScene = _state.get("scene").toJS();
    var layer = stateScene.layers["layer-1"];
    var isAllAreasHaveType = Object.keys(layer.areas).length > 0 &&
        Object.keys(layer.areas)
            .map(function (el) { return layer.areas[el].properties.areaType; })
            .filter(function (a) { return a === ""; }).length === 0;
    var isDisplay3D = mode === constants.MODE_3D_VIEW;
    var isDisplay2d = (mode === constants.MODE_IDLE ||
        mode === constants.MODE_2D_ZOOM_IN ||
        mode === constants.MODE_2D_ZOOM_OUT ||
        mode === constants.MODE_2D_PAN ||
        mode === constants.MODE_WAITING_DRAWING_LINE ||
        mode === constants.MODE_DRAGGING_LINE ||
        mode === constants.MODE_DRAGGING_VERTEX ||
        mode === constants.MODE_DRAGGING_ITEM ||
        mode === constants.MODE_DRAWING_LINE ||
        mode === constants.MODE_DRAWING_HOLE ||
        mode === constants.MODE_DRAWING_ITEM ||
        mode === constants.MODE_DRAGGING_HOLE ||
        mode === constants.MODE_ROTATING_ITEM) &&
        !isOpenCollage &&
        !isOpenEvaluates &&
        !isOpenCollageAndEstimates;
    var handleMockFn = useCallback(function () { }, []);
    var onOpenQuestionary = useCallback(function () {
        handleOpenQuestionary();
    }, [handleOpenQuestionary]);
    var onOpenEstimates = useCallback(function () {
        handleOpenEvaluates();
        onLoadEstimates();
    }, [handleOpenEvaluates, onLoadEstimates]);
    var onOpenCollageAndEstimates = useCallback(function () {
        handleOpenCollageAndEstimates();
        onLoadEstimates();
    }, [handleOpenCollageAndEstimates, onLoadEstimates]);
    var onOpenCollage = useCallback(function () {
        handleOpenCollage();
    }, [handleOpenCollage]);
    var onPressPrint = function () {
        onLoadEstimates();
        handleOpenScene();
        handlePrint();
    };
    var onOpen3D = function () {
        handleOpen3D();
        // console.log(projectActions);
        // console.log(viewer3DActions);
        viewer3DActions.selectTool3DView();
    };
    var handleOpen2D = function () {
        handleOpenScene();
        // viewer2DActions.selectTool2DView()
        projectActions.setMode(constants.MODE_IDLE);
        handleOpenScene();
    };
    var handleBack = function () {
        projectActions.undo();
    };
    // if (isDisplay3D) {
    //   return null
    // }
    var steep = 0.1;
    var onPlusZoomScene = function () {
        var oldScale = state.viewer2D.toJS();
        var newScale = __assign(__assign({}, oldScale), { d: oldScale.d + steep, a: oldScale.a + steep });
        projectActions.updateZoomScale(newScale.a);
        viewer2DActions.updateCameraView(newScale);
    };
    var onMinusZoomScene = function () {
        var oldScale = state.viewer2D.toJS();
        var newScale = __assign(__assign({}, oldScale), { d: oldScale.d - steep, a: oldScale.a - steep });
        projectActions.updateZoomScale(newScale.a);
        viewer2DActions.updateCameraView(newScale);
        // viewer2DActions.selectToolZoomOut()
        // // projectActions.updateZoomScale(-299)
    };
    var layoutId = getProjectInfo().layoutId;
    var isDisableAutoGenerate = isDisplay3D || !layoutId;
    var isDisabled3D = !layoutId;
    return (React.createElement(MainHeaderIconStyled, null,
        !isDisplay3D && (React.createElement(IconGroupContainer, null,
            React.createElement(CommonButton, { onClick: handleBack },
                React.createElement(MdUndo, { size: 24 })),
            React.createElement(CommonButton, { onClick: onPlusZoomScene },
                React.createElement(FirstGroupIcon, { src: require("src/assets/img/+.png"), alt: "Plus Icon" })),
            React.createElement(CommonButton, { onClick: onMinusZoomScene },
                React.createElement(FirstGroupIcon, { src: require("src/assets/img/-.png"), alt: "Minus Icon" })))),
        React.createElement(MainHeaderContainer, null,
            React.createElement(SecondIconGroupContainer, null,
                React.createElement(CommonButton, { onClick: handleOpen2D },
                    React.createElement(IconWrapper, { isSelected: isDisplay2d },
                        React.createElement(FirstGreenIcon, null,
                            React.createElement(Text2D, null, "2D")))),
                React.createElement(CommonButton, { onClick: onOpen3D, isDisabled: isDisabled3D },
                    React.createElement(IconWrapper, { isSelected: isDisplay3D },
                        React.createElement(FirstGreenIcon, null,
                            React.createElement(Text2D, null, "3D")))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisableAutoGenerate || !isAllAreasHaveType, onClick: onOpenQuestionary },
                    React.createElement(IconWrapper, null,
                        React.createElement(IconThree, null))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D || !isAllAreasHaveType, onClick: onOpenEstimates },
                    React.createElement(IconWrapper, { isSelected: isOpenEvaluates },
                        React.createElement(Icon, { src: require("src/assets/img/notes.png"), alt: "Notes" }))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D || !isAllAreasHaveType, onClick: onOpenCollage },
                    React.createElement(IconWrapper, { isSelected: isOpenCollage },
                        React.createElement(Icon, { src: require("src/assets/img/groupFurniture.png"), alt: "Furniture" }))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D || !isAllAreasHaveType, onClick: onOpenCollageAndEstimates },
                    React.createElement(IconWrapper, { isSelected: isOpenCollageAndEstimates },
                        React.createElement(Icon, { src: require("src/assets/img/window.png"), alt: "Window" })))),
            React.createElement(ThirdIconGroupContainer, null,
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: onPressPrint },
                    React.createElement(IconWrapper, { isSelected: false },
                        React.createElement(Icon, { src: require("src/assets/img/print.png"), alt: "Print" }))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: handleMockFn },
                    React.createElement(IconWrapper, null,
                        React.createElement(Icon, { src: require("src/assets/img/send.png"), alt: "Send" })))))));
});
