var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useState } from "react";
import { Seq } from "immutable";
import ElementEditor from "src/components/sidebar/panel-element-editor/element-editor";
var STYLE = {
    backgroundColor: "#fff",
    width: "400px",
    // height: "420px",
    position: "absolute",
    borderRadius: "10px",
    boxSizing: "border-box",
    bottom: "100px",
    zIndex: 99,
    padding: "20px",
    right: "10px",
};
export var EditElement = memo(function EditWall(_a) {
    var state = _a.state, catalog = _a.catalog;
    var _b = __read(useState(true), 2), isOpen = _b[0], setIsOpen = _b[1];
    var handleOnClose = function () {
        setIsOpen(false);
    };
    // setIsOpen(true)
    // useEffect(() => {
    //   setIsOpen(true)
    //   // return () => {
    //   //   setIsOpen(true)
    //   // }
    // }, [state])
    // console.log(catalog)
    // const selectedLayerName = state.scene.selectedLayer
    // const layers = state.scene.layers
    // const selectedLayer =  layers.get(selectedLayerName);
    // console.log(selectedLayer.lines.valueSeq())
    // return <div>zalupa</div>
    var scene = state.scene, mode = state.mode;
    // translator.t('Properties: [{0}] {1}', element.type, element.id)
    var componentRenderer = function (element, layer) {
        return isOpen ? (React.createElement("div", { key: element.id, style: STYLE },
            React.createElement(ElementEditor, { element: element, layer: layer, state: state, onClose: handleOnClose }))) : null;
    };
    // </Panel>;
    var layerRenderer = function (layer) {
        return Seq()
            .concat(layer.lines, layer.holes, layer.areas, layer.items)
            .filter(function (element) { return element.selected; })
            .map(function (element) { return componentRenderer(element, layer); })
            .valueSeq();
    };
    return React.createElement("div", null, scene.layers.valueSeq().map(layerRenderer));
});
