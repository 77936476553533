import { fetchPostRequests } from "src/api/fetch/post";
import { recognitionUrl } from "src/api/urls/recorgnition";
export var recognitionRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data;
    fetchPostRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: recognitionUrl,
        data: data,
        isFormData: true,
    });
};
