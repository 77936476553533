var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Z_INDEX } from "src/constants";
export var NotificationWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  z-index: ", ";\n"], ["\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  z-index: ", ";\n"])), Z_INDEX.megaTurboMax);
export var NotificationItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px solid\n    ", ";\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  margin-bottom: 10px;\n  color: ", ";\n"], ["\n  background-color: ", ";\n  border: 1px solid\n    ", ";\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  margin-bottom: 10px;\n  color: ", ";\n"])), function (_a) {
    var $isError = _a.$isError, $isSuccess = _a.$isSuccess;
    return $isError ? "#FFCDD2" : $isSuccess ? "#C8E6C9" : "#fff";
}, function (_a) {
    var $isError = _a.$isError, $isSuccess = _a.$isSuccess;
    return $isError ? "#F44336" : $isSuccess ? "#4CAF50" : "#ccc";
}, function (_a) {
    var $isError = _a.$isError, $isSuccess = _a.$isSuccess;
    return $isError ? "#D32F2F" : $isSuccess ? "#388E3C" : "#000";
});
export var CloseButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #ff6347;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  padding: 5px 10px;\n  cursor: pointer;\n"], ["\n  background-color: #ff6347;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  padding: 5px 10px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
