var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from "react";
import { CardsBlock, ImagesBlock_horisonal, ImagesBlock_verical, ButtonBlock, StyledImg, CardName, 
// Tabs,
// Tab,
// TabsConainer,
Container, } from "./styled";
import { BlockTitle } from "../../styled";
import Card from "src/ui-kit/card/card";
import { CommonButton } from "src/ui-kit/button";
import { ChangeRoomMenu } from "../../changeRoomMenu/changeRoomMenu";
var areas = {
    KITCHEN: "Кухня",
    LIVING_ROOM: "Гостиная",
    BATHROOM: "Ванна",
    BEDROOM: "Спальня",
    CHILDRENS_ROOM: "Детская комната",
    STUDY_ROOM: "Кабинет",
    TOILET: "Туалет",
};
export var RoomStyleInfo = function (_a) {
    var roomStyle = _a.roomStyle, setRoomStyle = _a.setRoomStyle, roomTab = _a.roomTab, setRoomTab = _a.setRoomTab, width = _a.width, rooms = _a.rooms, roomsData = _a.roomsData, setRoomsData = _a.setRoomsData;
    var cardWidth = useMemo(function () { return (width > 480 ? "20%" : "45%"); }, [width]);
    var handleChangeRoomMenuTabClick = function (index) {
        setRoomsData(function (p) {
            return p.map(function (el, i) {
                if (i === index) {
                    return __assign(__assign({}, el), { isCurrent: true });
                }
                else {
                    return __assign(__assign({}, el), { isCurrent: false });
                }
            });
        });
    };
    var handleSetRoomStyle = function (style) {
        setRoomsData(function (p) {
            return p.map(function (el, i) {
                if (el.isCurrent) {
                    console.log(p);
                    console.log(style);
                    return __assign(__assign({}, el), { style: style });
                }
                else {
                    return __assign({}, el);
                }
            });
        });
    };
    return (React.createElement(Container, null,
        React.createElement(BlockTitle, null, "\u0421\u0442\u0438\u043B\u044C \u043A\u043E\u043C\u043D\u0430\u0442\u044B:"),
        React.createElement(ChangeRoomMenu, { roomsData: roomsData, setRoomTab: handleChangeRoomMenuTabClick, arr: rooms }),
        React.createElement(CardsBlock, null,
            React.createElement(Card, { isActive: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "MINIMALIST", width: cardWidth },
                React.createElement(ImagesBlock_horisonal, null,
                    React.createElement("img", { src: require("src/assets/img/room-styles/minimalizm_1.png"), alt: "room" }),
                    React.createElement(ImagesBlock_verical, null,
                        React.createElement("img", { src: require("src/assets/img/room-styles/minimalizm_2.png") }),
                        React.createElement("img", { src: require("src/assets/img/room-styles/minimalizm_3.png") }))),
                React.createElement(ButtonBlock, null,
                    React.createElement(CommonButton, { onClick: function () {
                            handleSetRoomStyle("MINIMALIST");
                        } },
                        React.createElement(StyledImg, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].style ===
                                "MINIMALIST"
                                ? require("src/assets/img/round-check-on.png")
                                : require("src/assets/img/round-check-off.png"), alt: "" })),
                    React.createElement(CardName, null, "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u0438\u0437\u043C"))),
            React.createElement(Card, { isActive: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "CLASSICAL", width: cardWidth },
                React.createElement(ImagesBlock_horisonal, null,
                    React.createElement("img", { src: require("src/assets/img/room-styles/klassik_1.png"), alt: "room" }),
                    React.createElement(ImagesBlock_verical, null,
                        React.createElement("img", { src: require("src/assets/img/room-styles/klassik_2.png") }),
                        React.createElement("img", { src: require("src/assets/img/room-styles/klassik_3.png") }))),
                React.createElement(ButtonBlock, null,
                    React.createElement(CommonButton, { onClick: function () {
                            handleSetRoomStyle("CLASSICAL");
                        } },
                        React.createElement(StyledImg, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].style ===
                                "CLASSICAL"
                                ? require("src/assets/img/round-check-on.png")
                                : require("src/assets/img/round-check-off.png"), alt: "" })),
                    React.createElement(CardName, null, "\u041A\u043B\u0430\u0441\u0441\u0438\u043A\u0430"))),
            React.createElement(Card, { isActive: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "ECO_FRIENDLY", width: cardWidth },
                React.createElement(ImagesBlock_horisonal, null,
                    React.createElement("img", { src: require("src/assets/img/room-styles/eko_1.png"), alt: "room" }),
                    React.createElement(ImagesBlock_verical, null,
                        React.createElement("img", { src: require("src/assets/img/room-styles/eko_2.png") }),
                        React.createElement("img", { src: require("src/assets/img/room-styles/eko_3.png") }))),
                React.createElement(ButtonBlock, null,
                    React.createElement(CommonButton, { onClick: function () {
                            handleSetRoomStyle("ECO_FRIENDLY");
                        } },
                        React.createElement(StyledImg, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].style ===
                                "ECO_FRIENDLY"
                                ? require("src/assets/img/round-check-on.png")
                                : require("src/assets/img/round-check-off.png"), alt: "" })),
                    React.createElement(CardName, null, "\u042D\u043A\u043E"))),
            React.createElement(Card, { isActive: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "INDUSTRIAL", width: cardWidth },
                React.createElement(ImagesBlock_horisonal, null,
                    React.createElement("img", { src: require("src/assets/img/room-styles/loft_1.png"), alt: "room" }),
                    React.createElement(ImagesBlock_verical, null,
                        React.createElement("img", { src: require("src/assets/img/room-styles/loft_2.png") }),
                        React.createElement("img", { src: require("src/assets/img/room-styles/loft_3.png") }))),
                React.createElement(ButtonBlock, null,
                    React.createElement(CommonButton, { onClick: function () {
                            handleSetRoomStyle("INDUSTRIAL");
                        } },
                        React.createElement(StyledImg, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].style ===
                                "INDUSTRIAL"
                                ? require("src/assets/img/round-check-on.png")
                                : require("src/assets/img/round-check-off.png"), alt: "" })),
                    React.createElement(CardName, null, "\u041B\u043E\u0444\u0442"))),
            React.createElement(Card, { isActive: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "SCANDINAVIAN", width: cardWidth },
                React.createElement(ImagesBlock_horisonal, null,
                    React.createElement("img", { src: require("src/assets/img/room-styles/skandinavian_1.png"), alt: "room" }),
                    React.createElement(ImagesBlock_verical, null,
                        React.createElement("img", { src: require("src/assets/img/room-styles/skandinavian_2.png") }),
                        React.createElement("img", { src: require("src/assets/img/room-styles/skandinavian_3.png") }))),
                React.createElement(ButtonBlock, null,
                    React.createElement(CommonButton, { onClick: function () {
                            handleSetRoomStyle("SCANDINAVIAN");
                        } },
                        React.createElement(StyledImg, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].style ===
                                "SCANDINAVIAN"
                                ? require("src/assets/img/round-check-on.png")
                                : require("src/assets/img/round-check-off.png"), alt: "" })),
                    React.createElement(CardName, null, "\u0421\u043A\u0430\u043D\u0434\u0438\u043D\u0430\u0432\u0441\u043A\u0438\u0439"))),
            React.createElement(Card, { isActive: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "ART_DECO", width: cardWidth },
                React.createElement(ImagesBlock_horisonal, null,
                    React.createElement("img", { src: require("src/assets/img/room-styles/ar-deko_1.png"), alt: "room" }),
                    React.createElement(ImagesBlock_verical, null,
                        React.createElement("img", { src: require("src/assets/img/room-styles/ar-deko_2.png") }),
                        React.createElement("img", { src: require("src/assets/img/room-styles/ar-deko_3.png") }))),
                React.createElement(ButtonBlock, null,
                    React.createElement(CommonButton, { onClick: function () {
                            handleSetRoomStyle("ART_DECO");
                        } },
                        React.createElement(StyledImg, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].style === "ART_DECO"
                                ? require("src/assets/img/round-check-on.png")
                                : require("src/assets/img/round-check-off.png"), alt: "" })),
                    React.createElement(CardName, null, "\u0410\u0440-\u0434\u0435\u043A\u043E"))))));
};
