var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import { getProjectsRequest } from "src/api/requests/get-projects";
import { MainMenu } from "../mainMenu";
import { LoadInfo } from "../load-info";
import { TopMenu } from "../topMenu";
import { postRoomsRequest } from "src/api/requests/post-rooms";
import { Project } from "src/class/export";
import { postGenerateAutoRequest } from "src/api/requests/generage-auto";
import { MainHeaderIcon } from "../mainMenu/_components/mainHeaderIcon";
import { generateFurniture } from "src/utils/create-catalog-element/generate-furniture";
import { generateDoor } from "src/utils/create-catalog-element/generate-door";
import { generateWindow } from "src/utils/create-catalog-element/generate-window";
import { postAddProjectRequest } from "src/api/requests/post-add-project";
import { getUsersRequest } from "src/api/requests/get-users";
import { postAddUserRequest } from "src/api/requests/post-user";
import { getProjectByIdRequest } from "src/api/requests/get-project-by-id";
import { updateProjectByIdRequest } from "src/api/requests/update-project-by-id";
import { getLayoutsRequest } from "src/api/requests/get-layouts";
import { postAddLayoutsRequest } from "src/api/requests/post-layout";
import { localStorageWorker } from "src/utils/local-storage-worker";
import { LOCAL_STRORAGE_KEYS } from "src/constants";
import { ModalQuiz } from "../modal-quiz";
import { Questionary } from "src/components/questionary/questionary";
import { enumFurnitureTypes } from "./_utils/enum-furniture-types";
import { recognitionRequest } from "src/api/requests/recognition";
import { Notifications } from "src/components/notifications";
export var Meta = memo(function Meta(props) {
    var projectActions = props.projectActions, catalog = props.catalog, isLoadingMeta = props.isLoadingMeta, setIsPrinting = props.setIsPrinting, changingFurnitureId = props.changingFurnitureId, setChangingFurnitureId = props.setChangingFurnitureId, state = props.state, viewer3DActions = props.viewer3DActions, viewer2DActions = props.viewer2DActions, handleLoadEstimates = props.handleLoadEstimates, linesActions = props.linesActions, itemsActions = props.itemsActions, holesActions = props.holesActions, collageDataForSave = props.collageDataForSave;
    // console.log(props)
    // console.log("changingFurnitureId");
    // console.log(changingFurnitureId);
    // console.log(props)
    var _a = __read(useState(null), 2), commonRequestNotification = _a[0], setCommonRequestNotification = _a[1];
    var _b = __read(useState(false), 2), isOpenRecorgnition = _b[0], setIsOpenRecorgnition = _b[1];
    var _c = __read(useState([]), 2), allProjects = _c[0], setAllProjects = _c[1];
    var _d = __read(useState(false), 2), isLoadingProjects = _d[0], setIsLoadingProjects = _d[1];
    var _e = __read(useState(false), 2), isLoadingRooms = _e[0], setIsLoadingRooms = _e[1];
    var _f = __read(useState(false), 2), isLoadingSaveRooms = _f[0], setIsLoadingSaveRooms = _f[1];
    var _g = __read(useState(false), 2), isLoadingCollage = _g[0], setIsLoadingCollage = _g[1];
    var _h = __read(useState(false), 2), isLoadingFurnitures = _h[0], setIsLoadingFurnitures = _h[1];
    var _j = __read(useState(false), 2), isLoadingRoomTypes = _j[0], setIsLoadingRoomTypes = _j[1];
    var _k = __read(useState(false), 2), isLoadingShards = _k[0], setIsLoadingShards = _k[1];
    var _l = __read(useState([]), 2), windows = _l[0], setWindows = _l[1];
    var _m = __read(useState([]), 2), doors = _m[0], setDoors = _m[1];
    var _o = __read(useState([]), 2), furnitures = _o[0], setFurnitures = _o[1];
    var _p = __read(useState([]), 2), roomTypes = _p[0], setRoomTypes = _p[1];
    var _q = __read(useState(false), 2), isOpenQuiz = _q[0], setIsOpenQuiz = _q[1];
    var _r = __read(useState(false), 2), isLoadingPlanRecognition = _r[0], setIsLoadingPlanRecognition = _r[1];
    // console.log(catalog)
    var hadnleOpenRecorgnition = function () {
        setIsOpenRecorgnition(true);
    };
    var hadnleCloseRecorgnition = function () {
        setIsOpenRecorgnition(false);
    };
    var handleStartLoadingPlanRecognition = function () {
        setIsLoadingPlanRecognition(true);
    };
    var handleStopLoadingPlanRecognition = function () {
        setIsLoadingPlanRecognition(false);
    };
    var _s = __read(useState(false), 2), isLoadingAdminAction = _s[0], setIsLoadingAdminAction = _s[1];
    var _t = __read(useState([]), 2), allLayouts = _t[0], setAllLayouts = _t[1];
    var _u = __read(useState(false), 2), isLoadingLayouts = _u[0], setIsLoadingLayouts = _u[1];
    var _v = __read(useState(false), 2), hardClear = _v[0], setHardClear = _v[1];
    var handleFalseHardClear = function () {
        setHardClear(false);
    };
    var handleCloseQuiz = function () {
        setIsOpenQuiz(false);
    };
    var handleOpenQuiz = function () {
        setIsOpenQuiz(true);
    };
    // const [, setHasInProgressGenerate] = useState(false);
    useEffect(function () {
        var hasInProgress = allLayouts.find(function (layout) {
            return layout.data.is_ml_generated === false;
        });
        if (!hasInProgress) {
            setClearHasInProgressGenerate();
        }
    }, [allLayouts]);
    var setClearHasInProgressGenerate = function () {
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.HAS_IN_GENERATED,
            value: "0",
        });
    };
    var pollingTimeoutRef = useRef(null); // Реф для хранения таймера
    var loongPoolingLayout = function () {
        // Запрашиваем данные
        getLayoutsRequest({
            onSuccess: handleLoadLayouts,
        });
    };
    var startPolling = function () {
        pollingTimeoutRef.current = setTimeout(function () {
            loongPoolingLayout(); // Выполняем запрос
            var hasInProgressGenerate = localStorageWorker.get({
                key: LOCAL_STRORAGE_KEYS.HAS_IN_GENERATED,
            }) === "1";
            // Если есть незавершенные процессы, продолжаем запросы
            if (hasInProgressGenerate) {
                startPolling(); // Рекурсивно запускаем polling снова
            }
        }, 5000); // 5 секунд задержка
    };
    // Очищаем таймер, если больше не нужно продолжать
    var stopPolling = function () {
        if (pollingTimeoutRef.current) {
            // setCommonRequestNotification({
            //   isSuccess: true,
            //   title: "Успешно"
            // })
            clearTimeout(pollingTimeoutRef.current);
            pollingTimeoutRef.current = null;
        }
    };
    useEffect(function () {
        var hasInProgressGenerate = localStorageWorker.get({
            key: LOCAL_STRORAGE_KEYS.HAS_IN_GENERATED,
        }) === "1";
        if (hasInProgressGenerate) {
            loongPoolingLayout(); // Выполняем первый запрос
            startPolling(); // Запускаем последующие запросы с задержкой
        }
        else {
            stopPolling(); // Очищаем таймер, если больше не нужно
        }
        // Очищаем таймер при размонтировании компонента
        return function () {
            // if(!hasInProgressGenerate) {
            //   stopPolling();
            // }
        };
    }, []);
    var handleLoadLayouts = function (layouts) {
        if (layouts) {
            setAllLayouts(layouts);
            var hasInProgress = layouts.find(function (layout) {
                return layout.data.is_ml_generated === false;
            });
            if (hasInProgress) {
                startPolling();
            }
            else {
                setClearHasInProgressGenerate();
            }
            layouts.forEach(function (layout) {
                if (!layout.data.items) {
                    return;
                }
                Object.values(layout.data.items).forEach(function (item) {
                    // console.log(item.catalog)
                    generateFurniture({ catalog: catalog, furniture: [item.catalog] });
                });
                Object.values(layout.data.holes).forEach(function (hole) {
                    if (!hole.catalog) {
                        return;
                    }
                    var isDoor = hole.type.indexOf("door") > -1;
                    var isWindow = hole.type.indexOf("window") > -1;
                    var name = "";
                    var subName = "";
                    var getHoles = function () {
                        if (isDoor) {
                            name = "Двери";
                            subName = "doors";
                            return generateDoor({ door: hole.catalog, catalog: catalog });
                        }
                        if (isWindow) {
                            name = "Окна";
                            subName = "windows";
                            return generateWindow({ catalog: catalog, window: hole.catalog });
                        }
                    };
                    var holes = getHoles();
                    catalog.registerCategory(name, subName, holes);
                    // // const holes = generateDoor({ door: doors, catalog });
                    // catalog.registerCategory("doors", "Doors", holes);
                    // // const holes = generateWindow({ catalog, window: widnows });
                    // // console.log(holes)
                    // catalog.registerCategory("windows", "Windows", holes);
                    // generateFurniture({ catalog, furniture: item.catalog });
                });
            });
            var selectedLayoutId_1 = localStorageWorker.get({
                key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_ID,
            });
            var findLayout = layouts.find(function (layout) { return "".concat(layout.id) === selectedLayoutId_1 && layout.data.id; });
            // console.log(findLayout);
            if (selectedLayoutId_1 && findLayout) {
                var metaLayer = {
                    unit: "cm",
                    layers: {
                        "layer-1": findLayout.data,
                    },
                    grids: {
                        h1: {
                            id: "h1",
                            type: "horizontal-streak",
                            properties: {
                                step: 20,
                                colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                            },
                        },
                        v1: {
                            id: "v1",
                            type: "vertical-streak",
                            properties: {
                                step: 20,
                                colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                            },
                        },
                    },
                    selectedLayer: "layer-1",
                    groups: {},
                    width: 3000,
                    height: 2000,
                    meta: {},
                    guides: {
                        horizontal: {},
                        vertical: {},
                        circular: {},
                    },
                };
                handleLoadRooms(metaLayer);
            }
        }
    };
    var handleStartLoadingLayouts = function () {
        setIsLoadingLayouts(true);
    };
    var handleStopLoadingLayouts = function () {
        setIsLoadingLayouts(false);
    };
    var handleStartLoadingAdminAction = function () {
        // setIsLoadingAdminAction(true)
    };
    var handleStopLoadingAdminAction = function () {
        // setIsLoadingAdminAction(false)
    };
    // FOR_ADMIN
    var _w = __read(useState([]), 2), currentUsers = _w[0], setCureentUsers = _w[1];
    var handleUpdateProjects = function (projects) {
        setAllProjects(projects);
    };
    var handleStartLoadinProjects = useCallback(function () {
        setIsLoadingProjects(true);
    }, []);
    var handleStopLoadingProjects = useCallback(function () {
        setIsLoadingProjects(false);
    }, []);
    var handleStartLoadingRooms = useCallback(function () {
        isLoadingMeta.isStartLoadingMeta();
        setIsLoadingRooms(true);
    }, []);
    var handleStopLoadingRooms = useCallback(function () {
        isLoadingMeta.isStopLoadingMeta();
        setIsLoadingRooms(false);
    }, []);
    var handleStartLoadingSaveRooms = useCallback(function () {
        // setIsLoadingSaveRooms(true);
        isLoadingMeta.isStartLoadingMeta();
    }, []);
    var handleStopLoadingSaveRooms = useCallback(function () {
        // setIsLoadingSaveRooms(false);
        isLoadingMeta.isStopLoadingMeta();
    }, []);
    var handleStartLoadingCollage = useCallback(function () {
        setIsLoadingCollage(true);
    }, []);
    var handleStopLoadingCollage = useCallback(function () {
        setIsLoadingCollage(false);
    }, []);
    // const handleLoadCollage = useCallback((collageData) => {
    //   //  props.setCollageInfo(collageData);
    //   props.collageActions.setCollageData(collageData);
    //   isLoadingMeta.isStopLoadingMeta();
    // }, []);
    // const handleLoadEstimates = useCallback(() => {
    //   if (
    //     !props.collageInfo.data ||
    //     props.collageInfo.data.furniture.length === 0
    //   ) {
    //     isLoadingMeta.isStartLoadingMeta();
    //     getCollageRequest({
    //       startLoadingCallback: handleStartLoadingCollage,
    //       stopLoadingCallback: handleStopLoadingCollage,
    //       onSuccess: handleLoadCollage,
    //     });
    //     setChangingFurnitureId('');
    //   }
    // }, []);
    var handleLoadRooms = useCallback(function (data) {
        // const handleLoadRoomsOnSuccess = (rooms: Array<TRoom>) => {
        //   const newFormat = transformObject(rooms[0]);
        //   projectActions.loadProject(newFormat);
        // };
        // const newFormat = transformObject(data);
        // console.log(data)
        // console.log('before')
        projectActions.loadProject(data);
        // console.log('after')
        // console.log(data)
        // getRoomsRequest({
        //   startLoadingCallback: handleStartLoadingRooms,
        //   stopLoadingCallback: handleStopLoadingRooms,
        //   onSuccess: handleLoadRoomsOnSuccess,
        //   // onError: commonCallbackError,
        // });
    }, [handleStartLoadingRooms, handleStopLoadingRooms]);
    var handleRefreshLayouts = function () {
        // handleLoadRooms();
        // localStorageWorker.remove({
        //   key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_ID,
        // });
        // localStorageWorker.remove({
        //   key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_NAME,
        // });
        setHardClear(true);
        getLayoutsRequest({
            startLoadingCallback: handleStartLoadingLayouts,
            stopLoadingCallback: handleStopLoadingLayouts,
            onSuccess: handleLoadLayouts,
        });
        handleLoadRooms({});
    };
    var handleRefresh = function (data) {
        getLayoutsRequest({
            startLoadingCallback: handleStartLoadingLayouts,
            stopLoadingCallback: handleStopLoadingLayouts,
            onSuccess: handleLoadLayouts,
        });
    };
    var handleSelectLayout = function (data) {
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
            value: data.layout_area_temp,
        });
        var metaLayer = {
            unit: "cm",
            layers: {
                "layer-1": data,
            },
            grids: {
                h1: {
                    id: "h1",
                    type: "horizontal-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                    },
                },
                v1: {
                    id: "v1",
                    type: "vertical-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                    },
                },
            },
            selectedLayer: "layer-1",
            groups: {},
            width: 3000,
            height: 2000,
            meta: {},
            guides: {
                horizontal: {},
                vertical: {},
                circular: {},
            },
        };
        var layer = data.id
            ? metaLayer
            : { layout_area_temp: data.layout_area_temp };
        handleLoadRooms(layer);
        // console.log(data)
    };
    var handleBackActionButton = useCallback(function () { }, []);
    var handleNextActionButton = useCallback(function () { }, []);
    var handleMinusZoomScene = useCallback(function () { }, []);
    var handlePlusZoomScene = useCallback(function () { }, []);
    var handleSaveRooms = useCallback(function () {
        var state = Project.unselectAll(props.state).updatedState;
        var stateScene = state.get("scene").toJS();
        var getlayoutAreaTemp = localStorageWorker.get({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
        });
        var layer = stateScene.layers["layer-1"];
        Object.values(layer.items).forEach(function (item) {
            var element = catalog.elements[item.type];
            if (element) {
                layer.items[item.id].catalog = element === null || element === void 0 ? void 0 : element.catalog;
            }
        });
        var data = __assign(__assign(__assign({}, layer), collageDataForSave), { layout_area_temp: getlayoutAreaTemp });
        postRoomsRequest({
            startLoadingCallback: handleStartLoadingSaveRooms,
            stopLoadingCallback: handleStopLoadingSaveRooms,
            onSuccess: handleRefresh,
            data: data,
        });
    }, [props, handleStartLoadingSaveRooms, handleStopLoadingSaveRooms]);
    var handleOpenEvaluates = useCallback(function () {
        props.setIsOpenEvaluates(true);
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenScene(false);
        props.setIsOpenCollage(false);
    }, []);
    var handleOpenScene = useCallback(function () {
        props.setIsOpenScene(true);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenCollage(false);
    }, []);
    var handleOpenCollage = useCallback(function () {
        props.setIsOpenScene(false);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenCollage(true);
    }, []);
    var handleOpenCollageAndEstimates = useCallback(function () {
        props.setIsOpenScene(false);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollage(false);
        setTimeout(function () {
            props.setIsOpenCollageAndEstimates(true);
        }, 0);
    }, []);
    var handleOpenQuestionary = useCallback(function () {
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenScene(true);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollage(false);
        props.setIsOpenQuestionary(true);
    }, []);
    var handleOpen3D = useCallback(function () {
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenScene(false);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollage(false);
    }, []);
    var handleStartLoadingFurnitures = useCallback(function () {
        setIsLoadingFurnitures(true);
    }, []);
    var handleStopLoadingFurnitures = useCallback(function () {
        setIsLoadingFurnitures(false);
    }, []);
    var handleStartLoadingRoomTypes = useCallback(function () {
        setIsLoadingRoomTypes(true);
    }, []);
    var handleStopLoadingRoomTypes = useCallback(function () {
        setIsLoadingRoomTypes(false);
    }, []);
    var handleStartLoadingShards = useCallback(function () {
        setIsLoadingShards(true);
    }, []);
    var handleStopLoadingShards = useCallback(function () {
        setIsLoadingShards(false);
    }, []);
    var handleLoadWindows = function (widnows) {
        // setWindows(widnows);
        // const holes = generateWindow({ catalog, window: widnows });
        // // console.log(holes)
        // catalog.registerCategory("windows", "Окна", holes);
        // catalog.registerCategory('doors', 'Doors', [Holes.door, Holes.doorDouble, Holes.panicDoor, Holes.panicDoorDouble, Holes.slidingDoor] );
        // setWindows(widnows);
    };
    var handleLoadDoors = function (doors) {
        // setDoors(doors);
        // const holes = generateDoor({ door: doors, catalog });
        // catalog.registerCategory("doors", "Двери", holes);
    };
    var handleLoadRoomTypes = function (roomTypes) {
        // console.log(roomTypes);
        var item_window_types = roomTypes.item_window_types;
        var item_door_types = roomTypes.item_door_types;
        setDoors(item_door_types);
        var holesDoor = generateDoor({ door: item_door_types, catalog: catalog });
        catalog.registerCategory("doors", "Двери", holesDoor);
        setWindows(item_window_types);
        var holesWindow = generateWindow({ catalog: catalog, window: item_window_types });
        // console.log(holes)
        catalog.registerCategory("windows", "Окна", holesWindow);
        var formattedRoomTypesFurniture = enumFurnitureTypes({
            roomTypes: roomTypes.area_types,
            furnitureTypes: roomTypes.item_furniture_types,
        });
        setRoomTypes(formattedRoomTypesFurniture);
    };
    var handleLoadFurnitures = function (furniture) {
        //  console.log(furniture);
        setFurnitures(furniture);
        generateFurniture({ catalog: catalog, furniture: furniture });
        // console.log(furniture)
        // createCatalogElement(catalog)e
        // generateFurniture({
        //   catalog,
        //   furniture
        // })
        // setFurnitures(furniture);
    };
    // console.log('nije')
    // console.log(Catalog)
    var handleUpdateCatalog = function () {
        projectActions.initCatalog(catalog);
        // console.log(projectActions)
        isLoadingMeta.isStopLoadingMeta();
    };
    var handleGenerateAuto = function (questionaryData) {
        // const state = Project.unselectAll(props.state).updatedState;
        // const stateScene = state.get("scene").toJS();
        // const oldFormat = reverseTransform(stateScene.layers["layer-1"]);
        // const olddFormatScaled = getReversScaledRooms([oldFormat]);
        var state = Project.unselectAll(props.state).updatedState;
        var stateScene = state.get("scene").toJS();
        // const oldFormat = reverseTransform(stateScene.layers["layer-1"]);
        // const olddFormatScaled = getReversScaledRooms([oldFormat]);
        var getlayoutAreaTemp = localStorageWorker.get({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
        });
        var layer = stateScene.layers["layer-1"];
        var data = __assign(__assign({}, layer), { questionary: questionaryData, layout_area_temp: getlayoutAreaTemp, is_ml_generated: false });
        // console.log(data);
        var generateAfterSuccessSave = function () {
            loongPoolingLayout();
            startPolling();
            postGenerateAutoRequest({
                // startLoadingCallback: handleStartLoadingSaveRooms,
                // stopLoadingCallback: handleStopLoadingSaveRooms,
                onSuccess: handleRefreshLayouts,
                // data: olddFormatScaled,
                //
            });
        };
        postRoomsRequest({
            startLoadingCallback: handleStartLoadingSaveRooms,
            stopLoadingCallback: handleStopLoadingSaveRooms,
            data: data,
            onSuccess: generateAfterSuccessSave,
        });
    };
    var handleGetAllProjects = function () {
        getProjectsRequest({
            startLoadingCallback: handleStartLoadinProjects,
            stopLoadingCallback: handleStopLoadingProjects,
            onSuccess: handleUpdateProjects,
            // onError: commonCallbackError,
        });
    };
    var handleAddProject = function (_a) {
        var name = _a.name, callbackOnSuccess = _a.callbackOnSuccess;
        var onSuccess = function (data) {
            localStorageWorker.set({
                key: LOCAL_STRORAGE_KEYS.SELECTED_PROJECT_ID,
                value: data.data.id,
            });
            callbackOnSuccess();
            handleGetAllProjects();
        };
        postAddProjectRequest({
            data: { name: name },
            onSuccess: onSuccess,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleAddLyaouts = function (_a) {
        var name = _a.name, callbackOnSuccess = _a.callbackOnSuccess, layout_area_temp = _a.layout_area_temp;
        var onSuccess = function (layaut) {
            callbackOnSuccess(layaut);
            handleGetAllProjects();
            getLayoutsRequest({
                startLoadingCallback: handleStartLoadingLayouts,
                stopLoadingCallback: handleStopLoadingLayouts,
                onSuccess: handleLoadLayouts,
            });
        };
        postAddLayoutsRequest({
            data: { name: name, data: { layout_area_temp: layout_area_temp }, layout_area_temp: layout_area_temp },
            onSuccess: onSuccess,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleAddUser = function (_a) {
        var name = _a.name, password = _a.password, callbackOnSuccess = _a.callbackOnSuccess;
        var handleOnSuccessAddUser = function () {
            // handleRefresh();
            callbackOnSuccess();
        };
        postAddUserRequest({
            data: {
                name: name,
                password: password,
            },
            onSuccess: handleOnSuccessAddUser,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleGetUsers = function () {
        getUsersRequest({
            onSuccess: function (users) { return setCureentUsers(users); },
        });
    };
    var getProjectById = function (id) {
        var handleCallbackOnSuccess = function (data) {
            var fillteredProject = allProjects.filter(function (project) { return project.id !== id; });
            fillteredProject.push(data);
            handleUpdateProjects(fillteredProject);
        };
        getProjectByIdRequest({
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
            id: id,
            onSuccess: handleCallbackOnSuccess,
        });
    };
    var handleUpdateProjectById = function (_a) {
        var id = _a.id, name = _a.name, callbackOnSuccess = _a.callbackOnSuccess;
        updateProjectByIdRequest({
            data: { name: name },
            id: id,
            onSuccess: callbackOnSuccess,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleLoadRoomsT = function () { };
    var onSelectExample = function (room) { };
    // console.log(linesActions)
    var handleStartBuildBode = function () {
        linesActions.selectToolDrawingLine("wall");
    };
    var handleStopBuildBode = function () {
        // console.log(props)
        projectActions.rollback();
        // try {
        //   // linesActions.endDrawingLine();
        // } catch (e) {
        //   console.log(e);
        // }
    };
    var handleCallbackOnSuccessRecognition = function (data) {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.data)) {
            return;
        }
        var layer = data.data.data;
        var metaLayer = {
            unit: "cm",
            layers: {
                "layer-1": layer,
            },
            grids: {
                h1: {
                    id: "h1",
                    type: "horizontal-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                    },
                },
                v1: {
                    id: "v1",
                    type: "vertical-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                    },
                },
            },
            selectedLayer: "layer-1",
            groups: {},
            width: 3000,
            height: 2000,
            meta: {},
            guides: {
                horizontal: {},
                vertical: {},
                circular: {},
            },
        };
        // console.log(metaLayer)
        handleLoadRooms(metaLayer);
        hadnleCloseRecorgnition();
    };
    var handlePlanRecorginition = function (file) {
        recognitionRequest({
            data: file,
            startLoadingCallback: handleStartLoadingPlanRecognition,
            stopLoadingCallback: handleStopLoadingPlanRecognition,
            onSuccess: handleCallbackOnSuccessRecognition,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadInfo, { handleUpdateCatalog: handleUpdateCatalog, handleStartLoadingFurnitures: handleStartLoadingFurnitures, handleStopLoadingFurnitures: handleStopLoadingFurnitures, handleStartLoadingRoomTypes: handleStartLoadingRoomTypes, handleStopLoadingRoomTypes: handleStopLoadingRoomTypes, handleStopLoadingShards: handleStopLoadingShards, handleStartLoadingShards: handleStartLoadingShards, handleLoadProjects: handleUpdateProjects, handleStartLoadinProjects: handleStartLoadinProjects, handleStopLoadingProjects: handleStopLoadingProjects, handleLoadFurnitures: handleLoadFurnitures, handleLoadRoomTypes: handleLoadRoomTypes, handleLoadDoors: handleLoadDoors, handleLoadWindows: handleLoadWindows, isLoadingMeta: isLoadingMeta, handleLoadLayouts: handleLoadLayouts, handleStartLoadingLayouts: handleStartLoadingLayouts, handleStopLoadingLayouts: handleStopLoadingLayouts }),
        props.isOpenQuestionary && (React.createElement(Questionary, { isOpen: props.isOpenQuestionary, setIsOpen: props.setIsOpenQuestionary, handleGenerateAuto: handleGenerateAuto, state: props.state })),
        React.createElement(TopMenu, { handleSaveProject: handleSaveRooms, allProjects: allProjects, 
            // handleRefresh={handleRefresh}
            handleRefreshLayouts: handleRefreshLayouts, 
            //   handleGenerateProjectFromBuild={handleGenerateAuto}
            handleAddProject: handleAddProject, handleAddUser: handleAddUser, handleGetUsers: handleGetUsers, currentUsers: currentUsers, handleGetAllProjects: handleGetAllProjects, getProjectById: getProjectById, handleUpdateProjectById: handleUpdateProjectById, isLoadingAdminAction: isLoadingAdminAction, allLayouts: allLayouts, handleAddLyaouts: handleAddLyaouts, handleSelectLayout: handleSelectLayout, hardClear: hardClear, handleFalseHardClear: handleFalseHardClear, roomTypes: roomTypes, handleOpenQuiz: handleOpenQuiz, onSelectExample: onSelectExample, isLoadingRooms: false, isLoadingSaveRooms: false, isLoadingGenerateProject: false, handleLoadRooms: handleLoadRoomsT, handlePlanRecorginition: handlePlanRecorginition, isLoadingPlanRecognition: isLoadingPlanRecognition, hadnleOpenRecorgnition: hadnleOpenRecorgnition, hadnleCloseRecorgnition: hadnleCloseRecorgnition, isOpenRecorgnition: isOpenRecorgnition, handleOpenScene: handleOpenScene }),
        React.createElement(MainMenu, { state: state, catalog: catalog, handleBuildModeStart: handleStartBuildBode, handleBuildModeStop: handleStopBuildBode, 
            //  handleInstallDoor={handleAddPortalOnWall}
            handleMinusZoomScene: handleMinusZoomScene, handlePlusZoomScene: handlePlusZoomScene, handleBackActionButton: handleBackActionButton, handleNextActionButton: handleNextActionButton, handleOpenEvaluates: handleOpenEvaluates, handleOpenCollageAndEstimates: handleOpenCollageAndEstimates, handleOpenQuestionary: handleOpenQuestionary, 
            //  handlePrint={handlePrint}
            handleOpenCollage: handleOpenCollage, handleOpenScene: handleOpenScene, 
            //  handleChangeWallThickness={handleChangeWallThickness}
            //  handleChangeWallHeight={handleChangeWallHeight}
            //  handleSelectStructure={handleSelectStructure}
            //  handleSelectWindow={handleSelectWindow}
            //  handleSelectDoor={handleSelectDoor}
            //  handleSelectRoomShard={handleSelectRoomShard}
            //  handleChangeStructureHeightLabel={handleChangeStructureHeightLabel}
            //  handleChangeStructureWidthLabel={handleChangeStructureWidthLabel}
            //  handleChangeStructureLengthLabel={handleChangeStructureLengthLabel}
            //  handleChangeWindowHeightFromFloorLabel={
            //    handleChangeWindowHeightFromFloorLabel
            //  }
            //  handleChangeWindowLengthLabel={handleChangeWindowLengthLabel}
            //  handleChangeWindowWidthLabel={handleChangeWindowWidthLabel}
            //  handleChangeDoorLengthLabel={handleChangeDoorLengthLabel}
            //  handleChangeDoorWidthLabel={handleChangeDoorWidthLabel}
            //  handleChangeRoomLengthLabel={handleChangeRoomLengthLabel}
            //  handleChangeRoomWidthLabel={handleChangeRoomWidthLabel}
            //  handleAddFurnitureOnRoom={handleAddFurnitureOnRoom}
            onLoadEstimates: handleLoadEstimates, 
            //  handleIsSquareBuild={handleIsSquareBuild}
            roomTypes: roomTypes, 
            //  isOpenCollageAndEstimates={isOpenCollageAndEstimates}
            //  isOpenCollage={isOpenCollage}
            //  isOpenScene={isOpenScene}
            //  isOpenEvaluates={isOpenEvaluates}
            //  selectedWallThickness={selectedWallThickness}
            furnitures: furnitures, 
            // structureHeightLabel={structureHeightLabel}
            // structureLengthLabel={structureLengthLabel}
            // structureWidthLabel={structureWidthLabel}
            // windowHeightFromFloorLabel={windowHeightFromFloorLabel}
            // windowLengthLabel={windowLengthLabel}
            // windowWidthLabel={windowWidthLabel}
            // doorLengthLabel={doorLengthLabel}
            // doorWidthLabel={doorWidthLabel}
            // wallThickness={wallThickness}
            // wallHeight={wallHeight}
            // roomLenghtLabel={roomLenghtLabel}
            // roomWidthLabel={roomWidthLabel}
            // selectRoomShard={selectRoomShard}
            // selectDoor={selectDoor}
            // selectWindow={selectWindow}
            // selectStructure={selectStructure}
            //  roomImagesShards={shards}
            roomImagesDoors: doors, roomImagesWindow: windows, 
            //  roomImagesStructure={structure}
            changingFurnitureId: changingFurnitureId, setChangingFurnitureId: setChangingFurnitureId, 
            //  setIsUploadingCollage={setIsUploadingCollage}
            handleOpen3D: handleOpen3D, itemsActions: itemsActions, holesActions: holesActions, collageActions: props.collageActions, collageInfo: props.collageInfo }),
        React.createElement(MainHeaderIcon, { state: state, handleBackActionButton: handleBackActionButton, handleNextActionButton: handleNextActionButton, handleMinusZoomScene: handleMinusZoomScene, handlePlusZoomScene: handlePlusZoomScene, handleOpenEvaluates: handleOpenEvaluates, handleOpenCollageAndEstimates: handleOpenCollageAndEstimates, handlePrint: function () {
                setIsPrinting(true);
            }, handleOpenCollage: handleOpenCollage, handleOpenScene: handleOpenScene, onLoadEstimates: handleLoadEstimates, isOpenCollageAndEstimates: props.isOpenCollageAndEstimates, isOpenCollage: props.isOpenCollage, isOpenScene: props.isOpenScene, isOpenEvaluates: props.isOpenEvaluates, 
            //     handleGenerateProjectFromBuild={handleGenerateAuto}
            handleOpenQuestionary: handleOpenQuestionary, viewer3DActions: viewer3DActions, viewer2DActions: viewer2DActions, projectActions: projectActions, handleOpen3D: handleOpen3D }),
        React.createElement(ModalQuiz, { onCloseQuiz: handleCloseQuiz, isOpenQuiz: isOpenQuiz }),
        React.createElement(Notifications, { notification: commonRequestNotification })));
});
