var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var QuestionaryContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  background-color: #ffffff;\n  border-radius: 30px;\n\n  padding: 30px 60px 90px 60px;\n\n  width: 80vw;\n  min-height: 80vh;\n  @media (max-width: 480px) {\n    width: 100vw;\n    height: 100vh;\n    padding: 32px 20px 187px 20px;\n    border-radius: 0;\n  }\n"], ["\n  position: relative;\n  background-color: #ffffff;\n  border-radius: 30px;\n\n  padding: 30px 60px 90px 60px;\n\n  width: 80vw;\n  min-height: 80vh;\n  @media (max-width: 480px) {\n    width: 100vw;\n    height: 100vh;\n    padding: 32px 20px 187px 20px;\n    border-radius: 0;\n  }\n"])));
export var QuestionaryTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  width: 100%;\n"], ["\n  text-align: center;\n  width: 100%;\n"])));
export var PageNumber = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #8dc646;\n"], ["\n  color: #8dc646;\n"])));
export var ButtonBlock = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  justify-content: center;\n  @media (max-width: 480px) {\n    justify-content: space-between;\n  }\n"], ["\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  justify-content: center;\n  @media (max-width: 480px) {\n    justify-content: space-between;\n  }\n"])));
export var BackButton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 62px;\n  height: 62px;\n  border-radius: 10px;\n  padding: 20px;\n  box-shadow: 0px 10px 25px 5px #00000050;\n"], ["\n  width: 62px;\n  height: 62px;\n  border-radius: 10px;\n  padding: 20px;\n  box-shadow: 0px 10px 25px 5px #00000050;\n"])));
export var StyledButton = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 10px;\n  padding: 16px 60px;\n  box-shadow: 0px 10px 25px 5px #00000050;\n  background-color: ", ";\n  color: ", ";\n  font-size: 22px;\n  line-height: 30px;\n"], ["\n  border-radius: 10px;\n  padding: 16px 60px;\n  box-shadow: 0px 10px 25px 5px #00000050;\n  background-color: ", ";\n  color: ", ";\n  font-size: 22px;\n  line-height: 30px;\n"])), function (_a) {
    var $isMain = _a.$isMain;
    return ($isMain ? "#8DC646" : "#ffffff");
}, function (_a) {
    var $isMain = _a.$isMain;
    return (!$isMain ? "#8DC646" : "#ffffff");
});
export var CloseButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 21.94px;\n  color: #8dc646;\n  cursor: pointer;\n  position: absolute;\n  top: 30px;\n  right: 40px;\n"], ["\n  background: none;\n  border: none;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 21.94px;\n  color: #8dc646;\n  cursor: pointer;\n  position: absolute;\n  top: 30px;\n  right: 40px;\n"])));
export var ModalBackdrop = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: ", ";\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1000;\n"], ["\n  display: ", ";\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1000;\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? "flex" : "none");
});
export var ButtonBlockContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: calc(100% - 120px);\n  position: absolute;\n  bottom: 30px;\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  @media (max-width: 480px) {\n    width: calc(100% - 40px);\n  }\n"], ["\n  width: calc(100% - 120px);\n  position: absolute;\n  bottom: 30px;\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  @media (max-width: 480px) {\n    width: calc(100% - 40px);\n  }\n"])));
export var StyledImg = styled.img(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  height: 20px;\n  width: auto;\n  transition: background-color 0.2s;\n  cursor: pointer;\n  /* &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  } */\n"], ["\n  height: 20px;\n  width: auto;\n  transition: background-color 0.2s;\n  cursor: pointer;\n  /* &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  } */\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
