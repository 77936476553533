"use client";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _this = this;
// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState, useMemo, } from "react";
import { CollageLayout } from "../CollageLayout";
import { CollageCanvas } from "../CollageCanvas";
import { collage } from "./mock";
import styled from "styled-components";
import { SelectBox } from "src/ui-kit/select-box";
import { mock } from "src/mock";
var areas = {
    KITCHEN: "Кухня",
    LIVING_ROOM: "Гостиная",
    BATHROOM: "Ванна",
    BEDROOM: "Спальня",
    CHILDRENS_ROOM: "Детская комната",
    STUDY_ROOM: "Кабинет",
    TOILET: "Туалет",
};
var CollageComponent = function (props) {
    var isFullWindow = props.isFullWindow, isPrint = props.isPrint, setChangingFurnitureId = props.setChangingFurnitureId, isLoadingCollage = props.isLoadingCollage, setIsLoadingCollage = props.setIsLoadingCollage, collageActions = props.collageActions, isLoadingMeta = props.isLoadingMeta, state = props.state, setCollageDataForSave = props.setCollageDataForSave;
    var C = useRef({
        canvas: null,
        ctx: null,
        json: "",
        selectedObject: null,
        selectedObjectOffset: null,
        inerval: null,
    });
    var options = null;
    var layouts = useMemo(function () { return new Map(); }, []);
    var rotatedObjectFurniture = useMemo(function () { return new Set(); }, []);
    var distance = 50;
    var rulerData = useRef({
        heightCanvas: 0,
        yZero: 0,
        yEnd: 0,
        xZero: 0,
        xEnd: 0,
        xStep: 0,
    });
    var ref = useRef();
    var _debug = true;
    var minWallHeight = 2000;
    var printedCollageWidth = 1700;
    var handleStartLoadingCollage = useCallback(function () {
        setIsLoadingCollage(true);
    }, []);
    var handleStopLoadingCollage = useCallback(function () {
        setIsLoadingCollage(false);
    }, []);
    var handleLoadCollage = useCallback(function (collageData) {
        collageActions.setCollageData(collageData);
        isLoadingMeta.isStopLoadingMeta();
    }, []);
    var _notFurniture = "decor" || "door" || "window";
    var debug = function (object) {
        if (_debug) {
            console.log(object);
        }
    };
    // const _state = Project.unselectAll(state).updatedState;
    // const stateScene = _state.get("scene").toJS();
    // const layer = stateScene.layers["layer-1"];
    var layer = mock;
    var roomsArray = useMemo(function () {
        var rooms = Object.keys(layer.collage_data.areas).map(function (el) { return ({
            name: areas[layer.areas[el].properties.areaType],
            hash: el,
        }); });
        //  console.log(rooms);
        return rooms.map(function (el) { return ({
            value: String(el.name),
            label: String(el.name),
            id: String(el.hash),
        }); });
    }, []);
    var _a = __read(useState(Object.keys(layer.collage_data.areas)[0]), 2), currentRoomHash = _a[0], setCurrentRoomHash = _a[1];
    var _b = __read(useState({
        id: "",
        positions: {},
        wall: {},
        floor: {},
        items: [],
    }), 2), collageData = _b[0], setCollageData = _b[1];
    useEffect(function () {
        createCanvas();
        if (!isLoadingCollage &&
            !layer.collage_data
        //  (!state.collage.data || state.collage.data.furniture.length === 0)
        ) {
            // isLoadingMeta.isStartLoadingMeta();
            // getCollageRequest({
            //   startLoadingCallback: handleStartLoadingCollage,
            //   stopLoadingCallback: handleStopLoadingCollage,
            //   onSuccess: handleLoadCollage,
            //   // onError: commonCallbackError,
            //   //   collage_json_id: 1,
            // });
            console.log(state);
            handleLoadCollage(collage);
        }
        else {
            // console.log(layer.collage_data);
            var newCollageData = __assign(__assign({}, layer.collage_data.areas[currentRoomHash]), { items: Object.keys(layer.collage_data.areas[currentRoomHash].items).map(function (el) { return layer.collage_data.areas[currentRoomHash].items[el]; }) });
            console.log(newCollageData);
            newCollageData = __assign(__assign({}, newCollageData), { items: newCollageData.items.map(function (el) {
                    var _a, _b, _c, _d, _e;
                    var imageLink = el.type === "door"
                        ? "https://convasroom.mvpdev.ru/assets/img/internal-box/objects/door.png"
                        : ((_a = layer.items[el.idx]) === null || _a === void 0 ? void 0 : _a.catalog.collage_image_src) ||
                            ((_b = layer.items[el.idx]) === null || _b === void 0 ? void 0 : _b.catalog.type_sub.default_collage_image_src);
                    return __assign(__assign({}, el), { id: el.idx, width: ((_c = layer.items[el.idx]) === null || _c === void 0 ? void 0 : _c.catalog.width) ||
                            layer.holes[el.idx].properties.width.length * 10, name: ((_d = layer.items[el.idx]) === null || _d === void 0 ? void 0 : _d.name) || layer.holes[el.idx].name, image: imageLink, image_left: imageLink, image_right: imageLink, furniture_id: ((_e = layer.items[el.idx]) === null || _e === void 0 ? void 0 : _e.catalog.id) || null });
                }) });
            console.log("newCollageData");
            console.log(newCollageData);
            setCollageData(newCollageData);
        }
    }, [currentRoomHash]);
    useEffect(function () {
        if (collageData && JSON.stringify(collageData) !== C.current.json) {
            C.current.json = JSON.stringify(collageData);
            layouts.clear();
            addJson(structuredClone(collageData));
        }
    }, [collageData]);
    var createCanvas = function () {
        if (!C.current.canvas) {
            var newCanvas = new CollageCanvas(ref.current, changeObjectByCoordinate, mooveObjectByCoordinate, selectObjectByCoordinate, clearSelectedObject, options, isPrint, printedCollageWidth);
            var cont = newCanvas.getContext();
            if (isPrint) {
                cont.canvas.width = printedCollageWidth;
            }
            C.current.canvas = newCanvas;
            C.current.ctx = cont;
        }
    };
    var objects = function () {
        var e_1, _a;
        var objects = [];
        try {
            for (var _b = __values(layouts.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var layout = _c.value;
                objects = objects.concat(layout.objects);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return objects;
    };
    var updateObjectByPosition = function (position, objectSetting) { return __awaiter(void 0, void 0, void 0, function () {
        var object;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(position === "wall")) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateWall(objectSetting)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 2:
                    if (!(position === "floor")) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateFloor(objectSetting)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 4: return [4 /*yield*/, getObjectByPosition(position)];
                case 5:
                    object = _a.sent();
                    return [4 /*yield*/, object.updateOptions(objectSetting)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, object.init()];
                case 7:
                    _a.sent();
                    _a.label = 8;
                case 8: return [4 /*yield*/, resizeCanvasByFirstLayer()];
                case 9:
                    _a.sent();
                    return [4 /*yield*/, render()];
                case 10:
                    _a.sent();
                    addRuler();
                    return [2 /*return*/];
            }
        });
    }); };
    var selectObjectByCoordinate = function (x, y) {
        var maxLayout = -1;
        objects().forEach(function (object) {
            if (x >= object.x &&
                x <= object.x + object.width &&
                y >= object.y &&
                y <= object.y + object.height &&
                parseInt(object.layer) > maxLayout) {
                C.current.selectedObject = object;
                C.current.selectedObjectOffset = [x - object.x, y - object.y];
            }
        });
        C.current.inerval = setInterval(function () {
            render();
            addRuler();
        }, 1000 / 30);
    };
    var clearSelectedObject = function () {
        clearInterval(C.current.inerval);
        var data = {
            id: C.current.selectedObject.id,
            userX: C.current.selectedObject.x / C.current.canvas.ratioWidth,
            userY: C.current.selectedObject.y / C.current.canvas.ratioWidth,
        };
        // console.log(data);
        // console.log(
        //   C.current.selectedObject._options.width / C.current.selectedObject.width
        // );
        C.current.selectedObject = null;
        C.current.selectedObjectOffset = null;
        collageActions.setFurniturePosition(data);
    };
    var changeObjectByCoordinate = function (x, y) {
        var maxLayout = -1;
        var objectClick = null;
        objects().forEach(function (object) {
            if (x >= object.x &&
                x <= object.x + object.width &&
                y >= object.y &&
                y <= object.y + object.height &&
                parseInt(object.layer) > maxLayout) {
                objectClick = object;
            }
        });
        if (objectClick) {
            //   console.log(objectClick._id);
            //      setChangingFurnitureId(String(objectClick._id));
        }
    };
    var mooveObjectByCoordinate = function (x, y) {
        if (C.current.selectedObject) {
            C.current.selectedObject.x = x - C.current.selectedObjectOffset[0];
            C.current.selectedObject.y = y - C.current.selectedObjectOffset[1];
        }
    };
    var addWall = function (options) {
        if (options === void 0) { options = {}; }
        if (!(options === null || options === void 0 ? void 0 : options.height)) {
            options.height = "100%";
        }
        options.y = 0;
        return addLayout("wall", options);
    };
    var addFloor = function (options) {
        if (options === void 0) { options = {}; }
        if (!options.top) {
            var percent = 25;
            if (options.height) {
                if (options.height.substring(options.height.length - 1) == "%") {
                    percent = parseInt(options.height.substring(0, options.height.length - 1));
                }
            }
            options.y = (100 - percent).toString() + "%";
        }
        return addLayout("floor", options);
    };
    var render = function () {
        var e_2, _a;
        var _b;
        // console.log(layouts);
        (_b = C.current.ctx) === null || _b === void 0 ? void 0 : _b.reset();
        try {
            for (var _c = __values(layouts.values()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var layout = _d.value;
                layout.render();
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    var addLayout = function (name, options) {
        if (options === void 0) { options = {}; }
        var layout = getLayout(name);
        if (!layout) {
            layout = new CollageLayout(C.current.ctx, options, C.current.canvas, isPrint, printedCollageWidth);
            layout.init();
            layout.interiorBox = _this;
            layouts.set(name, layout);
        }
        return layout;
    };
    var resizeCanvasByFirstLayer = function () {
        var layout = getLayout("1");
        if (!layout) {
            return false;
        }
        var sumWidth = distance;
        var maxTop = 0;
        for (var i = 0; i < layout.objects.length; i++) {
            var width = parseInt(layout.objects[i]._options.width);
            sumWidth += width ? width : 0;
            sumWidth += distance;
            var height = (width / layout.objects[i]._widthNatural) *
                layout.objects[i]._heightNatural; // высота объекта в мм
            var top_1 = height + layout.objects[i]._y;
            if (top_1 > maxTop) {
                maxTop = top_1;
            }
        }
        var floor = getLayout("floor");
        var percent = 0.25;
        if (floor._options.height.substring(floor._options.height.length - 1) == "%") {
            percent =
                parseInt(floor._options.height.substring(0, floor._options.height.length - 1)) / 100;
        }
        var wallHeight = Math.max(maxTop, minWallHeight) + distance;
        var canvasHeight = Math.ceil(wallHeight / (1 - percent));
        var a = isPrint ? printedCollageWidth : C.current.canvas._dom.clientWidth;
        //  C.current.canvas.ratioWidth = a / sumWidth;
        C.current.canvas.ratioWidth = //a / sumWidth;
            window.innerWidth < 480
                ? (window.innerHeight - 100) / canvasHeight
                : a / sumWidth;
        // console.log("C.current.canvas._ratioWidth");
        // console.log(C.current.canvas._ratioWidth);
        // console.log(window.innerHeight);
        var heightCanvas = canvasHeight * C.current.canvas._ratioWidth;
        C.current.canvas.updateHeight(heightCanvas);
        var newyZero = heightCanvas - heightCanvas * percent;
        /* данные для линейки */
        rulerData.current.heightCanvas = heightCanvas;
        rulerData.current.yZero = newyZero;
        rulerData.current.yEnd = distance * C.current.canvas._ratioWidth;
        rulerData.current.xZero = distance * C.current.canvas._ratioWidth;
        rulerData.current.xEnd =
            (sumWidth - distance) * C.current.canvas._ratioWidth;
        rulerData.current.xStep = C.current.canvas._ratioWidth * 100;
        /* ------------------ */
        // debug("Высота канваса: " + canvasHeight + " мм.");
        // debug("Ширина канваса: " + sumWidth + " мм.");
        // debug("Ширина канваса: " + sumWidth * C.current.canvas._ratioWidth);
        // debug("Нулевая координата оси Y: " + newyZero + " px");
        // debug(
        //   "Нулевая координата оси Y: " +
        //     (heightCanvas - heightCanvas * percent) +
        //     " px"
        // );
        recalcSettingObjects(newyZero);
    };
    var getObjectByPosition = function (positionSearch) {
        var objectResult = null;
        objects().forEach(function (object) {
            if (object.position === positionSearch) {
                objectResult = object;
            }
        });
        return objectResult;
    };
    var recalcSettingObjects = function (yZero) {
        var e_3, _a;
        var layoutFirst = getLayout("1");
        var left = distance * C.current.canvas._ratioWidth;
        layoutFirst.objects.forEach(function (item) {
            if (item._dom.updateData) {
                item._dom.updateData();
            }
            var diffX = item._options.x ? item._options.x : 0;
            item.x = left + diffX * C.current.canvas._ratioWidth;
            item.y =
                yZero -
                    item._height -
                    //Тут подобран коэффицинт чтоб прилично смтрлось при маштабировании, потом надо сдeлать что-то поприличнee
                    //конструкция (1 - (0.7 - item._ratioNatural))
                    item._options.y * (1 - (0.7 - item._ratioNatural));
            left = item.x + item._width + distance * C.current.canvas._ratioWidth;
        });
        try {
            for (var layouts_1 = __values(layouts), layouts_1_1 = layouts_1.next(); !layouts_1_1.done; layouts_1_1 = layouts_1.next()) {
                var layout = layouts_1_1.value;
                if (layout[0] === "1") {
                    layout[1].objects.forEach(function (item) {
                        var furn = collageData.items.filter(function (el) { return el.id === item.id; })[0];
                        if (furn.userX !== 0 || furn.userY !== 0) {
                            item.x = furn.userX * C.current.canvas._ratioWidth;
                            item.y = furn.userY * C.current.canvas._ratioWidth;
                        }
                    });
                }
                if (layout[0] === "wall" || layout[0] === "floor" || layout[0] === "1") {
                    continue;
                }
                layout[1].objects.forEach(function (item) {
                    var furn = collageData.items.filter(function (el) { return el.id === item.id; })[0];
                    if (item._dom.updateData) {
                        item._dom.updateData();
                    }
                    if (item.parentPosition) {
                        var objectParent = getObjectByPosition(item.parentPosition);
                        if (objectParent) {
                            if (furn.userX !== 0 || furn.userY !== 0) {
                                item.x = furn.userX * C.current.canvas._ratioWidth;
                                item.y = furn.userY * C.current.canvas._ratioWidth;
                            }
                            else if (item._options.x === "left") {
                                item.x = objectParent.x;
                            }
                            else if (item._options.x === "right") {
                                item.x = objectParent.x + objectParent._width - item._width;
                            }
                            else {
                                item.x =
                                    objectParent.x +
                                        objectParent._width / 2 -
                                        item._width / 2 +
                                        item._options.x * C.current.canvas._ratioWidth;
                            }
                            if (furn.userX === 0 && furn.userY === 0) {
                                item.y =
                                    objectParent.y +
                                        objectParent._height -
                                        item._height -
                                        item._options.y * C.current.canvas._ratioWidth;
                            }
                        }
                    }
                });
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (layouts_1_1 && !layouts_1_1.done && (_a = layouts_1.return)) _a.call(layouts_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    var addRuler = function () {
        var correction = 0; // (rulerData.current.yZero - rulerData.current.yEnd) * 0.2;
        /*рисуем линейку*/
        C.current.ctx.beginPath();
        C.current.ctx.lineWidth = 2;
        C.current.ctx.moveTo(rulerData.current.xZero, 
        //rulerData.current.yZero + correction
        rulerData.current.heightCanvas);
        C.current.ctx.lineTo(rulerData.current.xEnd, 
        // rulerData.current.yZero + correction
        rulerData.current.heightCanvas);
        C.current.ctx.strokeStyle = "#ffffff";
        C.current.ctx.stroke();
        C.current.ctx.beginPath();
        C.current.ctx.lineWidth = 2;
        C.current.ctx.moveTo(rulerData.current.xZero, rulerData.current.yZero);
        C.current.ctx.lineTo(rulerData.current.xZero, rulerData.current.yEnd);
        C.current.ctx.strokeStyle = "#ffffff";
        C.current.ctx.stroke();
        for (var i = 0; i <
            (rulerData.current.xEnd - rulerData.current.xZero) /
                rulerData.current.xStep; i++) {
            C.current.ctx.beginPath();
            C.current.ctx.lineWidth = 2;
            var dashHalfLength = 5;
            if (i > 0) {
                if (i % 5 === 0)
                    dashHalfLength = 7;
                if (i % 10 === 0)
                    dashHalfLength = 9;
            }
            C.current.ctx.moveTo(rulerData.current.xZero + rulerData.current.xStep * i, 
            // rulerData.current.yZero + correction - dashHalfLength
            rulerData.current.heightCanvas - dashHalfLength);
            C.current.ctx.lineTo(rulerData.current.xZero + rulerData.current.xStep * i, 
            //rulerData.current.yZero + correction + dashHalfLength
            rulerData.current.heightCanvas);
            C.current.ctx.strokeStyle = "#ffffff";
            C.current.ctx.stroke();
            if (i % 10 === 0) {
                var m = 1;
                if (i * 100 > 9 && i * 100 < 100)
                    m = 2;
                if (i * 100 > 99 && i * 100 < 1000)
                    m = 3;
                if (i * 100 > 999 && i * 100 < 10000)
                    m = 4;
                C.current.ctx.font = "18px Arial";
                C.current.ctx.fillStyle = "#FFFFFF";
                C.current.ctx.fillText(i * 100, rulerData.current.xZero + rulerData.current.xStep * i - 5 * m, 
                //  rulerData.current.yZero + correction + 30
                rulerData.current.heightCanvas - 20);
            }
        }
        for (var i = 0; i <
            (rulerData.current.yZero - rulerData.current.yEnd) /
                rulerData.current.xStep; i++) {
            C.current.ctx.beginPath();
            C.current.ctx.lineWidth = 2;
            var dashHalfLength = 3;
            if (i > 0) {
                if (i % 5 === 0)
                    dashHalfLength = 5;
                if (i % 10 === 0)
                    dashHalfLength = 7;
            }
            C.current.ctx.moveTo(rulerData.current.xZero - dashHalfLength, rulerData.current.yZero - rulerData.current.xStep * i);
            C.current.ctx.lineTo(rulerData.current.xZero + dashHalfLength, rulerData.current.yZero - rulerData.current.xStep * i);
            C.current.ctx.strokeStyle = "#ffffff";
            C.current.ctx.stroke();
        }
    };
    var addJson = function (jsonData) { return __awaiter(void 0, void 0, void 0, function () {
        var prevPosition, prevRotate, prevCoef, countFurnitureOneLayer, i, countRotate, indexOneLayer, i, objectFurniture, layout;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (jsonData.wall) {
                        addWall(jsonData.wall);
                    }
                    if (jsonData.floor) {
                        addFloor(jsonData.floor);
                    }
                    console.log(11);
                    if (!(jsonData.items && jsonData.items.constructor === Array)) return [3 /*break*/, 4];
                    console.log(jsonData.items);
                    prevPosition = null;
                    prevRotate = null;
                    prevCoef = 1;
                    countFurnitureOneLayer = 0;
                    for (i = 0; i < jsonData.items.length; i++) {
                        if (jsonData.items[i].layer === "1" &&
                            jsonData.items[i].type !== _notFurniture) {
                            countFurnitureOneLayer++;
                        }
                    }
                    countRotate = Math.ceil(countFurnitureOneLayer * 0.2);
                    indexOneLayer = 0;
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < jsonData.items.length)) return [3 /*break*/, 4];
                    objectFurniture = jsonData.items[i];
                    if (!objectFurniture.x &&
                        objectFurniture.type &&
                        jsonData.positions &&
                        jsonData.positions[objectFurniture.type] &&
                        jsonData.positions[objectFurniture.type].x) {
                        objectFurniture.x = jsonData.positions[objectFurniture.type].x;
                    }
                    if (!objectFurniture.y &&
                        objectFurniture.type &&
                        jsonData.positions &&
                        jsonData.positions[objectFurniture.type] &&
                        jsonData.positions[objectFurniture.type].y) {
                        objectFurniture.y = jsonData.positions[objectFurniture.type].y;
                    }
                    layout = addLayout(objectFurniture.layer, {
                        width: "100%",
                        height: "100%",
                    });
                    if (objectFurniture.layer !== "1" && prevPosition) {
                        objectFurniture.parentPosition = prevPosition;
                        objectFurniture.rotation = prevRotate;
                        objectFurniture.width *= prevCoef;
                        objectFurniture.y *= prevCoef;
                    }
                    if (objectFurniture.position && objectFurniture.layer === "1") {
                        prevPosition = objectFurniture.position;
                    }
                    if (!objectFurniture.name && objectFurniture.type) {
                        objectFurniture.name = objectFurniture.type;
                    }
                    if (!objectFurniture.count) {
                        objectFurniture.count = 1;
                    }
                    prevRotate = "front";
                    prevCoef = 1;
                    if (objectFurniture.layer === "1" &&
                        jsonData.items[i].type !== _notFurniture) {
                        if (indexOneLayer < countRotate) {
                            prevCoef = 1; // 1.2;
                            objectFurniture.rotation = "left";
                            objectFurniture.width *= prevCoef;
                            objectFurniture.y *= prevCoef;
                            prevRotate = "left";
                        }
                        else if (indexOneLayer >= countFurnitureOneLayer - countRotate) {
                            objectFurniture.rotation = "right";
                            prevCoef = 1; //1.2;
                            objectFurniture.width *= prevCoef;
                            objectFurniture.y *= prevCoef;
                            prevRotate = "right";
                        }
                        indexOneLayer++;
                    }
                    return [4 /*yield*/, layout.addObject(objectFurniture)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    resizeCanvasByFirstLayer();
                    // setInterval(() => {
                    console.log(layouts);
                    render();
                    addRuler();
                    return [2 /*return*/];
            }
        });
    }); };
    var getLayout = function (key) {
        if (layouts.has(key)) {
            return layouts.get(key);
        }
        return null;
    };
    var updateWall = function (options) { return __awaiter(void 0, void 0, void 0, function () {
        var layout;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    layout = getLayout("wall");
                    layout.updateOptions(options);
                    return [4 /*yield*/, layout.init()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateFloor = function (options) { return __awaiter(void 0, void 0, void 0, function () {
        var layout;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    layout = getLayout("floor");
                    layout.updateOptions(options);
                    return [4 /*yield*/, layout.init()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Container, { "$fullWindow": isFullWindow, isPrint: isPrint, className: "landscape" },
        isFullWindow && (React.createElement(Selector, null,
            React.createElement(SelectBox, { options: roomsArray, onValueChange: function (a) {
                    console.log(a);
                    setCurrentRoomHash(a.id);
                }, hasBorder: true, customFontsizeTitle: "18px", selectedValue: roomsArray[0].value, customWidth: "200px" }))),
        React.createElement("div", { className: "collageDiv", ref: ref })));
};
var Selector = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 150px;\n  right: 20px;\n  z-index: 2;\n\n  width: auto;\n  height: auto;\n  border-radius: 10px;\n\n  background-color: #ffffff;\n"], ["\n  position: absolute;\n  top: 150px;\n  right: 20px;\n  z-index: 2;\n\n  width: auto;\n  height: auto;\n  border-radius: 10px;\n\n  background-color: #ffffff;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: ", ";\n\n  margin: ", ";\n  //  transform: ", ";\n  & > .collageDiv {\n    width: ", ";\n    // height: 70vh !important;\n  }\n\n  @media print {\n    display: block;\n    width: 1700px;\n\n    //height: 100vh;\n    margin: 0;\n    & > div {\n      width: auto !important;\n    }\n    @page {\n      background-color: #ffffff;\n    }\n  }\n\n  /* @media (max-width: 425px) {\n    width: 800px !important ;\n    & > div {\n      width: 800px !important;\n      // height: 70vh !important;\n    }\n  } */\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: ", ";\n\n  margin: ", ";\n  //  transform: ", ";\n  & > .collageDiv {\n    width: ", ";\n    // height: 70vh !important;\n  }\n\n  @media print {\n    display: block;\n    width: 1700px;\n\n    //height: 100vh;\n    margin: 0;\n    & > div {\n      width: auto !important;\n    }\n    @page {\n      background-color: #ffffff;\n    }\n  }\n\n  /* @media (max-width: 425px) {\n    width: 800px !important ;\n    & > div {\n      width: 800px !important;\n      // height: 70vh !important;\n    }\n  } */\n"])), function (p) { return (p.$fullWindow ? "100vw" : "680px"); }, function (p) { return (p.$fullWindow ? "calc(100vh - 60px)" : "auto"); }, function (p) { return (p.$fullWindow ? "0" : "0 0 10px 0"); }, function (p) { return (p.isPrint ? "rotate(90deg)" : ""); }, function (p) { return (p.$fullWindow ? "90% !important" : "100%  !important"); });
var Collage = React.memo(CollageComponent);
export default Collage;
var templateObject_1, templateObject_2;
