var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useMemo, useState } from "react";
import { EstimatesStyled, Selector } from "./styled";
import Collage from "src/components/CollageComponens/Collage";
import { EstimatesTable } from "./estimates-table";
import { SelectBox } from "src/ui-kit/select-box";
import { Project } from "src/class/export";
//import { mock } from "src/mock";
var areas = {
    KITCHEN: "Кухня",
    LIVING_ROOM: "Гостиная",
    BATHROOM: "Ванна",
    BEDROOM: "Спальня",
    CHILDRENS_ROOM: "Детская комната",
    STUDY_ROOM: "Кабинет",
    TOILET: "Туалет",
};
var mock = ["FYpeIzjdeAe", "KSxvYeh1x23", "cFtsaaQGvF", "U263POkyrH"];
export var Estimates = memo(function Estimates(_a) {
    var _b, _c;
    var furniture = _a.furniture, isCollage = _a.isCollage, handleSyncCollage = _a.handleSyncCollage, handleEditFurniture = _a.handleEditFurniture, setChangingFurnitureId = _a.setChangingFurnitureId, isLoadingCollage = _a.isLoadingCollage, setIsLoadingCollage = _a.setIsLoadingCollage, collageActions = _a.collageActions, state = _a.state, estimatesData = _a.estimatesData, setCollageDataForSave = _a.setCollageDataForSave, handleLoadEstimates = _a.handleLoadEstimates, catalog = _a.catalog, props = __rest(_a, ["furniture", "isCollage", "handleSyncCollage", "handleEditFurniture", "setChangingFurnitureId", "isLoadingCollage", "setIsLoadingCollage", "collageActions", "state", "estimatesData", "setCollageDataForSave", "handleLoadEstimates", "catalog"]);
    console.log("Estimates");
    var _state = Project.unselectAll(state).updatedState;
    var stateScene = _state.get("scene").toJS();
    var layer = stateScene.layers["layer-1"];
    var rooms = Object.keys(layer.areas).map(function (el, i) { return ({
        hash: Object.keys(layer.areas)[i],
        name: areas[layer.areas[el].properties.areaType],
    }); });
    var roomsArray = rooms.map(function (el, i) { return ({
        value: String(el.name),
        label: String(el.name),
        id: String(el.hash),
    }); });
    var _d = __read(useState(((_b = rooms[0]) === null || _b === void 0 ? void 0 : _b.hash) || []), 2), curentRoom = _d[0], setCurentRoom = _d[1];
    var _furniture = useMemo(function () {
        if (estimatesData) {
            var furniture_1 = Object.keys(estimatesData).map(function (el) {
                return __assign(__assign({}, estimatesData[el]), { item_idx: el });
            });
            if (curentRoom === "all")
                return furniture_1;
            return furniture_1.filter(function (el) { return el.area_idx === curentRoom; });
        }
        else {
            return [];
        }
    }, [estimatesData, curentRoom]);
    if (!isCollage && roomsArray.length > 0)
        roomsArray.push({
            value: "Итого",
            label: "Итого",
            id: "all",
        });
    return (React.createElement(EstimatesStyled, { "$isCollage": isCollage },
        React.createElement(Selector, null,
            React.createElement(SelectBox, { options: roomsArray, onValueChange: function (o) {
                    setCurentRoom(o.id);
                }, hasBorder: true, customFontsizeTitle: "18px", selectedValue: ((_c = roomsArray[0]) === null || _c === void 0 ? void 0 : _c.value) || "", customWidth: "200px" })),
        isCollage && (React.createElement(Collage, { isFullWindow: false, setChangingFurnitureId: setChangingFurnitureId, isLoadingCollage: isLoadingCollage, setIsLoadingCollage: setIsLoadingCollage, collageActions: collageActions, isLoadingMeta: props.isLoadingMeta, state: state, setCollageDataForSave: setCollageDataForSave })),
        React.createElement(EstimatesTable, { handleEditFurniture: handleEditFurniture, furniture: _furniture, isCollage: false, setChangingFurnitureId: setChangingFurnitureId, handleLoadEstimates: handleLoadEstimates, catalog: catalog, state: state })));
});
