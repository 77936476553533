import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropertyLengthMeasure from '../../../../catalog/properties/property-lenght-measure';
import PropertyString from '../../../../catalog/properties/property-string';
import { CommonButton } from 'src/ui-kit/button';
import { CommonText } from 'src/ui-kit/text';

export default function HoleAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {
  let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let offsetA = attributeFormData.has('offsetA') ? attributeFormData.get('offsetA') : element.offsetA;
  let offsetB = attributeFormData.has('offsetB') ? attributeFormData.get('offsetB') : element.offsetA;


  const handleOnDelete = () => {
    const escEvent = new KeyboardEvent('keydown', {
      key: 'Delete',
      keyCode: 46, // Устарело, но всё ещё используется в некоторых браузерах
      code: 'Delete',
      which: 46,  // Устаревшее свойство, но добавлено для поддержки старых браузеров
      bubbles: true, // Событие будет всплывать
      cancelable: true
    });

    // Отправляем событие в документ
    document.dispatchEvent(escEvent);
  }

  return <div>
        
          <CommonButton onClick={handleOnDelete} >
         <CommonText color="red" text="Удалить" />
        
        </CommonButton>
      
    <PropertyString
      value={name}
      onUpdate={mapped => onUpdate('name', mapped)}
      configs={{label: 'Название'}}
      state={state}
      {...rest}
    />
    <PropertyLengthMeasure
      value={offsetA}
      onUpdate={mapped => onUpdate('offsetA', mapped)}
      configs={{label: 'Отступ от стены', min: 0, max: Infinity, precision: 2}}
      state={state}
      {...rest}
    />
    <PropertyLengthMeasure
      value={offsetB}
      onUpdate={mapped => onUpdate('offsetB', mapped)}
      configs={{label: 'Отступ от стены', min: 0, max: Infinity, precision: 2}}
      state={state}
      {...rest}
    />
  </div>;
}

HoleAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

HoleAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
