import {
  SET_COLLAGE_DATA,
  CHANGE_FURNITURE,
  SET_FURNITURE_POSITION,
} from "../constants";
//import { Vertex } from '../class/export';

export default function (state, action) {
  // console.log(11111);
  // console.log(state.collage);
  //console.log(action);

  switch (action.type) {
    case SET_COLLAGE_DATA:
      return state.set("collage", {
        ...state.collage,
        data: {
          ...action.data,
          furniture: [
            ...action.data.furniture.map((e) => {
              e.userX = e.userX || 0;
              e.userY = e.userY || 0;
              return e;
            }),
          ],
        },
      });

    case CHANGE_FURNITURE:
      return state.set("collage", {
        ...state.collage,
        data: {
          ...state.collage.data,
          furniture: [
            ...state.collage.data.furniture.map((e) =>
              e.id === action.newFurniture.id
                ? {
                    ...action.newFurniture.newFurniture,
                    y: e.y,
                    x: e.x,
                    userX: e.userX,
                    userY: e.userY,
                  }
                : { ...e }
            ),
          ],
        },
      });

    case SET_FURNITURE_POSITION:
      return state.set("collage", {
        ...state.collage,
        data: {
          ...state.collage.data,
          furniture: [
            ...state.collage.data.furniture.map((e) =>
              e.id === action.data.id
                ? {
                    ...e,
                    userX: action.data.userX,
                    userY: action.data.userY,
                  }
                : { ...e }
            ),
          ],
        },
      });

    // case SET_COLLAGE_DATA:
    //   const modifedAreas = {};
    //   Object.keys(action.areas).forEach((el) => {
    //     modifedAreas[el] = {
    //       ...action.areas[el],
    //       furniture: action.areas[el].furniture.map((furnItem) => {
    //         furnItem.userX = furnItem.userX || 0;
    //         furnItem.userY = furnItem.userY || 0;
    //         return furnItem;
    //       }),
    //     };
    //   });
    //   return state.set("collage", {
    //     ...state.collage,
    //     areas: { ...modifedAreas },
    //   });

    // case CHANGE_FURNITURE:
    //   return state.set("collage", {
    //     ...state.collage,
    //     areas: {
    //       ...state.collage.areas,
    //       [action.data.areaId]: {
    //         ...state.collage.areas[action.data.areaId],
    //         furniture: [
    //           ...state.collage.areas[action.data.areaId].furniture.map((e) =>
    //             e.id === action.data.newFurnitureId
    //               ? {
    //                   ...action.data.newFurniture,
    //                   y: e.y,
    //                   x: e.x,
    //                   userX: e.userX,
    //                   userY: e.userY,
    //                 }
    //               : { ...e }
    //           ),
    //         ],
    //       },
    //     },
    //   });

    // case SET_FURNITURE_POSITION:
    //   return state.set("collage", {
    //     ...state.collage,
    //     areas: {
    //       ...state.collage.areas,
    //       [action.data.areaId]: {
    //         ...state.collage.areas[action.data.areaId],
    //         furniture: [
    //           ...state.collage.data.furniture.map((e) =>
    //             e.id === action.data.furnitureId
    //               ? {
    //                   ...e,
    //                   userX: action.data.userX,
    //                   userY: action.data.userY,
    //                 }
    //               : { ...e }
    //           ),
    //         ],
    //       },
    //     },
    //   });

    default:
      return state;
  }
}
