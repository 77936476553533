var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var CardsBlock = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    overflow: auto;\n    scrollbar-width: none;\n    height: 60vh;\n    box-sizing: border-box;\n    ::-webkit-scrollbar {\n      display: none;\n    }\n  }\n"], ["\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  @media (max-width: 480px) {\n    overflow: auto;\n    scrollbar-width: none;\n    height: 60vh;\n    box-sizing: border-box;\n    ::-webkit-scrollbar {\n      display: none;\n    }\n  }\n"])));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  & > img {\n    width: 45%;\n  }\n  & > div {\n    width: 45%;\n  }\n  & > span {\n    width: 10%;\n    text-align: center;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  & > img {\n    width: 45%;\n  }\n  & > div {\n    width: 45%;\n  }\n  & > span {\n    width: 10%;\n    text-align: center;\n  }\n"])));
export var ButtonBlock = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  height: min-content;\n  margin-top: 10px;\n"], ["\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  height: min-content;\n  margin-top: 10px;\n"])));
export var StyledCheckbox = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 28.33px;\n  width: auto;\n  /* transition: background-color 0.2s; */\n  cursor: pointer;\n  /* &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  } */\n"], ["\n  height: 28.33px;\n  width: auto;\n  /* transition: background-color 0.2s; */\n  cursor: pointer;\n  /* &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  } */\n"])));
export var StyledImg = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-image: ", ";\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  height: 150px;\n  width: 250px;\n  border-radius: 10px;\n"], ["\n  background-image: ", ";\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  height: 150px;\n  width: 250px;\n  border-radius: 10px;\n"])), function (_a) {
    var $url = _a.$url;
    return ($url ? " url(".concat($url, ")") : "");
});
export var CardName = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 20px;\n  line-height: 27.32px;\n"], ["\n  font-size: 20px;\n  line-height: 27.32px;\n"])));
export var Tabs = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  margin-bottom: 20px;\n"], ["\n  display: flex;\n  gap: 20px;\n  margin-bottom: 20px;\n"])));
export var Tab = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 20px;\n  border-bottom: ", ";\n  color: ", ";\n"], ["\n  font-size: 20px;\n  border-bottom: ", ";\n  color: ", ";\n"])), function (_a) {
    var $isSelect = _a.$isSelect;
    return $isSelect ? "2px solid #000" : "0.5px solid #00000050";
}, function (_a) {
    var $isSelect = _a.$isSelect;
    return ($isSelect ? " #000" : " #00000050");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
