var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useCallback, useMemo, useState } from "react";
import { SelectBox } from "src/ui-kit/select-box";
import { localStorageWorker } from "src/utils/local-storage-worker";
import { LOCAL_STRORAGE_KEYS } from "src/constants";
import { Modal } from "src/ui-kit/modal";
import { InputCustom } from "src/ui-kit/Input";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { ModalContent, ButtonContainer } from "./styled";
import { BALANCER } from "src/api/_constants";
var addLayoutsButtonValue = "addButton";
export var Layouts = memo(function Layouts(_a) {
    var layoutsData = _a.layoutsData, handleRefresh = _a.handleRefresh, handleAddLyaouts = _a.handleAddLyaouts, hardClear = _a.hardClear, handleFalseHardClear = _a.handleFalseHardClear, roomTypes = _a.roomTypes, handleOpenScene = _a.handleOpenScene;
    var _b = __read(useState(localStorageWorker.get({
        key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_NAME,
    }) || ""), 2), selectedValue = _b[0], setSelectedValue = _b[1];
    var _c = __read(useState(false), 2), isOpenAddModal = _c[0], setIsOpenAddModal = _c[1];
    var _d = __read(useState(""), 2), newLayoutName = _d[0], setNewLayoutName = _d[1];
    var _e = __read(useState(""), 2), newSelectedRoomType = _e[0], setNewSelectedRoomType = _e[1];
    // const [selectedValue, setSelectedValue] = useState('')
    var handleChangeInputValue = function (value) {
        setNewLayoutName(value);
    };
    var handleCloseModal = useCallback(function () {
        handleClearNameLayouts();
        setIsOpenAddModal(false);
    }, []);
    var handleOpenModal = useCallback(function () {
        setIsOpenAddModal(true);
    }, []);
    var getSelectOptions = useMemo(function () {
        var projects = layoutsData.map(function (example) {
            // console.log(example)
            // is_ml_generated
            var isProgress = example.data.is_ml_generated === false;
            return {
                id: "".concat(example.id),
                label: "".concat(example.name),
                value: "".concat(example.id),
                data: example.data,
                isDisabled: isProgress,
            };
        });
        projects.push({
            id: addLayoutsButtonValue,
            label: "Добавить новый вариант",
            value: addLayoutsButtonValue,
            isOnlyButton: true,
        });
        return projects;
    }, [layoutsData]);
    var getSelectRoomTypesOptions = useMemo(function () {
        var projects = roomTypes.map(function (example) {
            return {
                id: "".concat(example.literal),
                label: "".concat(example.name_rus),
                value: "".concat(example.literal),
                image: "".concat(BALANCER).concat(example.icon_src),
            };
        });
        return projects;
    }, [roomTypes]);
    var handleChangeSelecterdLayouts = function (value) {
        var triggerCloseCatalog = new CustomEvent("triggerCloseCatalog");
        window.dispatchEvent(triggerCloseCatalog);
        handleOpenScene();
        var layoutId = value.id, layoutName = value.label, data = value.data;
        if (layoutId === addLayoutsButtonValue) {
            handleOpenModal();
            return;
        }
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_ID,
            value: layoutId,
        });
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_NAME,
            value: layoutName,
        });
        setSelectedValue(layoutName);
        // console.log(data)
        handleRefresh(data);
    };
    var handleChangeSelecterdRoomTypes = function (value) {
        console.log(value);
        setNewSelectedRoomType(value.id);
    };
    var handleClearNameLayouts = function () {
        setNewLayoutName("");
    };
    var handleOnAddClick = function () {
        var callbackOnSuccess = function (_a) {
            var data = _a.data;
            handleFalseHardClear();
            handleCloseModal();
            // handleRefresh();
            localStorageWorker.set({
                key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_NAME,
                value: newLayoutName,
            });
            localStorageWorker.set({
                key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_ID,
                value: data.id,
            });
            setSelectedValue(newLayoutName);
        };
        handleAddLyaouts({
            name: newLayoutName,
            layout_area_temp: newSelectedRoomType,
            callbackOnSuccess: callbackOnSuccess,
        });
        handleClearNameLayouts();
    };
    var canSave = useMemo(function () { return Boolean(newLayoutName); }, [newLayoutName]);
    return (React.createElement("div", { style: { display: "flex", alignItems: "center" } },
        React.createElement(CommonText, { text: "\u0412\u0430\u0440\u0438\u0430\u043D\u0442: " }),
        React.createElement(SelectBox, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0432\u0430\u0440\u0438\u0430\u043D\u0442", onValueChange: handleChangeSelecterdLayouts, options: getSelectOptions, hardClear: hardClear, selectedValue: selectedValue, handleFalseHardClear: handleFalseHardClear }),
        React.createElement(Modal, { isOpen: isOpenAddModal, onClose: handleCloseModal },
            React.createElement(ModalContent, null,
                React.createElement("div", { style: { textAlign: "left", height: "95%" } },
                    React.createElement(CommonText, { text: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u0430" }),
                    React.createElement(InputCustom, { value: newLayoutName, onChange: handleChangeInputValue })),
                React.createElement(ButtonContainer, null,
                    React.createElement(CommonButton, { isDisabled: !canSave, onClick: handleOnAddClick },
                        React.createElement(CommonText, { text: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" })),
                    React.createElement(CommonButton, { onClick: handleCloseModal },
                        React.createElement(CommonText, { text: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" })))))));
});
