import ReactPlannerAreasReducer from "./areas-reducer";
import ReactPlannerHolesReducer from "./holes-reducer";
import ReactPlannerItemsReducer from "./items-reducer";
import ReactPlannerLinesReducer from "./lines-reducer";
import ReactPlannerGroupsReducer from "./groups-reducer";
import ReactPlannerProjectReducer from "./project-reducer";
import ReactPlannerSceneReducer from "./scene-reducer";
import ReactPlannerVerticesReducer from "./vertices-reducer";
import ReactPlannerViewer2dReducer from "./viewer2d-reducer";
import ReactPlannerViewer3dReducer from "./viewer3d-reducer";
import ReactIsLoadingReducer from "./is-loading";
import ReactCollageReducer from "./collage-reducer";

export {
  ReactPlannerAreasReducer,
  ReactPlannerHolesReducer,
  ReactPlannerItemsReducer,
  ReactPlannerLinesReducer,
  ReactPlannerGroupsReducer,
  ReactPlannerProjectReducer,
  ReactPlannerSceneReducer,
  ReactPlannerVerticesReducer,
  ReactPlannerViewer2dReducer,
  ReactPlannerViewer3dReducer,
  ReactIsLoadingReducer,
  ReactCollageReducer,
};

export default {
  ReactPlannerAreasReducer,
  ReactPlannerHolesReducer,
  ReactPlannerItemsReducer,
  ReactPlannerLinesReducer,
  ReactPlannerGroupsReducer,
  ReactPlannerProjectReducer,
  ReactPlannerSceneReducer,
  ReactPlannerVerticesReducer,
  ReactPlannerViewer2dReducer,
  ReactPlannerViewer3dReducer,
  ReactIsLoadingReducer,
  ReactCollageReducer,
};
