var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef } from "react";
import { PrintedContainer, SceneConainer } from "./styled";
import { useReactToPrint } from "react-to-print";
import { Content } from "src/components/export";
export var PrintedComponent = function (props //,
// ref: React.ForwardedRef<HTMLDivElement>
) {
    console.log("PrintedCmoponent");
    var furniture = props.furniture, isPrinting = props.isPrinting, isLoadingCollage = props.isLoadingCollage, setIsLoadingCollage = props.setIsLoadingCollage, collageActions = props.collageActions, collageInfo = props.collageInfo, setIsPrinting = props.setIsPrinting, width = props.width, height = props.height, state = props.state, rest = __rest(props, ["furniture", "isPrinting", "isLoadingCollage", "setIsLoadingCollage", "collageActions", "collageInfo", "setIsPrinting", "width", "height", "state"]);
    // console.log("estimatesData");
    // console.log(estimatesData);
    var refPrintedComponent = useRef();
    var runPrint = useReactToPrint({
        content: function () { return refPrintedComponent.current; },
        onAfterPrint: function () { return setIsPrinting(false); },
    });
    useEffect(function () {
        if (isPrinting && furniture.length > 0) {
            setTimeout(function () {
                console.log("runPrint");
                runPrint();
            }, 1);
        }
    }, [isPrinting, runPrint, furniture]);
    return (React.createElement(PrintedContainer, null,
        React.createElement("div", { ref: refPrintedComponent },
            isPrinting && (React.createElement(SceneConainer, null,
                React.createElement(Content, __assign({ width: 1700, height: height, state: state }, rest, { onWheel: function (event) { return event.preventDefault(); } })))),
            React.createElement("div", null))));
};
