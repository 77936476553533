export var enumFurnitureTypes = function (_a) {
    var roomTypes = _a.roomTypes, furnitureTypes = _a.furnitureTypes;
    var MOCK = [
        {
            literal: "KITCHEN",
            name_rus: "Кухня",
            name_eng: "Kitchen",
            icon_src: "/images/room_types/kitchen_icon.svg",
            furniture: ["TABLE", "CHAIR", "SHELF", "CABINET_AND_DRESSER"]
        },
        {
            literal: "LIVING_ROOM",
            name_rus: "Гостиная",
            name_eng: "Living Room",
            icon_src: "/images/room_types/living_room_icon.svg",
            furniture: ["COUCH", "TV_STAND", "COFFEE_TABLE", "ARMCHAIR", "SHELF", "OTTOMAN", "TV"]
        },
        {
            literal: "BATHROOM",
            name_rus: "Ванна",
            name_eng: "Bathroom",
            icon_src: "/images/room_types/bathroom_icon.svg",
            furniture: ["SHELF", "CABINET_AND_DRESSER", "HANGER"]
        },
        {
            literal: "BEDROOM",
            name_rus: "Спальня",
            name_eng: "Bedroom",
            icon_src: "/images/room_types/bedroom_icon.svg",
            furniture: ["BED", "CLOSET", "NIGHTSTAND", "DRESSING_TABLE", "WARDROBE", "SHELF"]
        },
        {
            literal: "CHILDRENS_ROOM",
            name_rus: "Детская комната",
            name_eng: "Children's Room",
            icon_src: "/images/room_types/childrens_room_icon.svg",
            furniture: ["BED", "CLOSET", "TABLE", "CHAIR", "SHELF", "WARDROBE", "WORKING_SPACE"]
        },
        {
            literal: "STUDY_ROOM",
            name_rus: "Кабинет",
            name_eng: "Study Room",
            icon_src: "/images/room_types/study_room_icon.svg",
            furniture: ["TABLE", "CHAIR", "SHELF", "WORKING_SPACE", "ARMCHAIR"]
        },
        {
            literal: "TOILET",
            name_rus: "Туалет",
            name_eng: "Toilet",
            icon_src: "/images/room_types/toilet_icon.svg",
            furniture: ["SHELF", "HANGER"]
        }
    ];
    return MOCK;
    // const roomTypesWithFurniture = roomTypes.map((types) => {
    //     furnitureTypes.
    //     return types
    // })
    // return roomTypesWithFurniture
};
