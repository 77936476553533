var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var QuestionaryContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #ffffff;\n  border-radius: 30px;\n  // box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);\n  padding: 80px 60px 30px 60px;\n  width: 80vw;\n  height: 80vh;\n  /* display: flex;\n  flex-direction: column;\n  align-items: center; */\n  position: relative;\n  /* justify-content: space-between; */\n"], ["\n  background-color: #ffffff;\n  border-radius: 30px;\n  // box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);\n  padding: 80px 60px 30px 60px;\n  width: 80vw;\n  height: 80vh;\n  /* display: flex;\n  flex-direction: column;\n  align-items: center; */\n  position: relative;\n  /* justify-content: space-between; */\n"])));
export var QuestionaryTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  width: 100%;\n"], ["\n  text-align: center;\n  width: 100%;\n"])));
export var BlockTitle = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 22px;\n  font-weight: 500;\n  line-height: 30.05px;\n  margin-bottom: 16px;\n"], ["\n  font-size: 22px;\n  font-weight: 500;\n  line-height: 30.05px;\n  margin-bottom: 16px;\n"])));
export var Label = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 21.86px;\n  margin-bottom: 7px;\n"], ["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 21.86px;\n  margin-bottom: 7px;\n"])));
export var PageNumber = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #8dc646;\n"], ["\n  color: #8dc646;\n"])));
export var ChildrenBlock = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  gap: 30px;\n  align-items: flex-start;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  gap: 30px;\n  align-items: flex-start;\n  justify-content: flex-start;\n"])));
export var ChildrenAgeBlock = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n"])));
export var PriceSegment = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n"])));
export var CloseButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 21.94px;\n  color: #8dc646;\n  cursor: pointer;\n  position: absolute;\n  top: 60px;\n  right: 40px;\n"], ["\n  background: none;\n  border: none;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 21.94px;\n  color: #8dc646;\n  cursor: pointer;\n  position: absolute;\n  top: 60px;\n  right: 40px;\n"])));
export var StyledButton = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 11px 20px;\n  border: ", ";\n  border-radius: 10px;\n  font-size: 18px;\n  line-height: 24.59px;\n"], ["\n  padding: 11px 20px;\n  border: ", ";\n  border-radius: 10px;\n  font-size: 18px;\n  line-height: 24.59px;\n"])), function (_a) {
    var $isSelect = _a.$isSelect;
    return $isSelect ? "1px solid #8DC646" : "0.5px solid #C4C4C4";
});
export var BottomButtonBlock = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: none;\n  border: none;\n\n  display: flex;\n  gap: 20px;\n"], ["\n  background: none;\n  border: none;\n\n  display: flex;\n  gap: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
