var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from "react";
import { CardsBlock, Container, ButtonBlock, StyledCheckbox, CardName, StyledImg,
// Tabs,
// Tab,
 } from "./styled";
import { BlockTitle } from "../../styled";
import Card from "src/ui-kit/card/card";
import { CommonButton } from "src/ui-kit/button";
import { ChangeRoomMenu } from "../../changeRoomMenu/changeRoomMenu";
import light from "src/assets/img/room-colors/light.png";
import dark from "src/assets/img/room-colors/dark.png";
import cold from "src/assets/img/room-colors/cold.png";
import warm from "src/assets/img/room-colors/warm.png";
import bright from "src/assets/img/room-colors/bright.png";
import pastel from "src/assets/img/room-colors/pastel.png";
export var RoomColorInfo = function (_a) {
    var roomColor = _a.roomColor, setRoomColor = _a.setRoomColor, roomTab = _a.roomTab, setRoomTab = _a.setRoomTab, width = _a.width, rooms = _a.rooms, roomsData = _a.roomsData, setRoomsData = _a.setRoomsData;
    var cardWidth = useMemo(function () { return (width > 480 ? "40%" : "100%"); }, [width]);
    var handleChangeRoomMenuTabClick = function (index) {
        setRoomsData(function (p) {
            return p.map(function (el, i) {
                if (i === index) {
                    return __assign(__assign({}, el), { isCurrent: true });
                }
                else {
                    return __assign(__assign({}, el), { isCurrent: false });
                }
            });
        });
    };
    var handleSetIsLight = function (colorStyle) {
        setRoomsData(function (p) {
            return p.map(function (el, i) {
                if (el.isCurrent) {
                    return __assign(__assign({}, el), { isLight: colorStyle });
                }
                else {
                    return __assign({}, el);
                }
            });
        });
    };
    var handleSetIsWarm = function (colorStyle) {
        setRoomsData(function (p) {
            return p.map(function (el, i) {
                if (el.isCurrent) {
                    return __assign(__assign({}, el), { isWarm: colorStyle });
                }
                else {
                    return __assign({}, el);
                }
            });
        });
    };
    var handleSetIsPastel = function (colorStyle) {
        setRoomsData(function (p) {
            return p.map(function (el, i) {
                if (el.isCurrent) {
                    return __assign(__assign({}, el), { isPastel: colorStyle });
                }
                else {
                    return __assign({}, el);
                }
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(BlockTitle, null, "\u0426\u0432\u0435\u0442\u043E\u0432\u0430\u044F \u0433\u0430\u043C\u043C\u0430 \u043A\u043E\u043C\u043D\u0430\u0442\u044B:"),
        React.createElement(ChangeRoomMenu, { roomsData: roomsData, setRoomTab: handleChangeRoomMenuTabClick, arr: rooms }),
        React.createElement(CardsBlock, null,
            React.createElement(Card, { isActive: false, width: cardWidth },
                React.createElement(Container, null,
                    React.createElement(StyledImg, { "$url": light }),
                    React.createElement("span", null, "\u0438\u043B\u0438"),
                    React.createElement(StyledImg, { "$url": dark })),
                React.createElement(Container, null,
                    React.createElement(ButtonBlock, null,
                        React.createElement(CommonButton, { onClick: function () {
                                handleSetIsLight(true);
                            } },
                            React.createElement(StyledCheckbox, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].isLight
                                    ? require("src/assets/img/round-check-on.png")
                                    : require("src/assets/img/round-check-off.png"), alt: "" })),
                        React.createElement(CardName, null, "\u0421\u0432\u0435\u0442\u043B\u0430\u044F")),
                    React.createElement("span", null),
                    React.createElement(ButtonBlock, null,
                        React.createElement(CommonButton, { onClick: function () {
                                handleSetIsLight(false);
                            } },
                            React.createElement(StyledCheckbox, { src: !roomsData.filter(function (el) { return el.isCurrent; })[0].isLight
                                    ? require("src/assets/img/round-check-on.png")
                                    : require("src/assets/img/round-check-off.png"), alt: "" })),
                        React.createElement(CardName, null, "\u0422\u0435\u043C\u043D\u0430\u044F")))),
            React.createElement(Card, { isActive: false, width: cardWidth },
                React.createElement(Container, null,
                    React.createElement(StyledImg, { "$url": cold }),
                    React.createElement("span", null, "\u0438\u043B\u0438"),
                    React.createElement(StyledImg, { "$url": warm })),
                React.createElement(Container, null,
                    React.createElement(ButtonBlock, null,
                        React.createElement(CommonButton, { onClick: function () {
                                handleSetIsWarm(false);
                            } },
                            React.createElement(StyledCheckbox, { src: !roomsData.filter(function (el) { return el.isCurrent; })[0].isWarm
                                    ? require("src/assets/img/round-check-on.png")
                                    : require("src/assets/img/round-check-off.png"), alt: "" })),
                        React.createElement(CardName, null, "\u0425\u043E\u043B\u043E\u0434\u043D\u0430\u044F")),
                    React.createElement("span", null),
                    React.createElement(ButtonBlock, null,
                        React.createElement(CommonButton, { onClick: function () {
                                handleSetIsWarm(true);
                            } },
                            React.createElement(StyledCheckbox, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].isWarm
                                    ? require("src/assets/img/round-check-on.png")
                                    : require("src/assets/img/round-check-off.png"), alt: "" })),
                        React.createElement(CardName, null, "\u0422\u0435\u043F\u043B\u0430\u044F")))),
            React.createElement(Card, { isActive: false, width: cardWidth },
                React.createElement(Container, null,
                    React.createElement(StyledImg, { "$url": bright }),
                    React.createElement("span", null, "\u0438\u043B\u0438"),
                    React.createElement(StyledImg, { "$url": pastel })),
                React.createElement(Container, null,
                    React.createElement(ButtonBlock, null,
                        React.createElement(CommonButton, { onClick: function () {
                                handleSetIsPastel(false);
                            } },
                            React.createElement(StyledCheckbox, { src: !roomsData.filter(function (el) { return el.isCurrent; })[0].isPastel
                                    ? require("src/assets/img/round-check-on.png")
                                    : require("src/assets/img/round-check-off.png"), alt: "" })),
                        React.createElement(CardName, null, "\u042F\u0440\u043A\u0430\u044F")),
                    React.createElement("span", null),
                    React.createElement(ButtonBlock, null,
                        React.createElement(CommonButton, { onClick: function () {
                                handleSetIsPastel(true);
                            } },
                            React.createElement(StyledCheckbox, { src: roomsData.filter(function (el) { return el.isCurrent; })[0].isPastel
                                    ? require("src/assets/img/round-check-on.png")
                                    : require("src/assets/img/round-check-off.png"), alt: "" })),
                        React.createElement(CardName, null, "\u041F\u0430\u0441\u0442\u0435\u043B\u044C\u043D\u0430\u044F")))))));
};
