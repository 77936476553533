import { fetchPostRequests } from "src/api/fetch/post";
import { getProjectInfo } from '../../fetch/get-project-info';
export var postAddLayoutsRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data;
    var projectId = getProjectInfo().projectId;
    fetchPostRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: "https://demo.platformvim.org/api/v2/layouts?project_id=".concat(projectId),
        data: data,
    });
};
