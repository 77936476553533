var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useCallback, useState } from "react";
// import { ArrowIcon } from "src/components/Client/connected/_components/icons";
// import { useTranslation } from "react-i18next";
import { MenuContainer, MenuLeft, MenuCenter, StyledImg, Title, MenuRight, } from "./styled";
import { CommonButton } from "src/ui-kit/button";
import { Examples } from "./components/examples";
import { Projects } from "./components/projects";
import { Admin } from "./components/admin";
import { CommonText } from "src/ui-kit/text";
import { Layouts } from "./components/layouts";
import { PlanRecorgnition } from './components/plan-recorgnition';
export var TopMenu = memo(function TopMenu(_a) {
    var handleLoadRooms = _a.handleLoadRooms, hadnleOpenRecorgnition = _a.hadnleOpenRecorgnition, hadnleCloseRecorgnition = _a.hadnleCloseRecorgnition, handleSaveProject = _a.handleSaveProject, 
    //handleGenerateProjectFromBuild,
    onSelectExample = _a.onSelectExample, isLoadingPlanRecognition = _a.isLoadingPlanRecognition, isOpenRecorgnition = _a.isOpenRecorgnition, 
    // handleRefresh,
    _b = _a.allProjects, 
    // handleRefresh,
    allProjects = _b === void 0 ? [] : _b, _c = _a.allLayouts, allLayouts = _c === void 0 ? [] : _c, isLoadingRooms = _a.isLoadingRooms, isLoadingSaveRooms = _a.isLoadingSaveRooms, isLoadingGenerateProject = _a.isLoadingGenerateProject, 
    // isCanGenerateProject,
    // isCanSaveProject,
    // handleGenerateAuto,
    // isCanLoadProject,
    _d = _a.examplesData, 
    // isCanGenerateProject,
    // isCanSaveProject,
    // handleGenerateAuto,
    // isCanLoadProject,
    examplesData = _d === void 0 ? [] : _d, handleAddProject = _a.handleAddProject, handleAddLyaouts = _a.handleAddLyaouts, handleAddUser = _a.handleAddUser, handleGetUsers = _a.handleGetUsers, 
    // handleGetProject,
    currentUsers = _a.currentUsers, handleRefreshLayouts = _a.handleRefreshLayouts, handleGetAllProjects = _a.handleGetAllProjects, getProjectById = _a.getProjectById, handleUpdateProjectById = _a.handleUpdateProjectById, isLoadingAdminAction = _a.isLoadingAdminAction, handleSelectLayout = _a.handleSelectLayout, hardClear = _a.hardClear, handleFalseHardClear = _a.handleFalseHardClear, roomTypes = _a.roomTypes, handlePlanRecorginition = _a.handlePlanRecorginition, handleOpenQuiz = _a.handleOpenQuiz, handleOpenScene = _a.handleOpenScene;
    var _e = __read(useState(false), 2), isOpenAdminModal = _e[0], setIsOpenAdminModal = _e[1];
    var handleCloseModalAdmin = function () {
        setIsOpenAdminModal(false);
    };
    var handleOpenModalAdmin = function () {
        setIsOpenAdminModal(true);
    };
    var handleAddUsserCallback = function (_a) {
        var name = _a.name, password = _a.password, callbackOnSuccess = _a.callbackOnSuccess;
        handleAddUser({
            name: name,
            password: password,
            callbackOnSuccess: callbackOnSuccess,
        });
    };
    // const { t, i18n } = useTranslation();
    // const toggleLanguage = () => {
    //   const nextLanguage = i18n.language === "en" ? "ru" : "en";
    //   i18n.changeLanguage(nextLanguage);
    // };
    // console.log(allProjects)
    var handleOnSelectChange = useCallback(function (value) {
        var findRoom = examplesData.find(function (example) { return example.id === value.id; });
        if (findRoom === null || findRoom === void 0 ? void 0 : findRoom.room) {
            onSelectExample(findRoom.room);
        }
    }, [examplesData, onSelectExample]);
    var isDisplayExamples = Boolean(examplesData.length);
    var onFileImport = function (file) {
        handlePlanRecorginition(file);
        // console.log(file)
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuContainer, null,
            React.createElement(MenuLeft, null,
                React.createElement(CommonButton, { onClick: handleSaveProject, isLoading: isLoadingSaveRooms },
                    React.createElement(StyledImg, { src: require("src/assets/img/Save.png"), alt: "Save" })),
                React.createElement(CommonButton, { onClick: hadnleOpenRecorgnition },
                    React.createElement(CommonText, { text: "\u0420\u0430\u0441\u043F\u043E\u0437\u043D\u0430\u0439\u043A\u0430" }))),
            React.createElement(MenuCenter, null,
                React.createElement(Title, null,
                    React.createElement(Projects, { projectsData: allProjects, handleRefresh: handleRefreshLayouts, handleAddProject: handleAddProject, handleFalseHardClear: handleFalseHardClear, handleOpenScene: handleOpenScene }))),
            React.createElement(MenuCenter, null,
                React.createElement(Title, null,
                    React.createElement(Layouts, { layoutsData: allLayouts, handleRefresh: handleSelectLayout, handleAddLyaouts: handleAddLyaouts, hardClear: hardClear, handleFalseHardClear: handleFalseHardClear, roomTypes: roomTypes, handleOpenScene: handleOpenScene }))),
            React.createElement(MenuRight, null,
                isDisplayExamples && (React.createElement(Examples, { examplesData: examplesData, onSelectExample: handleOnSelectChange })),
                React.createElement(Admin, { handleCloseModalAdmin: handleCloseModalAdmin, isOpenAdminModal: isOpenAdminModal, handleAddUser: handleAddUsserCallback, handleGetUsers: handleGetUsers, 
                    // handleGetProject={handleGetProject}
                    currentUsers: currentUsers, handleGetAllProjects: handleGetAllProjects, getProjectById: getProjectById, allProjects: allProjects, handleUpdateProjectById: handleUpdateProjectById, isLoadingAdminAction: isLoadingAdminAction }))),
        React.createElement(PlanRecorgnition, { isLoadingPlanRecognition: isLoadingPlanRecognition, onClose: hadnleCloseRecorgnition, isOpen: isOpenRecorgnition, onFileImport: onFileImport })));
});
