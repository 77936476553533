import React from "react";
import { Container, ImageBlock } from "./styled";
export var CheckboxMF = function (_a) {
    var isMale = _a.isMale, handleOnClick = _a.handleOnClick;
    return (React.createElement(Container, { onClick: handleOnClick }, isMale ? (React.createElement(ImageBlock, null,
        React.createElement("img", { src: require("src/assets/img/m-on.png") }),
        React.createElement("img", { src: require("src/assets/img/f-off.png") }))) : (React.createElement(ImageBlock, null,
        React.createElement("img", { src: require("src/assets/img/m-off.png") }),
        React.createElement("img", { src: require("src/assets/img/f-on.png") })))));
};
