var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { memo, useCallback, useState, useEffect } from "react";
import { SubMenuStyled, SubMenuImagesBlock, SubMenuTitle, SubMenuTitleName, SubMenuInput, SubMenuInputsBlock, SubMeniDropdow, SubMenuArrow, SubMenuBody, SubMeniDropdowImg, SubMenuButtonsBlock, SubMenuCustomButton, SubMenuCheckboxBlock, } from "./styled";
import { InputCustom } from "src/ui-kit/Input";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { Checkbox } from 'src/ui-kit/checkbox';
import { RecursiveImageMenu } from "./_components/recursiveImageMenu";
export var SubMenu = memo(function SubMenu(_a) {
    var _b;
    var menuItem = _a.menuItem, onClose = _a.onClose, handleBuildModeStop = _a.handleBuildModeStop, handleBuildModeStart = _a.handleBuildModeStart, handleSelectItem = _a.handleSelectItem, handleHoleItem = _a.handleHoleItem, setActiveMenuType = _a.setActiveMenuType;
    var _c = __read(useState([]), 2), openedType = _c[0], setOpenedType = _c[1];
    var _d = __read(useState(''), 2), selectedButtonClick = _d[0], setSelectedButtonClick = _d[1];
    var onBuildModeClick = function (_a) {
        var onClick = _a.onClick, buttonTitle = _a.buttonTitle;
        return function () {
            onClick();
            setSelectedButtonClick(buttonTitle);
        };
    };
    var getIsDisabled = useCallback(function (_a) {
        var buttonTitle = _a.buttonTitle, buttonDisabled = _a.buttonDisabled;
        return !selectedButtonClick ? buttonDisabled : selectedButtonClick === buttonTitle;
    }, [selectedButtonClick]);
    var _e = __read(useState(""), 2), selectedImage = _e[0], setSelectedImage = _e[1];
    var handleSelectImage = useCallback(function (_a) {
        // console.log(name);
        // console.log(menuItem)
        // setSelectedImage(name);
        // callback(menuItem.items);
        var name = _a.name, type = _a.type;
        if (type === 'window') {
            handleHoleItem("window-".concat(name));
        }
        if (type === 'door') {
            handleHoleItem("door-".concat(name));
        }
        if (type === "item") {
            handleSelectItem(name);
        }
    }, []);
    var handleOnClose = useCallback(function (type) { return function () {
        onClose(type);
    }; }, [onClose]);
    var handleUpdateOpenedType = useCallback(function (type) { return function () {
        setOpenedType(function (selectedTypes) {
            if (selectedTypes.includes(type)) {
                // if (type === "wall") {
                //   handleBuildModeStop();
                // }
                return selectedTypes.filter(function (selected) { return selected !== type; });
            }
            // if (type === "wall") {
            //   handleBuildModeStart();
            // }
            return __spreadArray(__spreadArray([], __read(selectedTypes), false), [type], false);
        });
    }; }, [handleBuildModeStart, handleBuildModeStop]);
    var isDisplay = useCallback(function (type) { return openedType.includes(type); }, [openedType]);
    useEffect(function () {
        var handleEscEvent = function () {
            // handleOnClose(menuItem.type)
            setActiveMenuType('');
            setOpenedType([]);
        };
        window.addEventListener('triggerCloseCatalog', handleEscEvent);
        return function () {
            window.removeEventListener('triggerCloseCatalog', handleEscEvent);
        };
    }, []);
    // console.log(menuItem);
    return (React.createElement(SubMenuStyled, null,
        React.createElement(SubMenuTitle, null,
            React.createElement(CommonText, { text: menuItem.name }),
            React.createElement(CommonButton, { onClick: handleOnClose(menuItem.type) },
                React.createElement(CommonText, { color: "green", text: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" }))), (_b = menuItem.items) === null || _b === void 0 ? void 0 :
        _b.map(function (item) {
            var _a, _b, _c, _d;
            return (React.createElement("div", { key: item.type },
                React.createElement(SubMenuTitleName, null,
                    React.createElement(CommonButton, { isDisabled: item.isDisabled, onClick: handleUpdateOpenedType(item.type) },
                        React.createElement(SubMeniDropdow, null,
                            React.createElement(SubMeniDropdowImg, { src: item.imageSrc, alt: item.type }),
                            React.createElement(CommonText, { text: item.name }),
                            React.createElement(SubMenuArrow, null,
                                React.createElement("img", { src: require("src/assets/img/Arrow 1.png"), alt: "arrow" }))))),
                isDisplay(item.type) && (React.createElement(SubMenuBody, null,
                    React.createElement(SubMenuCheckboxBlock, null, (_a = item.checkBoxs) === null || _a === void 0 ? void 0 : _a.map(function (checkBox) {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Checkbox, { onChange: checkBox.onClick }),
                            React.createElement(CommonText, { text: checkBox.title })));
                    })),
                    React.createElement(SubMenuInputsBlock, null, (_b = item.labels) === null || _b === void 0 ? void 0 : _b.map(function (label) {
                        return (React.createElement(SubMenuInput, { key: label.id },
                            React.createElement(InputCustom, { value: label.value, onChange: label.onValueChange, placeholder: label.placeholder })));
                    })),
                    React.createElement(SubMenuButtonsBlock, null, (_c = item.customButtons) === null || _c === void 0 ? void 0 : _c.map(function (button) {
                        return (React.createElement(CommonButton, { isDisabled: getIsDisabled({ buttonTitle: button.title, buttonDisabled: button.isDisabled }), onClick: onBuildModeClick({ onClick: button.onClick, buttonTitle: button.title }) },
                            React.createElement(SubMenuCustomButton, null,
                                React.createElement(CommonText, { color: button.color, text: button.title }))));
                    })),
                    React.createElement(SubMenuImagesBlock, null, (_d = item.images) === null || _d === void 0 ? void 0 : _d.map(function (image) {
                        // console.log(image);
                        // console.log(image)
                        if (!image.images) {
                            return (React.createElement(RecursiveImageMenu, { key: image.name, images: [image], selectedImage: selectedImage, handleSelectImage: handleSelectImage }));
                        }
                        return (
                        // <RecursiveImageMenu
                        //   key={image.name}
                        //   images={image.images}
                        //   selectedImage={selectedImage}
                        //   handleSelectImage={handleSelectImage}
                        // />
                        React.createElement(RecursiveImageMenu, { key: image.name, images: [image], selectedImage: selectedImage, handleSelectImage: handleSelectImage }));
                    }))))));
        })));
});
