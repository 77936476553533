import React, { memo } from "react";
import { SubMenuImage, SubMenuImagesUrl } from "../../styled";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
export var RecursiveImageMenu = memo(function RecursiveImageMenu(_a) {
    var images = _a.images, selectedImage = _a.selectedImage, handleSelectImage = _a.handleSelectImage;
    // console.log(images)
    return (React.createElement(React.Fragment, null, images.map(function (image) { return (React.createElement(SubMenuImage, { key: image.name, "$isActive": selectedImage === image.name },
        React.createElement(CommonButton, { isDisabled: image.isDisabled, onClick: function () {
                return handleSelectImage({
                    type: image.type,
                    name: "".concat(image.literal || image.id),
                });
            } }, image.imageSrc ? (React.createElement(SubMenuImagesUrl, { src: "".concat(image.imageSrc) })) : (
        // <image.svg />
        React.createElement(SubMenuImagesUrl, { src: "".concat(image.icon_src_preview) })
        // <CommonText text="Ошибка" />
        )),
        React.createElement(CommonText, { text: image.title || image.name, fontSize: "9px" }))); })));
});
