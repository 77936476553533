import React from "react";
import { Tabs, Tab, TabsConainer } from "./styled";
import { CommonButton } from "src/ui-kit/button";
export var ChangeRoomMenu = function (_a) {
    var roomsData = _a.roomsData, setRoomTab = _a.setRoomTab, arr = _a.arr;
    return (React.createElement(TabsConainer, null,
        React.createElement(Tabs, null, arr.map(function (el, i) {
            return (React.createElement(CommonButton, { onClick: function () {
                    setRoomTab(i);
                } },
                React.createElement(Tab, { "$isSelect": roomsData[i].isCurrent }, el)));
        }))));
};
