var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from "react";
import { Container, Input, InputBlock, Inputs } from "./styled";
import TwoThumbsRange from "src/ui-kit/two-tumbs-range/two-tumbs-range";
export var TwoThumbsRangeBlock = function (props) {
    var step = props.step, min = props.min, max = props.max;
    var _a = __read(useState([min, max]), 2), twoThumbsRangeValues = _a[0], setTwoThumbsRangeValues = _a[1];
    var inputElFrom = useRef(null);
    var inputElTo = useRef(null);
    var regex = /[A-Z]/g;
    // const [blur, setBlur] = useState(false);
    var handleOnChangeElFrom = function (e) {
        e.preventDefault();
        console.log(e.target.value);
        if (e.target.value.match(regex) &&
            e.target.value < twoThumbsRangeValues[1]) {
            //   setTwoThumbsRangeValues((p) => {
            //     const a = [...p];
            //     a[0] = e.target.value;
            //     return a;
        }
    };
    var handleOnChangeElTo = function (e) {
        // if (e.target.value > twoThumbsRangeValues[0]) console.log(e.target.value);
        // setTwoThumbsRangeValues((p) => {
        //   const a = [...p];
        //   a[1] = e.target.value;
        //   return a;
        // });
    };
    return (React.createElement(Container, null,
        React.createElement(Inputs, null,
            React.createElement(InputBlock
            //className={`input ${blur && "input--focused"}`}
            , { 
                //className={`input ${blur && "input--focused"}`}
                onClick: function () {
                    inputElFrom.current.focus();
                } },
                React.createElement("div", null,
                    React.createElement("span", { className: "adornment-text" }, "\u043E\u0442")),
                React.createElement(Input, { ref: inputElFrom, className: "input-field", value: twoThumbsRangeValues[0], onChange: handleOnChangeElFrom })),
            React.createElement(InputBlock
            //className={`input ${blur && "input--focused"}`}
            , { 
                //className={`input ${blur && "input--focused"}`}
                onClick: function () {
                    inputElTo.current.focus();
                } },
                React.createElement("div", null,
                    React.createElement("span", { className: "adornment-text" }, "\u0434\u043E")),
                React.createElement(Input, { ref: inputElTo, className: "input-field", value: twoThumbsRangeValues[1], onChange: handleOnChangeElTo }))),
        React.createElement(TwoThumbsRange, { rtl: false, step: step, min: min, max: max, values: twoThumbsRangeValues, setValues: setTwoThumbsRangeValues })));
};
