var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var PriceSegment = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  @media (max-width: 480px) {\n    gap: 8px;\n  }\n"], ["\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  justify-content: flex-start;\n  @media (max-width: 480px) {\n    gap: 8px;\n  }\n"])));
export var StyledButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 11px 20px;\n  border: ", ";\n  border-radius: 10px;\n  font-size: 18px;\n  line-height: 24.59px;\n\n  @media (max-width: 480px) {\n    padding: 8px 14px;\n    font-size: 17px;\n    line-height: 20.72px;\n  }\n"], ["\n  padding: 11px 20px;\n  border: ", ";\n  border-radius: 10px;\n  font-size: 18px;\n  line-height: 24.59px;\n\n  @media (max-width: 480px) {\n    padding: 8px 14px;\n    font-size: 17px;\n    line-height: 20.72px;\n  }\n"])), function (_a) {
    var $isSelect = _a.$isSelect;
    return $isSelect ? "1px solid #8DC646" : "0.5px solid #C4C4C4";
});
var templateObject_1, templateObject_2;
