import { fetchGetRequests } from "src/api/fetch/get";
import { getEstimatesUrl } from "src/api/urls/get-estimates";
// import { MOCK_ESTIMATES_DATA } from "../mock";
import { getProjectInfo } from "../../fetch/get-project-info";
export var getEstimatesRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data;
    var _d = getProjectInfo(), projectId = _d.projectId, layoutId = _d.layoutId;
    fetchGetRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: getEstimatesUrl,
        urlId: "/layouts/".concat(layoutId, "/estimate"),
    });
};
