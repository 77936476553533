import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormNumberInput from '../../../style/form-number-input';
import FormTextInput from '../../../style/form-text-input';
import { CommonButton } from 'src/ui-kit/button';
import { CommonText } from 'src/ui-kit/text';

const tableStyle = { width: '100%' };
const firstTdStyle = { width: '6em' };
const inputStyle = { textAlign: 'left' };

export default function ItemAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {
  let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let renderedX = attributeFormData.has('x') ? attributeFormData.get('x') : element.x;
  let renderedY = attributeFormData.has('y') ? attributeFormData.get('y') : element.y;
  let renderedR = attributeFormData.has('rotation') ? attributeFormData.get('rotation') : element.rotation;




  let renderedWidth = element.properties.width ? element.properties.get('width').get('length') : 0
  let renderedHeight =  element.properties.height ? element.properties.get('width').get('length') : 0


  // console.log(attributeFormData.get('properties'))
  // console.log(element  )
  // console.log(element)
  // console.log(renderedWidth)
  const handleOnDelete = () => {
    const escEvent = new KeyboardEvent('keydown', {
      key: 'Delete',
      keyCode: 46, // Устарело, но всё ещё используется в некоторых браузерах
      code: 'Delete',
      which: 46,  // Устаревшее свойство, но добавлено для поддержки старых браузеров
      bubbles: true, // Событие будет всплывать
      cancelable: true
    });

    // Отправляем событие в документ
    document.dispatchEvent(escEvent);
  }
  
  return (
    <table style={tableStyle}>
      <tbody>


      <tr>
          <td style={firstTdStyle}>
          <CommonButton onClick={handleOnDelete} >
         <CommonText color="red" text="Удалить" />
        
        </CommonButton>
          </td>
          <td>
  
        </td>
        </tr>


        <tr>
          <td style={firstTdStyle}>Название</td>
          <td>
            <FormTextInput
              value={name}
              onChange={event => onUpdate('name', event.target.value)}
              style={inputStyle}
            />
          </td>
        </tr>



        {/* <tr>
          <td style={firstTdStyle}>X</td>
          <td>
            <FormNumberInput
              value={renderedX}
              onChange={event => onUpdate('x', event.target.value)}
              style={inputStyle}
              state={state}
              precision={2}
              {...rest}
            />
          </td>
        </tr> */}
        {/* <tr>
          <td style={firstTdStyle}>Y</td>
          <td>
            <FormNumberInput
              value={renderedY}
              onChange={event => onUpdate('y', event.target.value)}
              style={inputStyle}
              state={state}
              precision={2}
              {...rest}
            />
          </td>
        </tr>


        <tr>
          <td style={firstTdStyle}>Y</td>
          <td>
            <FormNumberInput
              value={renderedWidth}
              onChange={event => onUpdate('properties.width.length.length', event.target.value)}
              style={inputStyle}
              state={state}
              precision={2}
              {...rest}
            />
          </td>
        </tr> */}


        {/* <tr>
          <td style={firstTdStyle}>Y</td>
          <td>
            <FormNumberInput
              value={renderedHeight}
              onChange={event => onUpdate('properties.height.length.length', event.target.value)}
              style={inputStyle}
              state={state}
              precision={2}
              {...rest}
            />
          </td>
        </tr> */}
        <tr>
          <td style={firstTdStyle}>{translator.t('Rotation')}</td>
          <td>
            <FormNumberInput
              value={renderedR}
              onChange={event => onUpdate('rotation', event.target.value)}
              style={inputStyle}
              state={state}
              precision={2}
              {...rest}
            />
          </td>
        </tr>


      </tbody>
    </table>
  );
}

ItemAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

ItemAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
